





























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { Statues } from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniTableActions from '@/components/table/SygniTableActions.vue';
import {BSpinner, BvTableField, BTooltip} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import {mapState} from "vuex";
import {Investor} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniTableLegacy from "@/components/table/SygniTableLegacy.vue";
import breakpoints from '@/plugins/breakpoints';
import { create, all } from 'mathjs'
import SygniPagination from '@/components/table/SygniPagination.vue';
import Vue from 'vue';
import _ from 'lodash';
import { SygniTableAction } from '@/modules/genprox/modules/profile/store/types';
const math = create(all);

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniTableActions, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, BTooltip },
    computed: mapState('investors', {
      itemsLength: (state: any) => state.kycTableLength,
    }),
})
export default class KycTable extends SygniTableLegacy<MarketplaceItem> {
  breakpoints = breakpoints;
  openedOwner: any = null;
  
  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'investorEmail', sortable: true, borderless: true, thClass: 'text-left left th-cell' },
    { key: 'status', sortable: true, borderless: true, thClass: 'th-cell' },
    { key: 'brokerName', sortable: true, label: 'Broker name', borderless: true, thClass: 'th-cell' },
    { key: 'createdAt', sortable: true, borderless: true, thClass: 'th-cell' },
    { key: 'actions', sortable: false, thClass: 'th-cell', class: 'actions' }
  ];

  get computedTableFields() {
    const tableFields = this.tableFields

    return tableFields
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get isMarketplaceAdmin() {
    return this.activeUserData?.role?.marketplace === 'admin' ? true : false;
  }

  get isMarketplaceHead() {
    return this.activeUserData?.role?.marketplace === 'head' ? true : false;
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get isMarketplaceUser() {
    return this.activeUserData?.role?.marketplace === 'user' ? true : false;
  }

  get addProductEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceUser) {
      return true;
    }

    if (this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('add-product') ? true : false;
    }

    return false;
  }

  get totals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const investmentValues = item?.investmentValues || []

      if (investmentValues?.length) {
        investmentValues.forEach((el: any) => {
          if (el.currency) {
            if (totals[el.currency]) {
              totals[el.currency] = el.value ? math.number(math.add(math.bignumber(totals[el.currency]), math.bignumber(el.value))) : math.number(math.add(math.bignumber(totals[el.currency]), 0));
            } else {
              totals[el.currency] = el.value ? math.bignumber(math.bignumber(el.value)) : 0;
            }
          }
        })
      } else {
        if (item.totalInvestmentValue.currency) {
          if (totals[item.totalInvestmentValue.currency]) {
            totals[item.totalInvestmentValue.currency] = item.totalInvestmentValue.value ? math.number(math.add(math.bignumber(totals[item.totalInvestmentValue.currency]), math.bignumber(item.totalInvestmentValue.value))) : math.number(math.add(math.bignumber(totals[item.totalInvestmentValue.currency]), 0));
          } else {
            totals[item.totalInvestmentValue.currency] = item.totalInvestmentValue.value ? math.bignumber(math.bignumber(item.totalInvestmentValue.value)) : 0;
          }
        }
      }
    })

    return totals;
  }

  getAutentiStatuses(statuses: string[]) {
    const filteredStatuses: string[] = statuses?.filter((el: string) => el?.toUpperCase() === 'PROCESSING' || el?.toUpperCase() === 'DRAFT' || el?.toUpperCase() === 'COMPLETED' || el?.toUpperCase() === 'ERROR')

    if (filteredStatuses?.length !== statuses?.length) {
      filteredStatuses.push('NEW')
    }

    return _.uniq(filteredStatuses)
  }

  getRowActions(item: any) {
    const actions: SygniTableAction[] = []

    if (this.isMarketplaceAdmin && item?.id && item?.status?.toLowerCase() === 'submitted') {
      actions.push({ label: 'View KYC form', icon: this.$options.filters.getIcon('Fund'), callback: this.viewKycForm })
    }

    return actions
  }

  async viewKycForm(item: any) {
    if (!this.isBusy) {
      this.$store.commit(this.setTableBusyMutation, true);
  
      try {
        // const resp = await this.$store.dispatch('investors/getKycForm', item?.id)
        let domain = this.activeUserData?.advisorPanel?.www?.includes('https://') || this.activeUserData?.advisorPanel?.www?.includes('http://') ? this.activeUserData?.advisorPanel?.www : `https://${this.activeUserData?.advisorPanel?.www}`

        const lastChar = domain?.charAt(domain?.length - 1)
        if (lastChar === '/') {
          // remove last char
          domain = domain?.slice(-1)
        }

        const redirectionLink = `${domain}/kyc-form?id=${item?.id}`
        window.open(redirectionLink, '_blank').focus()
        this.$root?.$emit('bv::tooltip::hide')

      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          type: 'error',
          title: 'An error occurred.',
          text: errorMessage
        });
      }
  
      this.$store.commit(this.setTableBusyMutation, false);
    }
  }

  showInvestmentValues(item: any) {
    const currency = item?.totalInvestmentValue?.currency
    const investmentValues = item?.investmentValues?.filter((el: any) => el?.currency !== currency) || []

    return investmentValues?.length ? true : false
  }

  getInvestmentValues(item: any) {
    const investmentValues = item?.investmentValues || []

    return investmentValues
  }

  openOwnerModal(item: any) {
    this.openedOwner = item
  }

  closeOwnerModal() {
    this.openedOwner = null
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    setTimeout(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';
      switch(this.sortBy){
        case('name'):
          sortBy = 'investmentClientName';
          break;
        case('status'):
          sortBy = 'investmentClientStatus';
          break;
        case('sourceName'):
          sortBy = 'investmentClientSourceName';
          break;
        case('created'):
          sortBy = 'investmentClientCreated';
          break;
        case('ownerName'):
          sortBy = 'investmentClientOwnerName';
          break;  
      }
      this.sortingQuery = `sort[]=${sign}${sortBy}`;
      this.$store.commit(this.sortingQueryAction, {sorting: this.sortingQuery});
      this.$store.dispatch(this.getItemsAction)
    },1)
  }

  statusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case('SENT'): return 'lead'
      case('VIEWED'): return 'success'
      case('SUBMITTED'): return 'primary'
      default: return 'black'; 
    }
  }

  getSignedStatuses(statuses: string[]) {
    if (statuses?.includes('e-signed') && statuses?.includes('sent')) {
      statuses = statuses.filter((status: string) => status != 'sent')
    }

    return statuses
  }

  goToProfile(investor: Investor): void {
    this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: investor.id}})
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  async refreshTable() {
    this.$store.commit('investors/setKycTableBusy', true)
    await this.$store.dispatch('investors/getKycInvitations')
  }

  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }

  openAddNewProductFormAction(item: any) {
    this.openAddNewProductForm(item?.id)
  }

  openAddNewProductForm(id: string): void {
    this.$store.commit('investors/clearAnnexingData');
    this.$store.commit('investors/setNewProductInvestor', id);
    if (this.$route.path.includes('company')) {
      this.$router.push({ name: 'addNewProductStepOne', path: '/company/fundraising/product/new/step-one', params: { investorId: id } });
    } else {
      this.$router.push({ name: 'addNewProductStepOne', path: '/fund/fundraising/product/new/step-one', params: { investorId: id } });
    }
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();
    this.paginationInstance.$mount();
    this.paginationInstance.showPageNumberOptions = true;
    this.paginationInstance.perPage = this.perPage || 10;
    this.paginationInstance.totalRows = this.itemsLength;
    
    if (this.getQuery) {
      this.$nextTick(() => {
        const query = this.$store.getters[this.getQuery]
        const params = new URLSearchParams(query?.limit)
        const offset = Number(params.get('offset')) || 0
        const limit = Number(params.get('limit')) || this.perPage || 10
        
        const currentPage = (offset / limit) + 1
        this.paginationInstance.currentPage = currentPage
      })
    }

    this.paginationInstance.$on('change', (currentPage: number) => {
      // this.currentPage = currentPage;
      this.$store.commit(this.setTableBusyMutation, true);
      this.$store.commit(this.sortingQueryAction, { limit: this.createLimitQuery(currentPage, this.perPage || 10) });
      this.$store.dispatch(this.getItemsAction);
    })
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if (this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.currentPage = 1
      this.paginationInstance.perPage = perPage
      // this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      // this.localTableQuery.limit = this.tableData.perPage;
      this.$store.commit(this.sortingQueryAction, { limit: this.createLimitQuery(1, perPage || 10) });
      // this.localTableQuery.offset = 0;
      // this.setQuery();
      this.$store.dispatch(this.getItemsAction)
      this.changePerPageOption(perPage || 10);
    });
    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;
    this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
  }

  changePerPageOption(perPage: number): void {
    this.$emit('changePerPageOption', perPage);
  }
}

export interface MarketplaceItem {}

