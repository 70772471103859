var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-boilerplate', {
    staticClass: "register-page",
    attrs: {
      "size": "wide"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [!_vm.isRegistered ? _c('div', {
          staticClass: "auth-module-title"
        }, [_c('p', {
          staticClass: "auth-module__title"
        }, [_vm._v("Create an account")]), _c('sygni-rect-button', {
          staticClass: "auth-module-title__btn outline gn-primary",
          on: {
            "click": _vm.openDemoModal
          }
        }, [_c('div', {
          staticClass: "auth-module-title__btn-content"
        }, [_c('svg', {
          staticClass: "size-6",
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "fill": "none",
            "viewBox": "0 0 24 24",
            "stroke-width": "1.5",
            "stroke": "currentColor"
          }
        }, [_c('path', {
          attrs: {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "d": "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
          }
        })]), _c('span', [_vm._v("Schedule a demo")])])])], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return [!_vm.isRegistered ? [_c('div', {
          staticClass: "auth-module__group"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'First name',
            "validation": _vm.$v.name
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.name.$touch();
            }
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Last name',
            "validation": _vm.$v.surname
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.surname.$touch();
            }
          },
          model: {
            value: _vm.surname,
            callback: function callback($$v) {
              _vm.surname = $$v;
            },
            expression: "surname"
          }
        })], 1), _c('sygni-input', {
          ref: "emailInput",
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Email address',
            "validation": _vm.$v.email
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.email.$touch();
            }
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('div', {
          staticClass: "auth-module__group"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Password',
            "type": "password",
            "validation": _vm.$v.password
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.password.$touch();
            }
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Repeat password',
            "type": "password",
            "validation": _vm.$v.repeatPassword
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.repeatPassword.$touch();
            }
          },
          model: {
            value: _vm.repeatPassword,
            callback: function callback($$v) {
              _vm.repeatPassword = $$v;
            },
            expression: "repeatPassword"
          }
        })], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showRadios,
            expression: "showRadios"
          }],
          staticClass: "radio-group"
        }, [_c('div', {
          staticClass: "auth-module__label"
        }, [_vm._v("I am")]), _c('div', {
          staticClass: "radio-group__inputs"
        }, [_c('sygni-radio', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Fund Manager',
            "group": 'investor-type',
            "radioValue": _vm.UserTypes.fundManager
          },
          model: {
            value: _vm.investorType,
            callback: function callback($$v) {
              _vm.investorType = $$v;
            },
            expression: "investorType"
          }
        }), _c('sygni-radio', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Startup Founder',
            "radioValue": _vm.UserTypes.startupFounder,
            "disabled": true,
            "group": 'investor-type'
          },
          model: {
            value: _vm.investorType,
            callback: function callback($$v) {
              _vm.investorType = $$v;
            },
            expression: "investorType"
          }
        }), _c('sygni-radio', {
          staticClass: "secondary",
          attrs: {
            "legacyStyling": false,
            "label": 'Private Investor',
            "radioValue": _vm.UserTypes.privateInvestor,
            "disabled": true,
            "group": 'investor-type'
          },
          model: {
            value: _vm.investorType,
            callback: function callback($$v) {
              _vm.investorType = $$v;
            },
            expression: "investorType"
          }
        })], 1), _c('sygni-input-error', {
          attrs: {
            "legacyStyling": false,
            "validation": _vm.$v.investorType
          }
        })], 1), _c('sygni-checkbox', {
          staticClass: "no-wrap",
          attrs: {
            "legacyStyling": false,
            "clickableLabel": true,
            "validation": _vm.$v.agreement
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.agreement.$touch();
            }
          },
          model: {
            value: _vm.agreement,
            callback: function callback($$v) {
              _vm.agreement = $$v;
            },
            expression: "agreement"
          }
        }, [_c('div', {
          staticClass: "agreement-text"
        }, [_vm._v(" I consent to the processing of my data in accordance with GDPR and declare that I have read the "), _c('a', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.showRegulations.apply(null, arguments);
            }
          }
        }, [_vm._v("privacy policy and terms and conditions")])])])] : [_c('div', {
          staticClass: "registered"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/accept.svg")
          }
        }), _c('div', {
          staticClass: "title"
        }, [_vm._v("Registration completed.")]), _c('div', {
          staticClass: "description"
        }, [_vm._v("Please activate your account by email.")])])], _vm.showDemoModal ? _c('GenproxModal', {
          attrs: {
            "type": "iframe"
          },
          on: {
            "cancel": _vm.closeDemoModal,
            "close": _vm.closeDemoModal
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function fn() {
              return [_c('iframe', {
                attrs: {
                  "src": "https://genprox.pipedrive.com/scheduler/KkRaMRIR/demo-fundequate",
                  "title": "Pipedrive Scheduler Embed",
                  "frameborder": "0",
                  "allowfullscreen": ""
                }
              })];
            },
            proxy: true
          }], null, false, 689121686)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [!_vm.isRegistered ? [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-module__btn",
          attrs: {
            "legacyStyling": false,
            "loading": _vm.loading
          },
          on: {
            "click": function click($event) {
              return _vm.register();
            }
          }
        }, [_vm._v("Register")])] : [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-module__btn",
          attrs: {
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/auth/login');
            }
          }
        }, [_vm._v("Go to login")])]];
      },
      proxy: true
    }, {
      key: "actionsFooter",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "footNote",
      fn: function fn() {
        return [!_vm.isRegistered ? [_vm._v(" Have an account? "), _c('sygni-link-button', {
          staticClass: "gn-primary",
          attrs: {
            "type": "simple",
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/auth/login');
            }
          }
        }, [_vm._v("Sign in")])] : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }