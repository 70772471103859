
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AuthModuleBoilerplate extends Vue {
  @Prop({ default: 'normal' }) size?: 'normal' | 'wide' | 'textContainer'
}
