var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('edit-profile', {
    attrs: {
      "isLoading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "modal",
      fn: function fn() {
        return [_c('GenproxModal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showModal,
            expression: "showModal"
          }],
          attrs: {
            "isLoading": _vm.isLoading,
            "header": "Wait...",
            "description": "Do you want to save the changes?"
          },
          on: {
            "cancel": _vm.changeRoute,
            "confirm": _vm.confirmUpdateProfile,
            "close": function close($event) {
              _vm.showModal = false;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('sygni-container-title', {
          staticClass: "mb-5"
        }, [_vm._v("General data")]), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('logo-uploader', {
          attrs: {
            "title": "Upload new image",
            "tempLogo": _vm.profileData.generalData.photo,
            "type": "profile",
            "note": "min 200 x 200px"
          },
          on: {
            "input": _vm.uploadLogo,
            "delete": _vm.deleteLogo
          }
        }), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.titles,
            "options-label": 'label',
            "label": 'TITLE',
            "placeholder": 'Choose'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.title,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "title", $$v);
            },
            expression: "profileData.generalData.title"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'NAME',
            "validation": _vm.$v.profileData.generalData.firstName
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "firstName", $$v);
            },
            expression: "profileData.generalData.firstName"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'SURNAME',
            "validation": _vm.$v.profileData.generalData.surname
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.surname,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "surname", $$v);
            },
            expression: "profileData.generalData.surname"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'HEADLINE'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.professionalHeadline,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "professionalHeadline", $$v);
            },
            expression: "profileData.generalData.professionalHeadline"
          }
        }), _c('sygni-text-area', {
          staticClass: "secondary",
          attrs: {
            "label": 'DESCRIPTIONS'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.professionalDescription,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "professionalDescription", $$v);
            },
            expression: "profileData.generalData.professionalDescription"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'PHONE',
            "type": "tel",
            "validation": _vm.$v.profileData.generalData.phone
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "phone", $$v);
            },
            expression: "profileData.generalData.phone"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'EMAIL',
            "disabled": true,
            "validation": _vm.$v.profileData.generalData.email
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.email,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "email", $$v);
            },
            expression: "profileData.generalData.email"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'FACEBOOK',
            "validation": _vm.$v.profileData.generalData.facebook
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.facebook,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "facebook", $$v);
            },
            expression: "profileData.generalData.facebook"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'LINKEDIN',
            "validation": _vm.$v.profileData.generalData.linkedIn
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.linkedIn,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "linkedIn", $$v);
            },
            expression: "profileData.generalData.linkedIn"
          }
        })], 1), _vm.industries ? _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('div', {
          staticClass: "table"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('p', {
          staticClass: "edit-profile__subheader"
        }, [_vm._v("INDUSTRY")])])]), _vm._l(_vm.industries, function (item, index) {
          return [index % 2 === 0 ? _c('div', {
            key: index,
            staticClass: "row mt-3"
          }, [_c('div', {
            staticClass: "col-md-6 col-sm-12 checkbox-col"
          }, [_c('sygni-checkbox', {
            staticClass: "secondary",
            attrs: {
              "label": _vm.dictionaries.industry[index].label
            },
            on: {
              "changedValue": _vm.handleFieldChange
            },
            model: {
              value: _vm.industries[index].value,
              callback: function callback($$v) {
                _vm.$set(_vm.industries[index], "value", $$v);
              },
              expression: "industries[index].value"
            }
          })], 1), _vm.dictionaries.industry[index + 1] ? _c('div', {
            staticClass: "col-md-6 col-sm-12 checkbox-col"
          }, [_c('sygni-checkbox', {
            staticClass: "secondary",
            attrs: {
              "label": _vm.dictionaries.industry[index + 1].label
            },
            on: {
              "changedValue": _vm.handleFieldChange
            },
            model: {
              value: _vm.industries[index + 1].value,
              callback: function callback($$v) {
                _vm.$set(_vm.industries[index + 1], "value", $$v);
              },
              expression: "industries[index+1].value"
            }
          })], 1) : _vm._e()]) : _vm._e()];
        })], 2)]) : _vm._e(), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('p', {
          staticClass: "edit-profile__subheader"
        }, [_vm._v("Additional information "), _c('span', [_vm._v("(not display in user profile)")])]), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.investmentPotentials,
            "options-label": 'label',
            "label": 'INVESTMENT POTENTIAL',
            "placeholder": 'Choose'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.investmentPotential,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "investmentPotential", $$v);
            },
            expression: "profileData.generalData.investmentPotential"
          }
        }), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.investmentKnowledge,
            "options-label": 'label',
            "label": 'INVESTMENT KNOWLEDGE',
            "placeholder": 'Choose'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.investmentKnowledge,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "investmentKnowledge", $$v);
            },
            expression: "profileData.generalData.investmentKnowledge"
          }
        }), _c('div', {
          staticClass: "table mb-5"
        }, [_c('div', {
          staticClass: "checkboxes-title"
        }, [_vm._v("FOCUS AREA")]), _vm._l(_vm.focusArea, function (item, index) {
          return [index % 2 === 0 ? _c('div', {
            key: index,
            staticClass: "row mt-3"
          }, [_c('div', {
            staticClass: "col-md-6 col-sm-12 checkbox-col"
          }, [_c('sygni-checkbox', {
            staticClass: "secondary",
            attrs: {
              "label": _vm.focusArea[index].label
            },
            on: {
              "changedValue": _vm.handleFieldChange
            },
            model: {
              value: _vm.focusArea[index].value,
              callback: function callback($$v) {
                _vm.$set(_vm.focusArea[index], "value", $$v);
              },
              expression: "focusArea[index].value"
            }
          })], 1), _vm.focusArea[index + 1] ? _c('div', {
            staticClass: "col-md-6 col-sm-12 checkbox-col"
          }, [_c('sygni-checkbox', {
            staticClass: "secondary",
            attrs: {
              "label": _vm.focusArea[index + 1].label
            },
            on: {
              "changedValue": _vm.handleFieldChange
            },
            model: {
              value: _vm.focusArea[index + 1].value,
              callback: function callback($$v) {
                _vm.$set(_vm.focusArea[index + 1], "value", $$v);
              },
              expression: "focusArea[index+1].value"
            }
          })], 1) : _vm._e()]) : _vm._e()];
        })], 2), _c('sygni-rounded-button', {
          class: ['filled gn-primary button secondary mt-5', !_vm.hasChanges ? 'inactive' : ''],
          on: {
            "click": function click($event) {
              return _vm.updateProfile(_vm.nextRoute);
            }
          }
        }, [_vm._v("Save changes")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }