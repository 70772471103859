var render = function () {
  var _vm$$slots, _vm$$slots2, _vm$$slots3, _vm$$slots4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AuthModuleBoilerplate', {
    attrs: {
      "size": _vm.size
    }
  }, [_c('div', {
    staticClass: "auth-form-box"
  }, [_c('div', {
    staticClass: "auth-form-box__content"
  }, [_vm._t("title", function () {
    return [_vm.formTitle ? _c('h1', {
      staticClass: "auth-form-box__title"
    }, [_vm._v(_vm._s(_vm.formTitle))]) : _vm._e()];
  }), !!((_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.fields) ? _c('div', {
    staticClass: "auth-form-box__fields"
  }, [_vm._t("fields")], 2) : _vm._e(), !!((_vm$$slots2 = _vm.$slots) !== null && _vm$$slots2 !== void 0 && _vm$$slots2.actions) ? _c('div', [_vm._t("actions")], 2) : _vm._e(), !!((_vm$$slots3 = _vm.$slots) !== null && _vm$$slots3 !== void 0 && _vm$$slots3.actionsFooter) ? _c('p', {
    staticClass: "auth-form-box__actions-footer text-right"
  }, [_vm._t("actionsFooter")], 2) : _vm._e()], 2)]), !!((_vm$$slots4 = _vm.$slots) !== null && _vm$$slots4 !== void 0 && _vm$$slots4.footNote) ? _c('p', {
    staticClass: "auth-module__footnote"
  }, [_vm._t("footNote")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }