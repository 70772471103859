var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "genprox-app"
  }, [_c('fade-in-out', {
    attrs: {
      "enter-time": _vm.enterTime,
      "leave-time": _vm.leaveTime
    }
  }, [_vm.isReady ? _c('div', {
    class: ['genprox-app-grid', _vm.isFullWidth ? 'genprox-app-grid--full' : '', _vm.hideSidebarOnPrint ? 'genprox-app-grid--print' : '']
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('AppHeader', {
    attrs: {
      "id": "app-header"
    },
    on: {
      "setUserContext": _vm.setUserContextHandler
    }
  }), !_vm.isFullWidth ? _c('AppSideBar', {
    attrs: {
      "id": "app-side-bar"
    }
  }) : _vm._e(), _c('div', {
    ref: "appContent",
    staticClass: "app-content"
  }, [_c('router-view', {
    attrs: {
      "id": "app-router"
    }
  }), _c('div', {
    staticClass: "genprox-app__form-bar"
  }, [_c('portal-target', {
    attrs: {
      "name": "genproxFormBar"
    }
  })], 1), _c('div', {
    attrs: {
      "id": "scrollAnchor"
    }
  })], 1), _c('live-chat-widget', {
    attrs: {
      "customer-name": _vm.userName,
      "customer-email": _vm.userEmail,
      "license": "14842155"
    }
  }), _c('sygni-cookies-bar')], 1) : _vm._e()]), _c('fade-in-out', {
    staticClass: "spinner-container",
    attrs: {
      "enter-time": 1,
      "leave-time": 600
    }
  }, [!_vm.isReady ? _c('div', [_c('b-spinner', {})], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }