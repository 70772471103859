






































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { BTable } from 'bootstrap-vue'
import Utils from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';
import moment from 'moment';

@Component({
  components: { BTable, SygniModal, GenproxModal, SygniSelect, SygniCheckbox, SygniRoundedButton }
})
export default class ClatTaxModal extends Vue {
  @Prop({ default: [] }) productIds: string[]
  @Prop({ default: false }) show: boolean
  @Prop({ default: false }) preview: boolean
  
  isClatTaxModalLoading: boolean = false;
  showClatTaxModal: boolean = false
  clatTaxFormData: { taxOfficeCode: string | null, correction: boolean } = {
    taxOfficeCode: null,
    correction: false,
  }
  generatedClatTaxDeclarations: any[] = []

  get taxOfficeCodes() {
    return this.$store.getters['investors/taxOfficeCodes']
  }

  get declarationsHeading() {
    return this.preview ? 'Generated Declarations' : 'Success'
  }

  get declarationsDescription() {
    return this.preview ? '' : 'Declarations have been generated successfully.'
  }

  async openClatTaxModal() {
    this.showClatTaxModal = true

    if (!this.taxOfficeCodes?.length && !this.preview) {
      this.isClatTaxModalLoading = true

      try {
        await this.$store.dispatch('investors/getProductTaxOfficeCodes')
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.closeClatTaxModal()
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isClatTaxModalLoading = false
    } else if (this.preview) {
      const resp = await this.$store.dispatch('investors/getClatTaxDeclarations', this.productIds[0])
      const items = resp.map((el: any) => {
        el.fileName = el.fileName?.replaceAll('/', '_')
        return el
      })
      this.generatedClatTaxDeclarations = items
    } 
    
    if (!this.preview) {
      const defaultItem = this.taxOfficeCodes?.find((el: any) => el?.default)

      if (defaultItem) {
        this.clatTaxFormData.taxOfficeCode = defaultItem?.value
      }
    }
  }

  closeClatTaxModal(): void {
    this.$emit('close')
    this.clearClatTaxDeclarations()
    this.clatTaxFormData.taxOfficeCode = null
    this.clatTaxFormData.correction = false
  }

  clearClatTaxDeclarations() {
    this.generatedClatTaxDeclarations = []
  }

  async generateClatTaxDeclaration() {
    this.isClatTaxModalLoading = true

    try {
      const payload: any = { ...this.clatTaxFormData, productIds: this.productIds }
      const resp = await this.$store.dispatch('investors/generateClatTaxDeclaration', payload)
      const items = resp.map((el: any) => {
        el.fileName = el.fileName?.replaceAll('/', '_')
        return el
      })
      this.generatedClatTaxDeclarations = items
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isClatTaxModalLoading = false
  }

  downloadAllDeclarations() {
    let i: number = 0
    const items = this.generatedClatTaxDeclarations?.map((el: any) => {
      i++
      return { fileName: `${i}_${el.fileName}`, path: el.filePath };
    });
    if (items?.length) {
      Utils.downloadZippedFilesByUrls(items, `PCC_Package_${moment().format('YYYY_MM_DD')}`);
    }
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  @Watch('show') onShowUpdate() {
    if (this.show) {
      this.openClatTaxModal()
    }
  }
}
