var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "inner-card sygni-card--borderless"
  }, [_c('div', {
    staticClass: "reg-rep-creating-report"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/docs.svg")
    }
  }), _c('span', [_vm._v(" We are currently preparing your report and will get back to you as soon as possible. ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }