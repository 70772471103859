var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('notifications', {
    staticClass: "notification-wrapper",
    attrs: {
      "speed": 150,
      "width": 450,
      "position": _vm.position
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        var _props$item, _props$item2, _props$item3, _props$item4, _props$item5, _props$item6, _props$item7;

        return [_c('div', {
          staticClass: "notification-wrapper__inner"
        }, [_vm.hasIcon(props === null || props === void 0 ? void 0 : (_props$item = props.item) === null || _props$item === void 0 ? void 0 : _props$item.type) ? _c('div', {
          class: ['notification-wrapper__icon', props === null || props === void 0 ? void 0 : (_props$item2 = props.item) === null || _props$item2 === void 0 ? void 0 : _props$item2.type]
        }, [(props === null || props === void 0 ? void 0 : (_props$item3 = props.item) === null || _props$item3 === void 0 ? void 0 : _props$item3.type) === 'success' ? [_c('CheckCircleIcon')] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$item4 = props.item) === null || _props$item4 === void 0 ? void 0 : _props$item4.type) === 'warn' ? [_c('ExclamationCircleIcon')] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$item5 = props.item) === null || _props$item5 === void 0 ? void 0 : _props$item5.type) === 'error' ? [_c('ExclamationTriangleIcon')] : _vm._e()], 2) : _vm._e(), _c('div', {
          staticClass: "notification-wrapper__content"
        }, [_c('div', {
          staticClass: "notification-wrapper__title"
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$item6 = props.item) === null || _props$item6 === void 0 ? void 0 : _props$item6.title))]), _c('div', {
          staticClass: "notification-wrapper__desc",
          domProps: {
            "innerHTML": _vm._s(props === null || props === void 0 ? void 0 : (_props$item7 = props.item) === null || _props$item7 === void 0 ? void 0 : _props$item7.text)
          }
        })]), _c('div', {
          staticClass: "notification-wrapper__close",
          on: {
            "click": props.close
          }
        }, [_c('XMarkIcon')], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }