






























































































import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniDroparea from '@/components/inputs/SygniDroparea.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import draggable from 'vuedraggable';
import _ from 'lodash';
import { Prop, Watch } from 'vue-property-decorator';
// import { NavigationGuardNext, Route } from 'vue-router';
// import { required } from 'vuelidate/lib/validators';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { AxiosRequestConfig } from 'axios';
import { BACKEND_BASE_URL } from '@/shared/consts';
import AddNewCampaignBoilerplate from './AddNewCampaignBoilerplate.vue';
import { Issuer } from '@/modules/genprox/store/types';
import { SelectOption } from '@/store/types';

// Component.registerHooks(['validations', 'beforeRouteLeave']);
@Component({
  components: { SygniLoader, SygniContainerTitle, GenproxModal, SygniDroparea, SygniInput, SygniTextArea, SygniSelect, SygniRectButton, SygniLinkButton, draggable, EditorComponent },
})
export default class AddNewCampaignFormStepOne extends AddNewCampaignBoilerplate {
  @Prop({ default: null }) validation: any;
  uploadProgress: UploadProgress = { progress: 0 };
  BACKEND_BASE_URL: string = BACKEND_BASE_URL;
  isLoading: boolean = false;
  initialIssuerOption: SelectOption<string> = null
  issuerIdToChange: string = null
  galleryPhotos: any = [];
  emptyTextSection: any = {
    title: '',
    leftContent: '',
    rightContent: '',
    type: 'wide',
  };

  get hasId() {
    return this.$route?.params?.id ? true : false;
  }

  get imagePreviewUrl() {
    if(this.campaignData?.imagePath) {
      return this.campaignData.imagePath;
    }

    return this.campaignData?.image?.filePath ? this.campaignData.image.filePath : '';
  }

  get issuers() {
    return this.$store.getters['genprox/issuers']
  }

  get issuerOptions() {
    const issuers: SelectOption[] = this.issuers?.map((issuer: Issuer) => {
      return { label: issuer?.issuerName, value: issuer?.issuerId }
    }) || []

    if (this.initialIssuerOption?.value) {
      if (!issuers?.find((el: SelectOption) => el.value === this.initialIssuerOption?.value))
      issuers.push(this.initialIssuerOption)
    }
    
    return issuers
  }

  setIssuer(issuerId: string) {
    if (!this.campaignData?.products[0]?.agreementTemplateId) {
      this.campaignData.issuerId = issuerId
    } else {
      this.openIssuerChangeModal(issuerId)
    }
  }

  openIssuerChangeModal(issuerId: string) {
    this.issuerIdToChange = issuerId
  }

  closeIssuerChangeModal() {
    this.issuerIdToChange = null
  }

  confirmIssuerChangeModal() {
    const issuerId = this.issuerIdToChange
    this.campaignData.issuerId = issuerId
    this.campaignData.products[0].agreementTemplateId = null
    this.closeIssuerChangeModal()
  }

  removeImagePreview() {
    this.campaignData.imagePath = null;
    this.campaignData.imageId = null;
    this.setCampaignData();
  }

  deleteTextSection(index: number) {
    this.textSections[this.selectedLanguage].splice(index, 1);
    this.reRender++;
  }

  deleteGalleryPhoto(index: number) {
    this.galleryPhotos.splice(index, 1);
  }

  addNewTextSection() {
    this.textSections[this.selectedLanguage].push(_.cloneDeep(this.emptyTextSection));
    this.reRender++;
  }

  handleSectionUpdate(content: string, section: any, type: 'left' | 'right' | 'title' = 'left') {
    if (type == 'left') {
      section.leftContent = content;
    } else if (type == 'right') {
      section.rightContent = content;
    } else if(type == 'title') {
      section.title = content;
    }
  }

  setCampaignName() {
    this.validation?.campaignData?.campaignName?.$touch();

    this.setCampaignData();
  }

  async uploadThumbnail(file: File) {
    const thumbnailImage = await this.uploadFiles([file]);
    this.$nextTick(() => {
      this.campaignData.imagePath = thumbnailImage[0].filePath;
      this.campaignData.imageId = thumbnailImage[0].id;
      this.setCampaignData();
    })
  }

  async addGalleryFiles(files: File[]) {
    try {
      const photos = await this.uploadFiles(files);

      photos.forEach((photo: any) => {
        this.galleryPhotos.push(photo);
      });

    } catch(e: any) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async uploadFiles(files: File[]) {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    this.isLoading = true;
    try {
      const promises: Array<Promise<any>> = [];
      Array.from(files).forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadPublicFile', { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);
      this.isLoading = false;
      this.uploadProgress.progress = -1;
      return uploadedFiles;
    } catch (e) {
      e;
    }
    this.isLoading = false;
    this.uploadProgress.progress = -1;
  }

  mounted() {
    if (this.campaignData?.issuerId) {
      this.initialIssuerOption = {
        label: this.campaignData?.issuerName,
        value: this.campaignData?.issuerId,
      }
    }
  }

  @Watch('campaignData.landingPageUrl') onLandingPageUrlChange() {
    this.campaignData.landingPageUrl = this.$options.filters.toHttps(this.campaignData.landingPageUrl)
  }

  @Watch('galleryPhotos') onGalleryPhotosChange() {
    this.setCampaignData();
  }

  @Watch('textSections') onTextSectionsChange() {
    this.setCampaignData();
  }
}
