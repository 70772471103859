

































import SygniLoader from '@/components/layout/SygniLoader.vue';
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex';
import { Contexts, UserContext } from '../models/User';
import { InvestmentClient } from '../modules/fund/modules/fundraising/store/types';
import { WelcomeTextData } from '../store/types';
import FreeToolsBox from './FreeToolsBox.vue';
import GenproxBreadcrumbs from '@/components/layout/GenproxBreadcrumbs.vue';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';

@Component({
  components: { SygniLoader, FreeToolsBox, GenproxBreadcrumbs, GenproxPageHeading },
  computed:{
    ...mapState('genprox', {
      activeUserContext: (state: any) => state.activeUserData.context,
      welcomeText: (state: any) => state.welcomeText,
      authorized: (state: any) => state.activeUserData?.verified,
    }),
    ...mapState('investors', {
      investorData: (state: any) => state.newInvestmentClient,
    }),
    ...mapState('auth', {
      contextId: (state: any) => state.jwtTokenData?.context_id,
    }),
  }
})
export default class FreeTools extends Vue {
  readonly Contexts = Contexts;
  activeUserContext!: UserContext;
  investorData!: InvestmentClient;
  contextId!: string;
  authorized!: boolean;
  welcomeText: WelcomeTextData;
  xmlLoading: boolean = false;
  
  get freeToolsAvailable() {
    return this.activeUserContext.context === Contexts.fundManager || this.activeUserContext.context === Contexts.fundManagerPrivate || this.activeUserContext.context === Contexts.fund
  }

  setLoading(val: boolean) {
    this.xmlLoading = val;
  }
}
