






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class GenproxFormBar extends Vue {
  @Prop({ default: '' }) header: string;
  @Prop({ default: '' }) description: string;
}
