










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class GenproxPageHeading extends Vue {
  @Prop() title!: string;
  @Prop() description!: string;
}
