var render = function () {
  var _vm$validation, _vm$validation2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-input', !_vm.legacyStyling ? 'tw-theme' : '', _vm.displayCheck && (_vm.errors == false || _vm.errors == undefined) && !((_vm$validation = _vm.validation) !== null && _vm$validation !== void 0 && _vm$validation.$invalid) && _vm.value && _vm.editable ? 'sygni-input--with-check' : '', _vm.sygniInputClassList, _vm.useInvalidProperty && (_vm$validation2 = _vm.validation) !== null && _vm$validation2 !== void 0 && _vm$validation2.$invalid ? 'error' : ''],
    attrs: {
      "id": _vm.testId
    }
  }, [!_vm.tooltip ? _c('div', {
    staticClass: "label",
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    }
  }) : _c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "sygni-input__info"
  }, [_vm._v(" i ")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        interactive: false,
        delay: 0,
        animation: false
      },
      expression: "{ interactive: false, delay: 0, animation: false }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "input-container",
    attrs: {
      "title": _vm.nativeTooltip
    }
  }, [_c('input', _vm._g(_vm._b({
    ref: "sygniInputEl",
    class: _vm.unit ? 'with-unit' : '',
    attrs: {
      "disabled": _vm.isDisabled
    },
    domProps: {
      "value": _vm.displayValue
    },
    on: {
      "input": function input($event) {
        return _vm.handleInput($event, $event.target.value);
      },
      "blur": _vm.onBlur,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, 'input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input'))), _vm.clearable && _vm.modelValue ? _c('div', {
    staticClass: "clearable",
    on: {
      "click": function click($event) {
        return _vm.handleInput(null, '');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('X'),
      "alt": "Clear"
    }
  })]) : _vm._e(), _vm.unit ? _c('div', {
    staticClass: "unit-type"
  }, [_vm._v(" " + _vm._s(_vm.unit) + " ")]) : _vm._e(), _vm._t("displayCheck", function () {
    var _vm$validation3;

    return [_vm.displayCheck && (_vm.errors == false || _vm.errors == undefined) && !((_vm$validation3 = _vm.validation) !== null && _vm$validation3 !== void 0 && _vm$validation3.$invalid) && _vm.value ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip:hover",
        value: 'Correct IBAN number',
        expression: "'Correct IBAN number'",
        arg: "hover"
      }],
      staticClass: "confirmation-box"
    }, [_c('div', {
      staticClass: "success-box fe_success_box"
    }, [_c('svg', {
      attrs: {
        "id": "Component_24_260",
        "data-name": "Component 24 – 260",
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "16",
        "height": "16",
        "viewBox": "0 0 16 16"
      }
    }, [_c('path', {
      attrs: {
        "id": "Path_1170",
        "data-name": "Path 1170",
        "d": "M4.084,8.372h0l3.3,3.3,5.264-5.264",
        "transform": "translate(-0.367 -1.044)",
        "fill": "none",
        "stroke": "#fff",
        "stroke-width": "1.5"
      }
    })])])]) : _vm._e(), !_vm.displayCheck && _vm.errorType === 'inline' && _vm.hasLengthError && _vm.value ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.errorMessage ? _vm.errorMessage : _vm.getValidationError(_vm.validation),
        expression: "errorMessage ? errorMessage : getValidationError(validation)",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "confirmation-box fe_error_box"
    }, [_vm._m(0)]) : _vm._e()];
  })], 2), _vm.type === 'number' && _vm.showArrows ? _c('div', {
    staticClass: "counter"
  }, [_c('img', {
    staticClass: "arrow-up",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    },
    on: {
      "click": _vm.increment
    }
  }), _c('img', {
    staticClass: "arrow-down",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    },
    on: {
      "click": _vm.decrement
    }
  })]) : _vm._e(), _vm.errorType === 'default' ? _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation,
      "legacyStyling": _vm.legacyStyling,
      "useInvalidProperty": _vm.useInvalidProperty
    }
  }, [_vm._t("error")], 2) : _vm._e(), _vm.editable ? _c('div', {
    staticClass: "edit-button"
  }, [_vm.isDisabled ? _c('span', {
    on: {
      "click": _vm.enableInput
    }
  }, [_vm._v(" Edit ")]) : _vm._e(), !_vm.isDisabled ? _c('span', {
    on: {
      "click": function click($event) {
        _vm.isDisabled = true;
      }
    }
  }, [_vm._v(" Save ")]) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-box"
  }, [_c('span'), _c('span')]);
}]

export { render, staticRenderFns }