var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AuthModuleBoilerplate', {
    staticClass: "text-container",
    attrs: {
      "size": "textContainer"
    }
  }, [_c('div', {
    staticClass: "auth-module__box"
  }, [_c('div', {
    staticClass: "text-container__content",
    domProps: {
      "innerHTML": _vm._s(_vm.innerHTML)
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }