




























































































































import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {InvestmentClient} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {GlobalSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {
  verifyNewInvestorStepTwoValidation
} from "@/modules/genprox/modules/fund/modules/fundraising/components/add-new-investor/validations";
import {mapGetters, mapState} from "vuex";
import {Prop, Watch} from "vue-property-decorator";
import {InvestorFormViewType} from "@/modules/genprox/modules/fund/modules/fundraising/models/InvestorFormViewType.enum";
import { required } from 'vuelidate/lib/validators';
import AddNewInvestorBoilerplate from './AddNewInvestorBoilerplate.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniInputError,
    SygniDatePicker,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniLoader,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle},
  computed: {
    ...mapGetters('dictionary', {
      investorsSelects: 'investorsSelects',
      globalSelects: 'globalSelects'
    }),
    ...mapState('investors', {
      editInvestmentClientData: (state: any) => state.editInvestmentClientData,
      propagate: (state: any) => state.propagate,
    })
  }
})
export default class AddNewInvestorFormStepTwo extends AddNewInvestorBoilerplate {

  @Prop() investorId!: string;
  @Prop({default: InvestorFormViewType.newInvestor}) viewType!: InvestorFormViewType;

  investorsSelects!: InvestorSelects;
  globalSelects!: GlobalSelects;

  editInvestmentClientData!: InvestmentClient;
  propagate!: boolean;
  investmentData: InvestmentClient = {} as InvestmentClient;

  setPropagate(value: boolean) {
    this.$store.commit('investors/setPropagate', value)
  }

  get isReadOnly() {
    return this.viewType === InvestorFormViewType.previewInvestor
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get backButtonLink() {
    const parentLink = this.$route.path.includes('company') ? 'company' : 'fund';

    return this.viewType === InvestorFormViewType.verifyInvestor ? '/' : `/${parentLink}/fundraising`;
  }

  get isDocumentExpiryFieldDisabled() {
    return !!(this.investmentData?.details?.documentExpiryDate?.indefinitely)
  }

  disabledDate (date: Date) {
    const compareDate = new Date(new Date().toDateString())
    return date < compareDate
  }

  beforeMount(){
    switch (this.viewType){
      case InvestorFormViewType.verifyInvestor:
      case InvestorFormViewType.newInvestor:
        this.investmentData = this.$store.getters['investors/newInvestmentClient'];
        break;
      case InvestorFormViewType.editInvestor:
      case InvestorFormViewType.previewInvestor:
        this.investmentData = this.editInvestmentClientData;
        break;
    }

    if(!this.investmentData.firstName) {
      this.investmentData.firstName = this.activeUserData.user.firstName;
    }
    
    if(!this.investmentData.surname) {
      this.investmentData.surname = this.activeUserData.user.surname;
    }

    if(this.skip2ndStep) {
      this.goNext();
    }
  }

  goNext(): void {
    this.$v.$touch();
    if(!this.$v.$error){
      switch (this.viewType){
        case InvestorFormViewType.newInvestor:
        case InvestorFormViewType.verifyInvestor:
          this.$store.commit('investors/setNewInvestmentClient', this.investmentData);
          break;
        case InvestorFormViewType.editInvestor:
          this.$store.commit('investors/setInvestmentClientToEdit', this.investmentData);
          break;
        case InvestorFormViewType.previewInvestor:
          this.$store.commit('investors/setInvestmentClientToEdit', this.investmentData);
          break;
      }

      if(this.viewType === InvestorFormViewType.editInvestor && this.$route.query?.productId) {
        this.$router.push({ path: `step-three?productId=${this.$route.query?.productId}` });
      } else if (this.viewType === InvestorFormViewType.editInvestor || this.viewType === InvestorFormViewType.previewInvestor) {
        this.$router.push({ path: 'step-three' })
      } else {
        const subscriptionId = this.$route.query?.subscriptionId
        const query: any = this.$route.query?.type === 'subscription_product' ? { type: 'subscription_product', productType: this.$route.query?.productType?.toString() || undefined } : undefined;
        if (subscriptionId && query !== undefined) {
          query['subscriptionId'] = subscriptionId?.toString()
        }
        this.$router.push({ name: 'newInvestorFormStepThree', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/subscription-product/new/step-three`, query })
      }
    } else {
      this.$nextTick(() => {
        this.scrollToError();
      })
    }
  }

  get validationObject() {
    if(this.viewType == 'newInvestor' || this.viewType == 'editInvestor' || this.viewType == 'verifyInvestor') {
      const validation: any = {
        investmentData: {
          details: {
            birthDate: {
              birthDay: (value: string) => {
                if (!value) return true;
                return Date.parse(value) < Date.now();
              },
            },
            documentExpiryDate: {
              expiryDate: {
                expiryDate: (value: string) => {
                  if (this.viewType === InvestorFormViewType.editInvestor) return true;
                  if (!value) return true;
                  const date = new Date(new Date().toDateString())
                  return Date.parse(value) >= date.setDate(date.getDate());
                },
              }
            }
          },
        }
      }

      if(this.isLeadFormFieldRequired('title')) {
        validation.investmentData.details.title = { required }
      }

      if(this.isLeadFormFieldRequired('middle_name')) {
        validation.investmentData.details.middleName = { required }
      }
      
      if(this.isLeadFormFieldRequired('birth_date')) {
        validation.investmentData.details.birthDate.required = required
      }

      if(this.isLeadFormFieldRequired('birth_place')) {
        validation.investmentData.details.birthPlace = { required }
      }

      if(this.isLeadFormFieldRequired('fathers_name')) {
        validation.investmentData.details.fathersName = { required }
      }

      if(this.isLeadFormFieldRequired('mothers_name')) {
        validation.investmentData.details.mothersName = { required }
      }

      if(this.isLeadFormFieldRequired('phone_number')) {
        validation.investmentData.details.phone = { required }
      }

      if(this.isLeadFormFieldRequired('identification_number')) {
        validation.investmentData.details.identificationNumber = { required }
      }

      if(this.isLeadFormFieldRequired('document_type')) {
        validation.investmentData.details.documentType = { required }
      }

      if(this.isLeadFormFieldRequired('document_number')) {
        validation.investmentData.details.documentNumber = { required }
      }

      if(this.isLeadFormFieldRequired('document_expiry_date') && !this.investmentData?.details?.documentExpiryDate?.indefinitely) {
        validation.investmentData.details.documentExpiryDate.expiryDate.required = required
      }

      if(this.isLeadFormFieldRequired('document_country')) {
        validation.investmentData.details.documentCountry = { required }
      }

      if(this.isLeadFormFieldRequired('country')) {
        validation.investmentData.details.country = { required }
      }

      if(this.isLeadFormFieldRequired('tax_office')) {
        validation.investmentData.details.taxOffice = { required }
      }

      if(this.isLeadFormFieldRequired('nationality')) {
        validation.investmentData.details.nationality = { required }
      }

      return validation;
    }


    if (this.viewType !== 'previewInvestor') {
      if ( this.viewType === 'verifyInvestor') {
        return verifyNewInvestorStepTwoValidation;
      }
    }

    return {};
  }

  validations() {
    return this.validationObject;
  }

  @Watch('investmentData.details.documentExpiryDate.indefinitely') onDocumentExpiryIndefinitelyUpdate() {
    if (this.investmentData?.details?.documentExpiryDate?.indefinitely) {
      this.investmentData.details.documentExpiryDate.expiryDate = null
    }
  }
}
