










































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {FundTableActions} from "@/modules/genprox/modules/AIFM/modules/dashboard/store/types";
// import {BACKEND_BASE_URL} from "@/shared/consts";
import SygniTableLegacy from "@/components/table/SygniTableLegacy.vue";


@Component({
  components: {
    SygniRoundedButton,
    UserPresentation,
    ExpandCollapse, BSpinner },
})
export default class FundsTable extends SygniTableLegacy<FundTableItem> {

  FundTableActions = FundTableActions;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', label: 'FUND NAME', class: 'left' },
    { key: 'dateOfDiscontinuationOfAifManagement', label: 'DE-REGISTERED', class: 'center' },
    { key: 'action' },
  ];

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.onMounted();
  }

  performAction(action: FundTableActions, fund: FundTableItem): void {
    switch(action){
      case(FundTableActions.GO_TO_FUND): {
        this.$router.push({ name: 'fund-reg-dashboard', params: { id: fund.id } });
        break;
      }
      default:
        break;
    }
  }

}

export interface FundTableItem {
  id: string,
  name: string,
  dateOfDiscontinuationOfAifManagement: string,
  actions: FundTableActions[]
}

