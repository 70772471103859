var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenproxModal', {
    staticClass: "p-modal",
    attrs: {
      "smallerActionsPadding": true,
      "isLoading": _vm.isLoading,
      "overflow": true,
      "header": "",
      "cancelText": "Cancel",
      "size": "large",
      "confirmText": _vm.confirmText
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "cancel": function cancel($event) {
        return _vm.$emit('cancel');
      },
      "confirm": _vm.handleConfirm
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$participant, _vm$$v, _vm$$v$participant, _vm$$v2, _vm$$v2$participant, _vm$$v3, _vm$$v3$participant, _vm$$v4, _vm$$v4$participant, _vm$$v5, _vm$$v5$participant, _vm$$v6, _vm$$v6$participant, _vm$participant2, _vm$participant3, _vm$participant4, _vm$$v7, _vm$$v7$participant, _vm$$v8, _vm$$v8$participant;

        return [_c('div', {
          staticClass: "p-modal__inner"
        }, [_c('sygni-container-title', {
          staticClass: "p-modal__header mb-5",
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function fn() {
              return [_c('div', {
                staticClass: "actions"
              }, [_c('sygni-toggle-switch', {
                attrs: {
                  "label": "COMPANY REPRESENTATIVE"
                },
                model: {
                  value: _vm.participant.companyRepresentative,
                  callback: function callback($$v) {
                    _vm.$set(_vm.participant, "companyRepresentative", $$v);
                  },
                  expression: "participant.companyRepresentative"
                }
              })], 1)];
            },
            proxy: true
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.type === 'add' ? 'Add' : 'Edit') + " participant")])]), _vm.participant.companyRepresentative ? _c('div', {
          staticClass: "p-modal-options p-modal-options--company"
        }, [_c('div', {
          staticClass: "p-modal-options__container"
        }, [_c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('div', {
          staticClass: "tax-id"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("TAX ID")]), _c('div', {
          staticClass: "tax-id__input"
        }, [_c('sygni-select', {
          attrs: {
            "searchable": true,
            "allow-empty": false,
            "options": _vm.countryCodes,
            "validation": _vm.taxNumberValidation
          },
          model: {
            value: _vm.participant.taxPrefix,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "taxPrefix", $$v);
            },
            expression: "participant.taxPrefix"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "validation": _vm.taxNumberValidation
          },
          model: {
            value: _vm.participant.taxNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "taxNumber", $$v);
            },
            expression: "participant.taxNumber"
          }
        })], 1), _c('sygni-input-error', {
          attrs: {
            "useCustomError": true,
            "validation": _vm.taxNumberValidation
          }
        }, [!((_vm$participant = _vm.participant) !== null && _vm$participant !== void 0 && _vm$participant.taxNumber) ? [_vm._v(" This field is required ")] : [_vm._v(" Invalid Tax Number ")]], 2)], 1)]), _c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": "POSITION",
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$participant = _vm$$v.participant) === null || _vm$$v$participant === void 0 ? void 0 : _vm$$v$participant.position
          },
          model: {
            value: _vm.participant.position,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "position", $$v);
            },
            expression: "participant.position"
          }
        })], 1), _c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": "COMPANY NAME",
            "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$participant = _vm$$v2.participant) === null || _vm$$v2$participant === void 0 ? void 0 : _vm$$v2$participant.companyName
          },
          model: {
            value: _vm.participant.companyName,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "companyName", $$v);
            },
            expression: "participant.companyName"
          }
        })], 1)]), _c('sygni-line', {
          staticClass: "grey mb-4"
        })], 1) : _vm._e(), _c('div', {
          staticClass: "p-modal-options"
        }, [_c('div', {
          staticClass: "p-modal-options__container"
        }, [_c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": "EMAIL",
            "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$participant = _vm$$v3.participant) === null || _vm$$v3$participant === void 0 ? void 0 : _vm$$v3$participant.email
          },
          model: {
            value: _vm.participant.email,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "email", $$v);
            },
            expression: "participant.email"
          }
        })], 1), _c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": 'ROLE',
            "searchable": true,
            "options": _vm.roles,
            "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$participant = _vm$$v4.participant) === null || _vm$$v4$participant === void 0 ? void 0 : _vm$$v4$participant.role
          },
          on: {
            "input": _vm.handleParticipantRoleInput
          },
          model: {
            value: _vm.participant.role,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "role", $$v);
            },
            expression: "participant.role"
          }
        })], 1), _c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": "FIRST NAME",
            "validation": (_vm$$v5 = _vm.$v) === null || _vm$$v5 === void 0 ? void 0 : (_vm$$v5$participant = _vm$$v5.participant) === null || _vm$$v5$participant === void 0 ? void 0 : _vm$$v5$participant.firstName
          },
          model: {
            value: _vm.participant.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "firstName", $$v);
            },
            expression: "participant.firstName"
          }
        })], 1), _c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": "SURNAME",
            "validation": (_vm$$v6 = _vm.$v) === null || _vm$$v6 === void 0 ? void 0 : (_vm$$v6$participant = _vm$$v6.participant) === null || _vm$$v6$participant === void 0 ? void 0 : _vm$$v6$participant.surname
          },
          model: {
            value: _vm.participant.surname,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "surname", $$v);
            },
            expression: "participant.surname"
          }
        })], 1), ((_vm$participant2 = _vm.participant) === null || _vm$participant2 === void 0 ? void 0 : _vm$participant2.role) === 'SIGNER' ? _c('div', {
          staticClass: "p-modal-options__single"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": 'SIGNATURE TYPE',
            "placeholder": ((_vm$participant3 = _vm.participant) === null || _vm$participant3 === void 0 ? void 0 : _vm$participant3.role) === 'SIGNER' ? 'Select option' : '',
            "disabled": ((_vm$participant4 = _vm.participant) === null || _vm$participant4 === void 0 ? void 0 : _vm$participant4.role) !== 'SIGNER',
            "searchable": true,
            "options": _vm.signatureTypes,
            "validation": (_vm$$v7 = _vm.$v) === null || _vm$$v7 === void 0 ? void 0 : (_vm$$v7$participant = _vm$$v7.participant) === null || _vm$$v7$participant === void 0 ? void 0 : _vm$$v7$participant.signatureType
          },
          model: {
            value: _vm.participant.signatureType,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "signatureType", $$v);
            },
            expression: "participant.signatureType"
          }
        }), _c('div', {
          staticClass: "checkboxes"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "value": true,
            "label": "Authorization via SMS code"
          },
          model: {
            value: _vm.participant.authorizationViaSmsCode,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "authorizationViaSmsCode", $$v);
            },
            expression: "participant.authorizationViaSmsCode"
          }
        })], 1), _vm.showMobile ? _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": "PHONE NUMBER",
            "validation": (_vm$$v8 = _vm.$v) === null || _vm$$v8 === void 0 ? void 0 : (_vm$$v8$participant = _vm$$v8.participant) === null || _vm$$v8$participant === void 0 ? void 0 : _vm$$v8$participant.mobilePhone
          },
          model: {
            value: _vm.participant.mobilePhone,
            callback: function callback($$v) {
              _vm.$set(_vm.participant, "mobilePhone", $$v);
            },
            expression: "participant.mobilePhone"
          }
        }) : _vm._e()], 1) : _vm._e()])])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }