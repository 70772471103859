

































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Prop} from 'vue-property-decorator';
import {BSpinner} from "bootstrap-vue";
import { mapState } from 'vuex';
import { Package } from '@/modules/genprox/models/Package';

Component.registerHooks(['validations'])
@Component({
  components: {SygniRoundedButton, BSpinner},
  computed: {
    ...mapState('genprox', {
      packages: (state: any) => state.packages,
    }),
  },
})

export default class SygniModal extends Vue {
  @Prop() header: string;
  @Prop() description?: string;
  @Prop({default: 'normal'}) size?: string;
  @Prop({default: 'Quit without saving'}) cancelText?: string;
  @Prop({default: 'Save changes & quit'}) confirmText?: string;
  @Prop() isLoading!: boolean;

  packages!: Array<Package>;

  get hasContentSlot(): boolean {
    return !!this.$slots.content;
  }

  goToPurchase() {
    const redirectionModules = this.$route?.meta?.accessModule || []

    if (redirectionModules) {
      let moduleToRedirect: any = null

      redirectionModules?.forEach((redModule: any) => {
        if (!moduleToRedirect) {
          const redirectionModule = this.packages?.find((el: Package) => el?.accessModules[redModule])
  
          if (redirectionModule) {
            moduleToRedirect = redirectionModule
          }
        }
      })

      if (moduleToRedirect) {
        this.$router.push(`/fund/accept-package/${moduleToRedirect?.id}`)
      } else {
        this.$router.push('/plans')
      }
    } else {
      this.$router.push('/plans')
    }
  }
}
