

































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Statues} from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniInnerTable from "@/components/table/SygniInnerTable.vue";
import { Product, emptyBillProduct} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { BTable } from 'bootstrap-vue'
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRectButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, SygniCheckbox }
})
export default class InvestorsInnerTable extends SygniInnerTable<Product> {
  @Prop({ default: null }) investorId: string | null;

  bulkOptionsMarginTop: number = 0
  selectedProducts: string[] = []
  selectAllProductsOption: boolean = false

  innerTableFields = [
    {key: 'code', sortable: false, class: ['code']},
    {key: 'type', sortable: false, class: ['type']},
    {key: 'sourceName', sortable: false, class: 'source-name'},
    {key: 'ownerName', sortable: false, class: 'owner-name'},
    {key: 'autentiStatus', label: 'E-signature status', sortable: false, class: ['status']},
    {key: 'agreementDate', sortable: false, class: ['agreement-date']},
    {key: 'signedStatus', label: 'Signed Status', sortable: false, class: ['status']},
    {key: 'status', label: 'Status', sortable: false, class: ['status']},
    {key: 'value', sortable: false, borderless: true, class: ['value', 'text-right']},
    {key: 'actions', label: '', sortable: false, class: 'actions'}
  ]

  get innerTableFieldsComputed() {
    let fields: any = this.innerTableFields

    if (!this.isMarketplaceAccountant) {
      fields.unshift({ key: 'selected', sortable: false, label: '', class: ['selected'] })
    }

    return fields
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasJointStockSharesProducts() {
    const selectedProducts = this.selectedProducts?.map((id: string) => {
      return this.items?.find((el: any) => el?.id === id) || id
    })

    return selectedProducts?.some((product: any) => product?.type === 'JOINT_STOCK_SHARES')
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  createAnnex(type: 'annex' | 'rollover') {
    this.$store.commit('investors/setNewProduct', emptyBillProduct)
    this.$store.commit('investors/clearAnnexingData');
    this.$store.commit('investors/setNewProductAnnexData', { type, ids: this.selectedProducts })
    this.$store.commit('investors/setAnnexingProducts', this.items.filter((el: any) => this.selectedProducts.includes(el.id)));
    this.$store.commit('investors/setNewProductInvestor', this.investorId);
    
    if (this.$route.path.includes('company')) {
      // this.$router.push('/company/fundraising/product/new/step-one');
      this.$router.push({ name: 'addNewProductStepOne', path: '/company/fundraising/product/new/step-one', params: { investorId: this.investorId } });
    } else {
      // this.$router.push('/fund/fundraising/product/new/step-one');
      this.$router.push({ name: 'addNewProductStepOne', path: '/fund/fundraising/product/new/step-one', params: { investorId: this.investorId } });
    }

    // openAddNewProductForm(id: string): void {
    //   this.$store.commit('investors/clearAnnexingData');
    //   this.$store.commit('investors/setNewProductInvestor', id);
    //   if(this.$route.path.includes('company')) {
    //   this.$router.push({ name: 'addNewProductStepOne', path: '/company/fundraising/product/new/step-one', params: { investorId: id } });
    // } else {
    //   this.$router.push({ name: 'addNewProductStepOne', path: '/fund/fundraising/product/new/step-one', params: { investorId: id } });
    // }
  }

  canBeAnnexed(item: any) {
    return item?.type?.toUpperCase() !== 'JOINT_STOCK_SHARES' && item.status.toUpperCase() === 'ACTIVE' && !item.annexTargetReferenceId
  }

  clearTableSelection() {
    this.selectedProducts = []
  }

  selectAllProducts() {
    this.$nextTick(() => {
      if(this.selectAllProductsOption) {
        const allProductIds = this.items.filter((el: any) => this.canBeAnnexed(el)).map((el: any) => el.id)
        
        this.selectedProducts = allProductIds
        
        const index = this.items.findIndex((el: any) => this.canBeAnnexed(el))
        const selectedRowEl = (this.$refs.innerTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
        this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      } else {
        this.clearTableSelection()
      }
    })
  }

  selectProduct(id: string, rowData: any) {
    this.$nextTick(() => {
      if (this.selectedProducts.includes(id)) {
        const index = this.selectedProducts.findIndex((el: string) => el === id)
        this.selectedProducts.splice(index, 1)
      } else {
        this.selectedProducts.push(id)
      }

      const selectedRowEl = (this.$refs.innerTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${rowData.index + 1})`);
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    })
  }

  isSelected(id: string) {
    return this.selectedProducts.includes(id) ? true : false
  }

  statusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case(Statues.new): {
        return 'black';
      }
      
      case(Statues.active): {
        return 'primary';
      }
      case(Statues.invited): {
        return 'success';
      }
      case(Statues.awaiting): {
        return 'success';
      }
      case(Statues.pending): {
        return 'black';
      }
      case(Statues.rejected): {
        return 'danger';
      }
      case(Statues.closed): {
        return 'disabled';
      }
      case(Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch(status.toUpperCase()) {
      case(Statues.new): {
        return 'New';
      }
      case(Statues.active): {
        return 'Active';
      }
      case(Statues.invited): {
        return 'Awaiting';
      }
      case(Statues.awaiting): {
        return 'Awaiting';
      }
      case(Statues.pending): {
        return 'Pending';
      }
      case(Statues.rejected): {
        return 'Rejected';
      }
      case(Statues.closed): {
        return 'Closed';
      }
      case(Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  signedStatusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case('NEW'): {
        return 'black';
      }
      
      case('COMPLETED'): {
        return 'primary';
      }
      case('SENT'): {
        return 'success';
      }
      case('E-SIGNED'): {
        return 'success';
      }
    }
    return '';
  }

  signedStatusText(status: Statues): string {
    switch(status.toUpperCase()) {
      case('NEW'): {
        return 'New';
      }
      case('COMPLETED'): {
        return 'Completed';
      }
      case('SENT'): {
        return 'Sent';
      }
      case('E-SIGNED'): {
        return 'E-signed';
      }
    }
    return '';
  }

  autentiStatusClass(item: any) {
    const autentiStatus = item?.eSigned ? 'COMPLETED' : item?.autentiStatus ? item?.autentiStatus?.toUpperCase() : 'NONE'

    switch(autentiStatus) {
      case('NEW'): {
        return 'black'
      }
      case('UNKNOWN'):
      case('WITHDRAWING'): {
        return 'disabled';
      }
      case('COMPLETED'): {
        return 'primary';
      }
      case('PROCESSING'):
      case('DRAFT'): {
        return 'success';
      }
      case('ERROR'):
      case('WITHDRAWN'):
      case('REJECTED'): {
        return 'danger';
      }
    }
    return 'black';
  }

  autentiStatusText(item: any): string {
    const status = item?.eSigned ? 'COMPLETED' : item?.autentiStatus
    const autentiStatus = this.autentiStatuses?.find((el: any) => el?.value?.toUpperCase() === status?.toUpperCase())

    return autentiStatus?.label || item?.autentiStatus || ''
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.onMounted();
  }

  getProductSummaryLink(product: Product) {
    if (product.status === Statues.pending) {
      return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/accept-by-legal-entity/${product.id}`
    } else {
      return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${product.id}/for-legal-entity`
    }
  }

  async goToProductSummary(product: Product) {
    this.$store.commit('investors/clearAnnexingData');
    await this.$router.push({ path: this.getProductSummaryLink(product) })
  }
}

