







































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import FormBoilerplate from '../FormBoilerplate.vue';
import { required, sameAs } from 'vuelidate/lib/validators';
import { ResetPasswordData } from '../../store/types';

Component.registerHooks(['validations'])
@Component({
  components: { SygniInput, SygniRectButton, SygniLinkButton, FormBoilerplate }
})
export default class ResetPasswordPage extends Vue {
  password: string = '';
  repeatPassword: string = '';
  loading: boolean = false;

  async resetPassword(){
    const resetPasswordData: ResetPasswordData = { plainPassword: this.password, token: this.$route.params?.id };
    this.$v.$touch();
    if(!this.$v.$error) {
      this.loading = true
      try{
        await this.$store.dispatch('auth/resetPassword', resetPasswordData);
        this.$notify({
          type: 'success',
          title: 'Password has been changed'
        });
        await this.$router.push({name: 'login'});
      } catch (error) {
        const errorMessage = this.$options?.filters?.errorHandler(error)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.password = '';
      this.repeatPassword = '';
      this.$v.$reset();
      this.loading = false
    }
  }

  validations (){
    return  {
      password: {required, strongPassword: (value: string) => {
          return Boolean(value?.match('^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$'));
        }},
      repeatPassword: {required, sameAsPassword: sameAs('password')},
    }
  }
}
