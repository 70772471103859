















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import FormBoilerplate from '../FormBoilerplate.vue';
import { required, email } from 'vuelidate/lib/validators';
import { LoginCredentials } from '../../store/types';

Component.registerHooks(['validations'])
@Component({
  components: { SygniInput, SygniRectButton, SygniLinkButton, FormBoilerplate }
})
export default class LoginPage extends Vue {
  loginCredentials: LoginCredentials = {email: '', password: ''} as LoginCredentials;
  loading: boolean = false;

  validations (){
    return {
      loginCredentials: {
        password: { required },
        email: { required, email }
      }
    }
  }

  async login(){
    this.$v?.$touch()
    this.loading = true;

    if(!this.$v?.$error){
      await this.$store.dispatch('auth/login', this.loginCredentials);
      this.loginCredentials.email = '';
      this.loginCredentials.password = '';
      this.$v.$reset();
    }

    this.loading = false;
  }

  goToRemindPassword(){
    this.$router.push({name: 'remind-password'});
  }
}
