














































































































































































































































































































































































import Component from 'vue-class-component'
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import {AddProductSelects, GlobalSelects} from "@/modules/shared/dictionary-store/types";
import {Address, DebtorAccount, NewProduct , TimeTypes} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import { ibanSupportedCountries, usedCurrencies } from "@/shared/consts";
import {integer, minValue, maxValue, or, required, requiredIf, between} from "vuelidate/lib/validators";
import {Watch} from "vue-property-decorator";
import {mapGetters, mapState} from "vuex";
import {Wallet} from "@/shared/interfaces/Wallet";
// import { iban } from '@/shared/validators';
import { AttachmentListItem, Template, TemplateAttachment } from '../../modules/templates/store/types';
import { SelectOption } from '@/store/types';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import Attachments from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/components/Attachments.vue';
import { ProductSummaryIssuer, ProductSummaryInvestor } from '../../models/ProductSummaryData.interface';
import AttachmentModal from '../../modules/templates/components/AttachmentModal.vue';
import FileUploader from '@/components/FileUploader.vue';
import AttachmentsTable from '@/modules/accounting/components/AttachmentsTable.vue';
import { Contexts } from '@/modules/genprox/models/User';
import CapitalRisePermissions from '../CapitalRisePermissions.vue';
import { greaterThan, iban } from '@/shared/validators';
import { create, all } from 'mathjs'
import { ShareListOption } from '../../modules/subscriptions/store/types';
import { BTooltip } from 'bootstrap-vue'
import _ from 'lodash';
const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniToggleSwitch,
    SygniRadio,
    SygniSelect,
    SygniRoundedButton,
    SygniCheckbox,
    SygniInput,
    SygniInfoBox,
    SygniDatePicker,
    SygniContainerTitle,
    EditorComponent,
    SygniRectButton,
    SygniFileBox,
    Attachments,
    AttachmentModal,
    SygniLinkButton,
    SygniTextArea,
    FileUploader, 
    AttachmentsTable,
    BTooltip
  },
    computed: {
      ...mapGetters('dictionary', {
        productSelects: 'addProductsSelects',
        globalSelects: 'globalSelects',
      }),
      ...mapGetters('genprox', {
        activeContextWallets: 'activeContextWallet'
      }),
      ...mapState('products', {
        attachmentsUploadProgress: (state: any) => state.attachmentsUploadProgress,
      })
    }
})
export default class AddNewProductStepTwo extends CapitalRisePermissions {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png', 'doc', 'docx'];
  attachmentsUploadProgress!: any;
  isAttachmentsTableBusy: boolean = false;

  usedCurrencies: any = usedCurrencies;
  fixedReplacementDay: boolean = true;
  pageInitialized: boolean = false;
  @Watch('fixedReplacementDay') onFixedReplacementDay() {
    this.removeUnselectedProductData();
  }
  productSelects!: AddProductSelects;
  activeContextWallets!: Array<Wallet>;
  globalSelects!: GlobalSelects;
  initialPaymentBankAccountCountry: string = 'PL';
  principalPaymentBankAccountCountry: string = 'PL';
  interestPaymentBankAccountCountry: string = 'PL';
  templatesList: Template[] = [];
  openedTab: string = 'contract';
  agreementTemplate: any = null;

  showAttachmentsModal: boolean = false;
  attachmentListItems: AttachmentListItem[] = [];
  isAttachmentModalLoading: boolean = false;
  editableAttachmentIndex: number | null = null;
  areFilesLoading: boolean = false;
  attachmentsToUpload: TemplateAttachment[] = [];
  issuer: ProductSummaryIssuer = null;
  investor: ProductSummaryInvestor = null;
  legalEntityInvestorData: any = null;
  investorDetails: any = null;

  showAttachmentPreviewModal: boolean = false;
  selectedAttachment: any = null;

  seriesOfShares: any[] = []
  legalEntityData: any = null
  subscription: any = null
  subscriptionSlots: any = null
  product: NewProduct | any = {} as NewProduct;
  seriesOfSharesList: ShareListOption[] = []

  campaigns: any[] = [];
  assignCampaign: boolean = false;
  assignedCampaign: string | null = null;
  campaign: any = null
  campaignInvestmentReach: number | null = null
  campaignInvestmentValue: number | null = null
  isLoading: boolean = false

  handleInstrumentCountBlur() {
    this.$v?.product?.data?.seriesOfShares?.instrumentCount?.$touch()
  }

  handleCounterFromBlur() {
    this.$v?.product?.data?.seriesOfShares?.counterFrom?.$touch()
  }

  get isDebtProduct() {
    return !!(this.product?.type?.toUpperCase() === 'LOAN' || this.product?.type?.toUpperCase() === 'BILL_AGREEMENT')
  }

  get campaignOptions() {
    let campaigns = []

    const campaignItems = this.campaigns.map((el: any) => {
      const obj: any = { label: el.campaignName, value: el.id }

      if (el?.legalEntityName) {
        obj.legalEntityName = el?.legalEntityName
      }

      return obj;
    }) || []

    if (this.product?.campaignId && !campaignItems?.find((el: any) => el.value === this.product?.campaignId)) {
      campaigns.push({ label: this.product?.campaignName, value: this.product?.campaignId })
    }

    campaigns = _.uniq(campaigns.concat(campaignItems))

    return campaigns || [];
  }

  get campaignValidation() {
    if (this.campaignInvestmentReach !== null && this.campaignInvestmentValue !== null && this.assignCampaign) {
      const remaining = math.number(math.subtract(math.bignumber(this.campaignInvestmentValue), math.bignumber(this.campaignInvestmentReach)))
      const difference = math.number(math.subtract(math.bignumber(this.product.investmentValue), math.bignumber(remaining)))

      if (this.product.investmentValue > remaining) {
        return {
          $error: true,
          message: `If you assign this product to this campaign, the campaign will exceed the investment amount by <span class="text-nowrap">${this.$options.filters.numberFormat(difference, 2)}${this.product.currency}</span>`,
          modalMessage: `If you save this product, the campaign will exceed the investment amount by <span class="text-nowrap">${this.$options.filters.numberFormat(difference, 2)}${this.product.currency}</span>`
        }
      }
    }

    return {
      $error: false,
      message: ''
    }
  }

  get disableSeriesOfSharesAmounts() {
    return !this.product?.data?.seriesOfShares?.name || !this.currentAvailableSubscriptionSlots?.length ? true : false
  }

  get currentAvailableSubscriptionSlots() {
    const currentSubscriptionData: any = this.subscriptionSlots?.find((el: any) => el?.seriesOfSharesName === this.product?.data?.seriesOfShares?.name)

    return currentSubscriptionData?.slots || []
  }

  get differentIssueValue() {
    if (this.isEquity || this.product?.type === 'LLC_SHARES') {
      return !!(this.product?.data?.llcShares?.issueValue !== this.subscription?.llcShares?.issueValue)
    }

    return false
  }

  get totalInvestmentValue() {
    if (this.isEquity) {
      if (this.product?.type === 'JOINT_STOCK_SHARES') {
        const objectKey = this.product?.type === 'JOINT_STOCK_SHARES' ? 'seriesOfShares' : 'llcShares'
        return math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))) || 0
      }

      return this.product?.investmentValue
    }
    
    return 0
  }

  get seriesOfSharesOptions() {
    const options = this.seriesOfShares.map((el: any) => {
      return { label: el.name, value: el.name }
    })

    return _.uniqBy(options, 'value') || []
  }

  get subscriptionPool() {
    const seriesName: string = this.product?.data?.seriesOfShares?.name

    const series: any = this.subscriptionSlots?.find((el: any) => el?.seriesOfSharesName === seriesName)

    const seriesInstrumentCount = series?.subscriptionPool
    const productInstrumentCount = this.product?.data?.seriesOfShares?.instrumentCount

    const finalNumber = math.number(math.subtract(math.bignumber(seriesInstrumentCount || 0), math.bignumber(productInstrumentCount || 0)))

    return finalNumber >= 0 ? finalNumber : 0
  }

  get shareListOptionCounters() {
    const counters: any = {}

    this.seriesOfSharesList?.forEach((el: any) => {
      let shareValue: number = el?.nextCounterFrom

      this.$set(counters, el?.seriesOfSharesName, shareValue)
    })

    return counters
  }

  get isEquity() {
    return this.product.type === 'JOINT_STOCK_SHARES' || this.product.type === 'LLC_SHARES'
  }

  get productDataName(): string {
    return 'data';
    // return this.product.type === 'LOAN' ? 'loanData' : 'billAgreementData';
  }

  get disableInitialPaymentSwiftCode() {
    return this.product[this.productDataName].initialPaymentBankAccount.country?.toUpperCase() !== 'PL' ? false : true;
  }

  get disablePrincipalPaymentSwiftCode() {
    return this.product[this.productDataName].principalPaymentBankAccount.country?.toUpperCase() !== 'PL' ? false : true;
  }

  get disableInterestPaymentSwiftCode() {
    return this.product[this.productDataName].interestPaymentBankAccount.country?.toUpperCase() !== 'PL' ? false : true;
  }
  
  get hasTemplateChosen() {
    return this.product.agreementTemplateId ? true : false;
  }
  get activeContextWalletsOptions() {
    return this.activeContextWallets.map( wallet => ({label: this.$options.filters.bankAccountNumber(wallet.account), value: wallet.account}))
  }
  get ibanSupportedCountries() {
    return ibanSupportedCountries;
  }
  get initialPaymentBankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.product[this.productDataName].initialPaymentBankAccount.country) ? 'iban' : false;
  }
  get principalPaymentBankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.product[this.productDataName].principalPaymentBankAccount.country) ? 'iban' : false;
  }
  get interestPaymentBankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.product[this.productDataName].interestPaymentBankAccount.country) ? 'iban' : false;
  }
  get initialPaymentBankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.product[this.productDataName].initialPaymentBankAccount.country)) ? true : false;
  }
  get principalPaymentBankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.product[this.productDataName].principalPaymentBankAccount.country)) ? true : false;
  }
  get interestPaymentBankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.product[this.productDataName].interestPaymentBankAccount.country)) ? true : false;
  }
  get attachmentOptions(): SelectOption[] {
    return this.templatesList.map((el: Template) => {
      return { label: el.title, value: el.id }
    })
  }
  get disableProductCode () {
    return this.product?.autogeneratedCode
  }

  get investorId() {
    return this.$route.params?.investorId || null
  }

  setDescription(value: any) {
    this.product.description = value;
  }

  setSeriesName(value: string) {
    this.product.data.seriesOfShares.name = value
    this.$nextTick(() => {
      const subscriptionSeries = this.currentAvailableSubscriptionSlots[0]
      
      this.product.data.seriesOfShares.counterFrom = subscriptionSeries?.counterFrom || null
      this.product.data.seriesOfShares.instrumentCount = null
    })
  }

  setBillNumber(value: string) {
    this.product[this.productDataName].billNumber = value;
  }

  setDurationType(value: string) {
    if(this.product[this.productDataName].duration === null) {
      this.product[this.productDataName].duration = {
        type: value,
        count: undefined,
      }
    } else {
      this.product[this.productDataName].duration.type = value;
    }
  }

  setDurationCount(value: number) {
    if (this.product[this.productDataName].duration === null) {
      this.product[this.productDataName].duration = {
        type: undefined,
        count: !isNaN(value) ? value : null,
      }
    } else {
      this.product[this.productDataName].duration.count = !isNaN(value) ? value : null;
    }
  }

  setInterestPaidWithin(value: number) {
    this.product[this.productDataName].interestPaidWithin = !isNaN(value) ? value : null;
  }

  setInterestType(value: string) {
    value = 'Fixed'
    this.product[this.productDataName].interestType = value
  }

  setInterestPA(value: any) {
    this.product[this.productDataName].simpleOptions.interest = value ? value : null;
  }

  setCommissionPA(value: any) {
    this.product[this.productDataName].advancedOptions.commission = value ? value : null;
  }

  setMarginPA(value: any) {
    this.product[this.productDataName].advancedOptions.margin = value ? value : null;
  }

  closeAttachmentPreviewModal() {
    this.showAttachmentPreviewModal = false;
  }

  openAttachmentPreview(attachment: any) {
    this.selectedAttachment = attachment;
    this.showAttachmentPreviewModal = true;
  }

  setBankAccountsOwner(owner: string) {
    this.product[this.productDataName].initialPaymentBankAccount.owner = owner;
    this.product[this.productDataName].principalPaymentBankAccount.owner = owner;
    this.product[this.productDataName].interestPaymentBankAccount.owner = owner;
  }

  setDebtor(value: string) {
    const option: any = this.activeContextWallets.find((el: any) => el.account === value);

    const debtor = {
      account: option.account,
      name: option.name,
      currency: option.currency,
      type: option.type,
    }
    this.product[this.productDataName].debtor = debtor;
  }

  openAgreementTab() {
    if(this.hasTemplateChosen) {
      this.openedTab = 'agreement';
    }
  }
  async loadTemplate() {
    if(this.product?.agreementTemplateId) {
      const response = await this.$store.dispatch('templates/getTemplate', this.product.agreementTemplateId);
      this.agreementTemplate = response;
    } else {
      this.agreementTemplate = null;
    }
  }
  handleInitialPaymentBankAccountKeyEvent(e: string): void {
    this.product[this.productDataName].initialPaymentBankAccount.account = e.toLocaleUpperCase();
  }
  handlePrincipalPaymentBankAccountKeyEvent(e: string): void {
    this.product[this.productDataName].principalPaymentBankAccount.account = e.toLocaleUpperCase();
  }
  handleInterestPaymentBankAccountKeyEvent(e: string): void {
    this.product[this.productDataName].interestPaymentBankAccount.account = e.toLocaleUpperCase();
  }
  selectedBankAccountName(accountNumber: DebtorAccount): string {
    return this.activeContextWallets.find(wallet => wallet.account === accountNumber?.account)?.name;
  }
  selectedBankAccountCurrency(accountNumber: DebtorAccount): string {
    return this.activeContextWallets.find(wallet => wallet.account === accountNumber?.account)?.currency;
  }

  overwriteInitialPaymentBankAccount(e: any) {
    if (e) this.product[this.productDataName].initialPaymentBankAccount.account = e?.target.value;
    if (this.product[this.productDataName].initialPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].initialPaymentBankAccount.country)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.product[this.productDataName].initialPaymentBankAccount.account.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.product[this.productDataName].initialPaymentBankAccount.account.substring(1, 2));
      if (!containsCode && this.product[this.productDataName].initialPaymentBankAccount.country) {
        this.product[this.productDataName].initialPaymentBankAccount.account = `${this.product[this.productDataName].initialPaymentBankAccount.country}${this.product[this.productDataName].initialPaymentBankAccount.account}`;
      }
      if (containsCode && this.product[this.productDataName].initialPaymentBankAccount.country) {
        this.product[this.productDataName].initialPaymentBankAccount.account = `${this.product[this.productDataName].initialPaymentBankAccount.country}${this.product[this.productDataName].initialPaymentBankAccount.account.slice(2, this.product[this.productDataName].initialPaymentBankAccount.account.length)}`
      }
    }
  }

  overwritePrincipalPaymentBankAccount(e: any) {
    if (e) this.product[this.productDataName].principalPaymentBankAccount.account = e?.target.value;
    if (this.product[this.productDataName].principalPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].principalPaymentBankAccount.country)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.product[this.productDataName].principalPaymentBankAccount.account.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.product[this.productDataName].principalPaymentBankAccount.account.substring(1, 2));
      if (!containsCode && this.product[this.productDataName].principalPaymentBankAccount.country) {
        this.product[this.productDataName].principalPaymentBankAccount.account = `${this.product[this.productDataName].principalPaymentBankAccount.country}${this.product[this.productDataName].principalPaymentBankAccount.account}`;
      }
      if (containsCode && this.product[this.productDataName].principalPaymentBankAccount.country) {
        this.product[this.productDataName].principalPaymentBankAccount.account = `${this.product[this.productDataName].principalPaymentBankAccount.country}${this.product[this.productDataName].principalPaymentBankAccount.account.slice(2, this.product[this.productDataName].principalPaymentBankAccount.account.length)}`
      }
    }
  }

  overwriteInterestPaymentBankAccount(e: any) {
    if (e) this.product[this.productDataName].interestPaymentBankAccount.account = e?.target.value;
    if (this.product[this.productDataName].interestPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].interestPaymentBankAccount.country)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.product[this.productDataName].interestPaymentBankAccount.account.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.product[this.productDataName].interestPaymentBankAccount.account.substring(1, 2));
      if (!containsCode && this.product[this.productDataName].interestPaymentBankAccount.country) {
        this.product[this.productDataName].interestPaymentBankAccount.account = `${this.product[this.productDataName].interestPaymentBankAccount.country}${this.product[this.productDataName].interestPaymentBankAccount.account}`;
      }
      if (containsCode && this.product[this.productDataName].interestPaymentBankAccount.country) {
        this.product[this.productDataName].interestPaymentBankAccount.account = `${this.product[this.productDataName].interestPaymentBankAccount.country}${this.product[this.productDataName].interestPaymentBankAccount.account.slice(2, this.product[this.productDataName].interestPaymentBankAccount.account.length)}`
      }
    }
  }

  loadCorrectRadioButtons() {
    this.fixedReplacementDay = (this.product[this.productDataName].duration?.type) ? false : true;
    if (this.product[this.productDataName].debtor && !(this.activeContextWallets.find((el: any) => el.account === this.product[this.productDataName].debtor))) {
      this.customAccountInput = true;
    } else {
      this.pageInitialized = true;
    }
  }

  async loadCampaigns() {
    try {
      const resp = await this.$store.dispatch('campaigns/getAllCampaigns', this.product?.type)
      this.campaigns = resp;
    } catch (error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async checkCampaignTotals() {
    const campaign = await this.$store.dispatch('campaigns/getCampaign', this.assignedCampaign)
    this.campaign = campaign
    this.campaignInvestmentReach = campaign?.investmentReach
    this.campaignInvestmentValue = campaign?.investmentValue
  }

  async runCampaignTotalsCheck() {
    if (this.assignedCampaign) {
      this.isLoading = true
      try {
        await this.checkCampaignTotals()
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isLoading = false
    } else {
      this.campaignInvestmentReach = null
      this.campaignInvestmentValue = null
    }
  }

  async addFiles(files: Array<File>): Promise<void> {
    this.isAttachmentsTableBusy = true;
    let formattedFiles = files?.map((file: any) => {
      return { fileName: file?.name, file }
    })

    if (this.product?.files?.length) {
      formattedFiles = [...this.product.files, ...formattedFiles]?.map((file: any, index: number) => {
        file.id = index
        return file
      })
    } else {
      formattedFiles = [...formattedFiles]?.map((file: any, index: number) => {
        file.id = index
        return file
      })
    }

    // const payload = { files };
    this.product.files = formattedFiles

    this.isAttachmentsTableBusy = false;
    this.$store.commit('products/setAttachmentsUploadProgress', -1);
  }

  async removeAttachment(id: string) {
    this.isAttachmentsTableBusy = true;

    let files = _.cloneDeep(this.product.files)
    files.splice(id, 1)
    files = [...files]?.map((file: any, index: number) => {
      file.id = index
      return file
    })

    this.$set(this.product, 'files', files)

    this.isAttachmentsTableBusy = false;
  }

  @Watch('product.campaignId') onCampaignProductOfferIdChange() {
    this.assignedCampaign = this.product?.campaignId
  }

  @Watch('assignedCampaign') async onAssignCampaignChange() {
    await this.runCampaignTotalsCheck()
  }

  @Watch('product.data.initialPaymentBankAccount.country') onInitialPaymentBankAccountCountryChange() {
    this.overwriteInitialPaymentBankAccount(false);
  }

  @Watch('product.data.principalPaymentBankAccount.country') onPrincipalPaymentBankAccountCountryChange() {
    this.overwritePrincipalPaymentBankAccount(false);
  }

  @Watch('product.data.interestPaymentBankAccount.country') onInterestPaymentBankAccountCountryChange() {
    this.overwriteInterestPaymentBankAccount(false);
  }

  customAccountInput: boolean = false;
  @Watch('customAccountInput') onAccountInputTypeChange(){
    if(this.pageInitialized) {
      this.product[this.productDataName].debtor = '';
    } else {
      this.pageInitialized = true;
    }
  }

  @Watch('product.agreementTemplateId', {deep: true}) onProductChange(){
    this.loadTemplate();
    this.removeUnselectedProductData();
  }

  @Watch('product.loanData.enabledAdvancedOptions', { deep: true }) onLoanEnabledAdvancedOptionsChanged() {
    if(this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.advancedOptions === null) {
      this.product[this.productDataName].advancedOptions = {
        commission: null,
        commissionPayment: 'Monthly',
        commissionType: 'percent',
        margin: null,
        marginType: 'percent',
        marginPayment: 'Monthly',
      }
    } else if(!this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.simpleOptions === null) {
      this.product[this.productDataName].simpleOptions = {
        interest: null,
        interestPayment: 'Monthly',
      }
    }
  }

  @Watch('product.data.enabledAdvancedOptions', { deep: true }) onDataEnabledAdvancedOptionsChanged() {
    if(this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.advancedOptions === null) {
      this.product[this.productDataName].advancedOptions = {
        commission: null,
        commissionPayment: 'Monthly',
        commissionType: 'percent',
        margin: null,
        marginType: 'percent',
        marginPayment: 'Monthly',
      }
    } else if(!this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.simpleOptions === null) {
      this.product[this.productDataName].simpleOptions = {
        interest: null,
        interestPayment: 'Monthly',
      }
    }
  }

  @Watch('product.billAgreementData.enabledAdvancedOptions', { deep: true }) onBillEnabledAdvancedOptionsChanged() {
    if(this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.advancedOptions === null) {
      this.product[this.productDataName].advancedOptions = {
        commission: null,
        commissionPayment: 'Monthly',
        commissionType: 'percent',
        margin: null,
        marginType: 'percent',
        marginPayment: 'Monthly',
      }
    } else if(!this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.simpleOptions === null) {
      this.product[this.productDataName].simpleOptions = {
        interest: null,
        interestPayment: 'Monthly',
      }
    }
  }

  async loadSubscriptionSlots() {
    this.subscriptionSlots = await this.$store.dispatch('subscriptions/getSlots', this.product?.subscriptionId)

    this.subscriptionSlots = this.subscriptionSlots?.map((el: any) => {
      if (el?.seriesOfSharesName === this.product?.data?.seriesOfShares?.name) {
        el.subscriptionPool = math.number(math.bignumber(math.add(math.bignumber(el.subscriptionPool), math.bignumber(this.product?.data?.seriesOfShares?.instrumentCount || 0))))
      }

      return el
    })
  }

  async loadSubscriptionData() {
    try {
      const subscription = await this.$store.dispatch('subscriptions/getSubscription', this.product?.subscriptionId)
      this.subscription = subscription

      if (subscription?.legalEntityId) {
        this.legalEntityData = await this.$store.dispatch('genprox/getLeContextData', subscription?.legalEntityId)
      }

      await this.loadSubscriptionSlots()

      this.product.agreementTemplateId = subscription?.productAgreementTemplate?.id || null
      this.product.agreementDate = subscription?.agreementDate || null
      this.product.currency = subscription?.currency || 'PLN'
      this.product.autogeneratedCode = true
      this.seriesOfShares = subscription?.seriesOfShares

      if (this.product.type === 'JOINT_STOCK_SHARES' && this.product.data?.seriesOfShares?.name) {
        const selectedSeries = subscription?.seriesOfShares?.find((el: any) => el.name === this.product.data.seriesOfShares.name)

        if (selectedSeries) {
          this.product.data.seriesOfShares.nominalValue = selectedSeries?.nominalValue
          this.product.data.seriesOfShares.issueValue = selectedSeries?.issueValue
        }
      }

      if (this.product.type === 'LLC_SHARES') {
        this.product.data.llcShares.nominalValue = subscription?.llcShares?.nominalValue
        this.product.data.llcShares.issueValue = subscription?.llcShares?.issueValue
      }
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
      this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising?page=subscriptions`);
    }
  }

  async beforeMount() {
    this.templatesList = await this.$store.dispatch('templates/getTemplateChoices', { type: 'template', context: this.activeUserData?.context?.id });
    if (this.activeUserData.context.context !== Contexts.privateInvestor &&
    this.activeUserData.context.context !== Contexts.fundManagerPrivate) {
      try {
        const walletAddress = await this.$store.dispatch('genprox/getContextMarketplaceWalletNumber');
        this.$store.commit('genprox/setActiveContextMarketplaceWallet', walletAddress);
      } catch (err) {
        console.log(err);
      }
    }

    this.product = this.$store.getters['investors/newProduct'];
    this.loadCorrectRadioButtons();

    if(((this.product.investmentClientId === '' || !this.product.investmentClientId) && (!this.product.investorLegalEntityId)) && !this.investorId) {
      this.$router.push({name: 'investors'});
    }

    if (!this.isMarketplaceAccountant && this.isDebtProduct) {
      this.loadCampaigns()
    }
    
    const issuer = this.activeUserData.context;
        const issuerData = await this.$store.dispatch('genprox/getLeContextData', issuer.id);
        this.issuer = issuerData;

        if (this.product.investmentClientId) {
          const investor: Address & {name: string} = await this.$store.dispatch('investors/getInvestmentClientAddress', this.product.investmentClientId)
          const { clientType, clientId } = await this.$store.dispatch('investors/getInvestorProfile', this.product.investmentClientId)
          
          this.investor = {name: investor.name, address: {
            zipCode: investor.zipCode,
            houseNumber: investor.houseNumber,
            street: investor.street,
            city: investor.city,
            country: investor.country,
            flatNumber: investor.flatNumber
          }}

          if (clientType === 'legal-entity') {
            const investorData: any = await this.$store.dispatch('genprox/getLeContextData', clientId)
            this.legalEntityInvestorData = investorData
          }
        }

        if (this.product.investorLegalEntityId) {
          const investorData: any = await this.$store.dispatch('genprox/getLeContextData', this.product.investorLegalEntityId)
          this.investor = {name: investorData.name, address: investorData.address}
          this.legalEntityInvestorData = investorData
        }


    if (this.product?.investmentClientId) {
      await this.$store.dispatch('profile/getProfileData');
      this.investorDetails = this.$store.getters['profile/getProfileData'];
    } else {
      if (!this.product?.investorLegalEntityId) {
        this.investorDetails = await this.$store.dispatch('investors/getInvestorProfile', this.product.investmentClientId); 
      }
    }

    if (this.isEquity) {
      this.loadSubscriptionData()
      this.seriesOfSharesList = await this.$store.dispatch('subscriptions/getSeriesOfShares')
    }

    this.setBankAccountsOwner(this.investor?.name)
  }

  async goNext(): Promise<void> {
    this.removeUnselectedProductData();
    this.fixedReplacementDay ? this.product[this.productDataName].timeType = TimeTypes.fixedRepaymentDate :
        this.product[this.productDataName].timeType = TimeTypes.duration;

    if(!this.checkValidation()) {
      await this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/new/summary`);
    }
  }

  checkValidation(): any {
    this.$v.$touch();
    return this.$v.$error;
  }


  goBack() {
    const parentLink = this.$route.path.includes('company') ? 'company' : 'fund';
    this.$router.push({path: `/${parentLink}/fundraising`});
  }

  removeUnselectedProductData(): void {
    if(this.fixedReplacementDay) {
      this.product[this.productDataName].duration = null;
    } else {
      delete this.product[this.productDataName].fixedRepaymentDate;
    }
    if(this.product[this.productDataName].enabledAdvancedOptions) {
      this.product[this.productDataName].simpleOptions = null;
    } else {
      this.product[this.productDataName].advancedOptions = null;
    }
  }

  @Watch('totalInvestmentValue', { immediate: true }) onTotalInvestmentValueChange() {
    if (this.isEquity) {
      this.product.investmentValue = this.totalInvestmentValue
    }
  }

  updateInstrumentCount() {
    // if (this.isEquity && this.product?.type === 'LLC_SHARES') {
    //   if (!this.product?.data?.llcShares?.instrumentCount && this.product?.investmentValue) {
    //     const objectKey = this.product?.type === 'JOINT_STOCK_SHARES' ? 'seriesOfShares' : 'llcShares'
    //     this.product.data[objectKey].instrumentCount = math.number(math.divide(math.bignumber(this.product?.investmentValue || 0), math.bignumber(this.product?.data[objectKey]?.issueValue)))
    //   }
    // }
  }

  updateInvestmentValue() {
    if (this.isEquity && this.product?.type === 'LLC_SHARES') {
      if (this.product?.data?.llcShares?.instrumentCount && !this.product?.investmentValue) {
        const objectKey = this.product?.type === 'JOINT_STOCK_SHARES' ? 'seriesOfShares' : 'llcShares'
        this.product.investmentValue = math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))) || 0
      }
    }
  }

  updateIssueValue() {
    if (this.product?.data?.llcShares?.instrumentCount && this.product?.investmentValue) {
      const issueValue = math.number(math.divide(math.bignumber(this.product?.investmentValue || 0), math.bignumber(this.product?.data?.llcShares?.instrumentCount || 0)))
      this.product.data.llcShares.issueValue = issueValue || 0
    } else {
      this.product.data.llcShares.issueValue = this.subscription?.llcShares?.issueValue
    }
  }

  @Watch('product.data.seriesOfShares.instrumentCount') onSeriesInstrumentCountChange() {
    const instrumentCount = this.product?.data?.seriesOfShares?.instrumentCount
    const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
    if (counterFrom && instrumentCount) {
      this.product.data.seriesOfShares.counterTo = counterFrom + instrumentCount - 1
    } else {
      this.product.data.seriesOfShares.counterTo = null
    }
  }

  @Watch('product.data.seriesOfShares.counterFrom') onSeriesCounterFromChange() {
    const serieItems = this.seriesOfShares.filter((el: any) => el.name === this.product?.data?.seriesOfShares?.name)

    let series = this.seriesOfShares.find((el: any) => el.name === this.product?.data?.seriesOfShares?.name)

    if (this.product?.data?.seriesOfShares?.counterFrom) {
      const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
      const foundSeries = serieItems?.find((el: any) => counterFrom >= el.counterFrom && counterFrom <= el.counterTo)

      if (foundSeries) {
        series = foundSeries
      }
    }

    if (series) {
      this.product.data.seriesOfShares.nominalValue = series?.nominalValue
      this.product.data.seriesOfShares.issueValue = series?.issueValue
    }

    const instrumentCount = this.product?.data?.seriesOfShares?.instrumentCount
    const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
    if (counterFrom && instrumentCount) {
      this.product.data.seriesOfShares.counterTo = counterFrom + instrumentCount - 1
    } else {
      this.product.data.seriesOfShares.counterTo = null
    }
  }

  @Watch('product.data.seriesOfShares.name') onSeriesOfSharesNameChange() {
    const serieItems = this.seriesOfShares.filter((el: any) => el.name === this.product?.data?.seriesOfShares?.name)

    let series = this.seriesOfShares.find((el: any) => el.name === this.product?.data?.seriesOfShares?.name)

    if (this.product?.data?.seriesOfShares?.counterFrom) {
      const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
      const foundSeries = serieItems?.find((el: any) => counterFrom >= el.counterFrom && counterFrom <= el.counterTo)

      if (foundSeries) {
        series = foundSeries
      }
    }

    if (series) {
      this.product.data.seriesOfShares.nominalValue = series?.nominalValue
      this.product.data.seriesOfShares.issueValue = series?.issueValue
    } else {
      this.product.data.seriesOfShares.nominalValue = null
      this.product.data.seriesOfShares.issueValue = null
    }
  }

  validations () {
    let validation: any = {
      product: {
        agreementDate: {required},
        // investmentClientId: {required},
        investmentClientId: {
          required: requiredIf(() => {
            return !this.product.investorLegalEntityId
          })
        },
        investorLegalEntityId: {
          required: requiredIf(() => {
            return !this.product.investmentClientId
          })
        },
        // code: {required},
        // name: {required},
        investmentValue: {required, minValue: minValue(1)},
        currency: {required},
        type: required,
        agreementTemplateId: {},
      }
    }

    if (!this.isEquity) {
      validation.product.agreementTemplateId = { required }
      validation.product[this.productDataName] = {
        timeType: { required },
        interestType: { required },
        interestPaidWithin: { required, integer },
        interestCalculationMethod: { required },
        // debtor: { required }
      }

      if (this.product[this.productDataName].initialPaymentBankAccount?.account) {
        if (this.ibanSupportedCountries.includes(this.product[this.productDataName].initialPaymentBankAccount.country)) {
          validation.product[this.productDataName].initialPaymentBankAccount = {
            account: { iban: iban(this.product[this.productDataName].initialPaymentBankAccount.country) },
          };
        } else {
          validation.product[this.productDataName].initialPaymentBankAccount = {
            account: { required },
          };
        }

        if (!this.product[this.productDataName].initialPaymentBankAccount.account?.toLowerCase().slice(0, 2).includes('pl')) {
          validation.product[this.productDataName].initialPaymentBankAccount.swift = { required };
        }
      }

      if (this.product[this.productDataName].principalPaymentBankAccount?.account) {
        if (this.ibanSupportedCountries.includes(this.product[this.productDataName].principalPaymentBankAccount.country)) {
          validation.product[this.productDataName].principalPaymentBankAccount = {
            account: { iban: iban(this.product[this.productDataName].principalPaymentBankAccount.country) },
          };
        } else {
          validation.product[this.productDataName].principalPaymentBankAccount = {
            account: { required },
          };
        }

        if (!this.product[this.productDataName].principalPaymentBankAccount.account?.toLowerCase().slice(0, 2).includes('pl')) {
          validation.product[this.productDataName].principalPaymentBankAccount.swift = { required };
        }
      }

      if (this.product[this.productDataName].interestPaymentBankAccount?.account) {
        if (this.product[this.productDataName].interestPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].interestPaymentBankAccount.country)) {
          validation.product[this.productDataName].interestPaymentBankAccount = {
            account: { iban: iban(this.product[this.productDataName].interestPaymentBankAccount.country) },
          };
        } else {
          validation.product[this.productDataName].interestPaymentBankAccount = {
            account: { required },
          };
        }

        if (!this.product[this.productDataName].interestPaymentBankAccount.account?.toLowerCase().slice(0, 2).includes('pl')) {
          validation.product[this.productDataName].interestPaymentBankAccount.swift = { required };
        }
      }

      if (this.fixedReplacementDay) {
        validation.product[this.productDataName].fixedRepaymentDate = { required };
      } else {
        validation.product[this.productDataName].duration = {
          type: { required },
          count: { required, integer, minValue: minValue(1) }
        }
      }
      if (this.product[this.productDataName].enabledAdvancedOptions) {
        validation.product[this.productDataName].advancedOptions = {
          commission: { required, greaterThan: greaterThan(0) },
          margin: { required, greaterThan: greaterThan(0) },
          marginPayment: { required },
          marginType: { required },
          commissionType: { required },
          commissionPayment: { required },
        }
      } else {
        validation.product[this.productDataName].simpleOptions = {
          interest: { required, greaterThan: greaterThan(0) },
          interestPayment: { required },
        }
      }
    } else {
      if (this.product.type === 'JOINT_STOCK_SHARES') {
        const inRangeValidators: any[] = this.currentAvailableSubscriptionSlots?.map((el: any) => {
          return between(el?.counterFrom, el?.counterTo)
        })

        validation.product.data = {
          seriesOfShares: {
            name: { required },
            instrumentCount: { required, minValue: minValue(1) },
            counterFrom: { required, inRange: or(...inRangeValidators) },
            counterTo: { required },
            nominalValue: { required },
            issueValue: { required },
          }
        }

        const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
        if (counterFrom) {
          const activeSlotRange: any = this.currentAvailableSubscriptionSlots?.find((el: any) => counterFrom >= el?.counterFrom && counterFrom <= el?.counterTo)

          if (activeSlotRange) {
            validation.product.data.seriesOfShares.instrumentCount.maxValue = maxValue(activeSlotRange?.instrumentCount)
          }
        }

      } else if (this.product.type === 'LLC_SHARES') {
        validation.product.data = {
          llcShares: {
            instrumentCount: { required, minValue: minValue(1) },
            nominalValue: { required },
            issueValue: { required },
          }
        }
      }
    }

    return validation;
  }
}
