































































































































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop, Watch,  } from 'vue-property-decorator';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInputGroup from '@/components/inputs/SygniInputGroup.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import { SingleCaseItem } from '../store/types';
import { AxiosRequestConfig } from 'axios';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';

@Component({
  components: { SygniLinkButton, SygniRoundedButton, SygniFileBox, SygniRectButton, SygniInputGroup, SygniSelect, SygniLoader, GenproxModal },
})
export default class CaseModal extends Vue {
  @Prop() isLoading!: boolean;
  @Prop({ default: '' }) caseId: string;
  showStatusChangeModal: boolean = false;
  areCommentsLoading: boolean = false;
  isStatusLoading: boolean = false;
  filesToUpload: Array<File> = [];
  commentMessage: string = '';
  newCaseStatus: string = '';
  caseData: SingleCaseItem = {
    caseToken: '',
    category: '',
    comments: [],
    description: '',
    documents: [],
    email: null,
    name: null,
    phone: null,
    status: '',
    surname: null,
    title: ''
  };
  uploadProgress: UploadProgress = { progress: 0 };
  areFilesLoading: boolean = false;

  get isEditable() {
    return !(this.caseData.status == 'closed' || this.caseData.status == 'spam');
  }

  get caseStatusOptions() {
    return this.$store.getters['whistleblower/getStatuses'];
  }

  get unreadComments() {
    return this.caseData?.comments.filter((el: any) => {
      return el.beenRead != true;
    });
  }

  async markCommentsAsRead() {
    this.$emit('caseLoading');
    await this.$store.dispatch('whistleblower/markCommentsAsRead', this.caseId)
    this.$notify({
      type: 'success',
      title: 'Success',
      text: 'All comments are marked as read.'
    });
    await this.loadCaseData();
  }

  openStatusChangeModal() {
    this.newCaseStatus = this.caseData.status;
    this.showStatusChangeModal = true;
  }

  closeStatusChangeModal() {
    this.showStatusChangeModal = false;
  }

  getCaseStatusClassName(status: string) {
    let className = 'danger';
    switch(status) {
      case('new'):
        className = 'danger';
        break;
      case('investigated'):
        className = 'primary';
        break;
      case('awaiting'):
        className = 'success';
        break;
      case('closed'):
        className = 'default';
        break;
      case('spam'):
        className = 'default';
        break;
      default:
        className = 'danger';
    }

    return className;
  }

  deleteFile(file: File) {
    const index = this.filesToUpload.findIndex(el => el == file);
    this.filesToUpload.splice(index, 1);
  }

  toggleFileUpload() {
    (this.$refs.fileInput as HTMLInputElement).click()
  }

  downloadFile(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  prevFn() {
    this.$emit('prevCase');
  }

  nextFn() {
    this.$emit('nextCase');
  }

  clearCaseData() {
    this.caseData = {
      caseToken: '',
      category: '',
      comments: [],
      description: '',
      documents: [],
      email: null,
      name: null,
      phone: null,
      status: '',
      surname: null,
      title: ''
    };
  }

  async changeStatus() {
    this.isStatusLoading = true;
    try {
      await this.$store.dispatch('whistleblower/setCaseStatus', {
        id: this.caseId,
        status: this.newCaseStatus,
      })
      this.isStatusLoading = false;
      this.closeStatusChangeModal();
      this.$emit('caseLoading');
      this.$emit('statusChanged');
      await this.loadCaseData();
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Status has been updated.'
      });
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isStatusLoading = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async uploadFiles(e: Event) {
    const files = Array.from((e.target as HTMLInputElement).files);
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      this.areFilesLoading = true;
      const promises: Array<Promise<any>> = [];
      files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadFile', {file, config}));
      })

      const uploadedFiles = await Promise.all(promises);
      this.areFilesLoading = false;
      uploadedFiles.forEach((file: File) => {
        this.filesToUpload.push(file);
      });
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Files have been successfully uploaded'
      });
    } catch (e) {
      e;
    }
    this.uploadProgress.progress = -1;
  }

  async saveFiles() {
    this.$emit('caseLoading');
    try {
      const documentIds = this.filesToUpload.map((el: any) => {
        return el.id;
      })
      await this.$store.dispatch('whistleblower/uploadCaseDocument', {
        id: this.caseId, 
        documentIds: documentIds
      });
      this.filesToUpload = [];
      await this.loadCaseData();
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Files have been saved.'
      });
    } catch(e) {
      this.$emit('caseLoaded');
      this.$notify({
        type: 'error',
        title: 'Error',
        text: 'Files cannot be saved. Please try again later.'
      });
    }
  }

  async createComment() {
    this.areCommentsLoading = true;
    if(this.caseId) {
      try {
        await this.$store.dispatch('whistleblower/postComment', {
          id: this.caseId,
          content: this.commentMessage
        })
        this.$notify({
          type: 'success',
          title: 'Message sent.'
        });
        this.areCommentsLoading = false;
        this.commentMessage = '';
        this.$emit('caseLoading');
        await this.loadCaseData();
      } catch(err) {
        this.areCommentsLoading = false;
        const errorMessage = this.$options.filters.errorHandler(err);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }
    } else {
      this.$notify({
        type: 'error',
        title: 'Cannot send message.'
      })
    }
  }

  async loadCaseData() {
    if(this.caseId) {
      const caseData  = await this.$store.dispatch('whistleblower/getCase', this.caseId);
      this.$nextTick(() => {
        this.caseData = caseData;
        this.$emit('caseLoaded');
      });
    } else {
      this.clearCaseData();
    }
  }

  @Watch('caseId') async onCaseIdChange(): Promise<void> {
    this.loadCaseData();
    const appContentDiv: HTMLDivElement = this.$refs['caseModal'] as HTMLDivElement | undefined;
    appContentDiv?.querySelector('.case-modal__inner').scrollTo({top: 0});
  }
}
