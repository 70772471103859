








import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import AuthModuleBoilerplate from './AuthModuleBoilerplate.vue';
@Component({
  components: { AuthModuleBoilerplate }
})
export default class TextContainer extends Vue {
  @Prop() innerHTML!: string;
}
