var render = function () {
  var _vm$matchingResults, _vm$validationErrorMo;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-transactions-table"
  }, [_c('sygni-container-title', [_vm._v("Bank transactions")]), _c('div', {
    staticClass: "statement-transactions-table__box"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedRowsLength > 0,
      expression: "selectedRowsLength > 0"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedRowsLength))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline",
    on: {
      "click": function click($event) {
        return _vm.resetCheckboxes(false);
      }
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    staticClass: "dropdown"
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('div', {
    staticClass: "dropdown__inner"
  }, [_vm.productObject !== null && _vm.hasOnlyReconcilableDocuments ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": _vm.handleOpenModalAction
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Reconcile documents")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE 2'),
      "alt": "Reconcile documents"
    }
  })])]) : _vm._e(), _vm.hasOnlyBookableTransactions ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.synchronise();
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Book transactions")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('UPLOAD'),
      "alt": "Book transactions"
    }
  })])]) : _vm._e(), !_vm.hasOnlyBookableTransactions && !_vm.hasOnlyReconcilableDocuments ? _c('div', {
    staticClass: "dropdown__option"
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("No actions available")])]) : _vm._e()])])], 1)])], 1), _c('simplebar', {
    ref: "tableInner",
    staticClass: "statement-transactions-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "statementTransactionsTable",
    staticClass: "sygni-b-inner-table sygni-b-table",
    attrs: {
      "innertable": "true",
      "tbody-tr-class": _vm.rowClass,
      "fields": _vm.tableFields,
      "show-empty": true
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(selected)",
      fn: function fn(rowData) {
        var _vm$bulkRows$rowData$, _vm$bulkRows$rowData$2;

        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData.index], rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "disabled": _vm.isDisabled((_vm$bulkRows$rowData$ = _vm.bulkRows[rowData === null || rowData === void 0 ? void 0 : rowData.index]) === null || _vm$bulkRows$rowData$ === void 0 ? void 0 : _vm$bulkRows$rowData$.label),
            "value": (_vm$bulkRows$rowData$2 = _vm.bulkRows[rowData.index]) === null || _vm$bulkRows$rowData$2 === void 0 ? void 0 : _vm$bulkRows$rowData$2.value
          }
        })], 1)];
      }
    }, {
      key: "cell(transferNumber)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item2;

        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage((_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.transferNumber, 20),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item?.transferNumber, 20), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(_vm._s(_vm._f("trimString")((_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.transferNumber, 20)))])];
      }
    }, {
      key: "cell(code)",
      fn: function fn(rowData) {
        var _rowData$item3, _rowData$item4, _rowData$item5, _rowData$item6;

        return [rowData !== null && rowData !== void 0 && (_rowData$item3 = rowData.item) !== null && _rowData$item3 !== void 0 && _rowData$item3.matchedObjectId && rowData !== null && rowData !== void 0 && (_rowData$item4 = rowData.item) !== null && _rowData$item4 !== void 0 && _rowData$item4.code ? _c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage(rowData.item.code, 20),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item.code, 20), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left hoverable-cell",
          attrs: {
            "target": "_blank",
            "href": _vm.getProductSummaryLink(rowData === null || rowData === void 0 ? void 0 : (_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.matchedObjectId)
          }
        }, [_vm._v(_vm._s(_vm._f("trimString")((_rowData$item6 = rowData.item) !== null && _rowData$item6 !== void 0 && _rowData$item6.code ? rowData.item.code : '', 20)))]) : _vm._e()];
      }
    }, {
      key: "cell(transferDate)",
      fn: function fn(rowData) {
        var _rowData$item7;

        return [_c('div', {
          staticClass: "source-name text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")((_rowData$item7 = rowData.item) === null || _rowData$item7 === void 0 ? void 0 : _rowData$item7.transferDate)) + " ")])];
      }
    }, {
      key: "cell(counterpartyName)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage(rowData.item.counterpartyName, 20),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item.counterpartyName, 20), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.counterpartyName, 20)) + " ")])];
      }
    }, {
      key: "cell(counterpartyAccountNumber)",
      fn: function fn(rowData) {
        var _rowData$item8, _rowData$item9;

        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage(_vm.$options.filters.bankAccountNumber((_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : _rowData$item8.counterpartyAccountNumber), 30),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage($options.filters.bankAccountNumber(rowData.item?.counterpartyAccountNumber), 30), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(_vm._f("bankAccountNumber")((_rowData$item9 = rowData.item) === null || _rowData$item9 === void 0 ? void 0 : _rowData$item9.counterpartyAccountNumber), 30)) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(rowData) {
        var _rowData$item10;

        return [_c('div', {
          staticClass: "text-nowrap text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item10 = rowData.item) === null || _rowData$item10 === void 0 ? void 0 : _rowData$item10.amount)) + " ")])];
      }
    }, {
      key: "cell(currency)",
      fn: function fn(rowData) {
        var _rowData$item11, _rowData$item11$curre;

        return [_c('div', {
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s((_rowData$item11 = rowData.item) === null || _rowData$item11 === void 0 ? void 0 : (_rowData$item11$curre = _rowData$item11.currency) === null || _rowData$item11$curre === void 0 ? void 0 : _rowData$item11$curre.toUpperCase()) + " ")])];
      }
    }, {
      key: "cell(description)",
      fn: function fn(rowData) {
        var _rowData$item12, _rowData$item13;

        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.getTooltipMessage((_rowData$item12 = rowData.item) === null || _rowData$item12 === void 0 ? void 0 : _rowData$item12.description, 20),
              boundary: 'viewport'
            },
            expression: "{ title: getTooltipMessage(rowData.item?.description, 20), boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap text-left"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")((_rowData$item13 = rowData.item) === null || _rowData$item13 === void 0 ? void 0 : _rowData$item13.description, 20)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        var _rowData$item14, _rowData$item15, _rowData$item15$statu, _rowData$item16, _rowData$item16$statu;

        return [_c('div', {
          staticClass: "actions"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              interactive: false,
              boundary: 'viewport'
            },
            expression: "{ interactive: false, boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          class: ['action', !(rowData !== null && rowData !== void 0 && (_rowData$item14 = rowData.item) !== null && _rowData$item14 !== void 0 && _rowData$item14.investmentClientId) || (rowData === null || rowData === void 0 ? void 0 : (_rowData$item15 = rowData.item) === null || _rowData$item15 === void 0 ? void 0 : (_rowData$item15$statu = _rowData$item15.status) === null || _rowData$item15$statu === void 0 ? void 0 : _rowData$item15$statu.toUpperCase()) === 'BOOK' || (rowData === null || rowData === void 0 ? void 0 : (_rowData$item16 = rowData.item) === null || _rowData$item16 === void 0 ? void 0 : (_rowData$item16$statu = _rowData$item16.status) === null || _rowData$item16$statu === void 0 ? void 0 : _rowData$item16$statu.toUpperCase()) === 'BOOKED' ? 'disabled' : ''],
          attrs: {
            "title": "Book transaction"
          },
          on: {
            "click": function click($event) {
              return _vm.synchronise(rowData === null || rowData === void 0 ? void 0 : rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('UPLOAD'),
            "alt": "Book"
          }
        })])])])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        var _rowData$item17, _rowData$item18, _rowData$item19, _rowData$item20, _rowData$item21;

        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData !== null && rowData !== void 0 && (_rowData$item17 = rowData.item) !== null && _rowData$item17 !== void 0 && _rowData$item17.errorMessage ? rowData === null || rowData === void 0 ? void 0 : (_rowData$item18 = rowData.item) === null || _rowData$item18 === void 0 ? void 0 : _rowData$item18.errorMessage : '',
            expression: "rowData?.item?.errorMessage ? rowData?.item?.errorMessage : ''",
            modifiers: {
              "hover": true
            }
          }],
          class: "outline round ".concat(_vm.statusClass((_rowData$item19 = rowData.item) === null || _rowData$item19 === void 0 ? void 0 : _rowData$item19.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText((_rowData$item20 = rowData.item) === null || _rowData$item20 === void 0 ? void 0 : _rowData$item20.status)) + " "), rowData !== null && rowData !== void 0 && (_rowData$item21 = rowData.item) !== null && _rowData$item21 !== void 0 && _rowData$item21.errorMessage ? _c('div', {
          staticClass: "warning-info"
        }, [_vm._v("i")]) : _vm._e()])], 1)];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        var _rowData$item22, _rowData$item22$value, _rowData$item23, _rowData$item23$value, _rowData$item24, _rowData$item24$value;

        return [(_rowData$item22 = rowData.item) !== null && _rowData$item22 !== void 0 && (_rowData$item22$value = _rowData$item22.value) !== null && _rowData$item22$value !== void 0 && _rowData$item22$value.value ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item23 = rowData.item) === null || _rowData$item23 === void 0 ? void 0 : (_rowData$item23$value = _rowData$item23.value) === null || _rowData$item23$value === void 0 ? void 0 : _rowData$item23$value.value, 2)) + " " + _vm._s((_rowData$item24 = rowData.item) === null || _rowData$item24 === void 0 ? void 0 : (_rowData$item24$value = _rowData$item24.value) === null || _rowData$item24$value === void 0 ? void 0 : _rowData$item24$value.currency) + " ")]) : _vm._e()];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)], 1), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCombineModal,
      expression: "showCombineModal"
    }],
    staticClass: "comparison-modal",
    attrs: {
      "size": "wide",
      "header": "",
      "cancelText": "",
      "confirmText": "",
      "showCloseBtn": true,
      "isLoading": _vm.isMatchingModalLoading
    },
    on: {
      "close": _vm.closeCombineModal,
      "confirm": _vm.closeCombineModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$productObject, _vm$productObject2, _vm$productObject2$da, _vm$productObject3, _vm$productObject3$da, _vm$productObject4, _vm$productObject4$da, _vm$productObject5, _vm$productObject5$da, _vm$productObject6, _vm$productObject6$da, _vm$productObject7, _vm$productObject7$da, _vm$productObject8, _vm$productObject8$da;

        return [_c('div', {
          staticClass: "comparison"
        }, [_c('sygni-container-title', [_vm._v("Comparison")]), _c('simplebar', {
          staticClass: "comparison__inner"
        }, [_c('div', {
          staticClass: "comparison__container"
        }, [(_vm$productObject = _vm.productObject) !== null && _vm$productObject !== void 0 && _vm$productObject.data ? _c('div', {
          staticClass: "comparison__column small"
        }, [_c('p', {
          staticClass: "title"
        }, [_vm._v("Product")]), _c('div', {
          staticClass: "comparison__item"
        }, [_c('sygni-line', {
          staticClass: "grey comparison__divider"
        }), _c('div', {
          staticClass: "comparison__group"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("TYPE")]), _c('p', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.getTypeText((_vm$productObject2 = _vm.productObject) === null || _vm$productObject2 === void 0 ? void 0 : (_vm$productObject2$da = _vm$productObject2.data) === null || _vm$productObject2$da === void 0 ? void 0 : _vm$productObject2$da.type) || ''))])]), _c('div', {
          staticClass: "comparison__group"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("INVESTOR NAME")]), _c('p', {
          staticClass: "title"
        }, [_vm._v(_vm._s(((_vm$productObject3 = _vm.productObject) === null || _vm$productObject3 === void 0 ? void 0 : (_vm$productObject3$da = _vm$productObject3.data) === null || _vm$productObject3$da === void 0 ? void 0 : _vm$productObject3$da.investorName) || ''))])]), _c('div', {
          staticClass: "comparison__group"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("CODE")]), _c('p', {
          staticClass: "title"
        }, [_vm._v(_vm._s((_vm$productObject4 = _vm.productObject) === null || _vm$productObject4 === void 0 ? void 0 : (_vm$productObject4$da = _vm$productObject4.data) === null || _vm$productObject4$da === void 0 ? void 0 : _vm$productObject4$da.code))])]), _c('div', {
          staticClass: "comparison__group"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("DATE")]), _c('p', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")((_vm$productObject5 = _vm.productObject) === null || _vm$productObject5 === void 0 ? void 0 : (_vm$productObject5$da = _vm$productObject5.data) === null || _vm$productObject5$da === void 0 ? void 0 : _vm$productObject5$da.date)))])]), _c('div', {
          staticClass: "comparison__group"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("ACCOUNT NUMBER:")]), _c('p', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.getAccountNumber((_vm$productObject6 = _vm.productObject) === null || _vm$productObject6 === void 0 ? void 0 : (_vm$productObject6$da = _vm$productObject6.data) === null || _vm$productObject6$da === void 0 ? void 0 : _vm$productObject6$da.accountNumber)))])]), _c('div', {
          staticClass: "comparison__group"
        }, [_c('p', {
          staticClass: "label"
        }, [_vm._v("VALUE:")]), _c('p', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")((_vm$productObject7 = _vm.productObject) === null || _vm$productObject7 === void 0 ? void 0 : (_vm$productObject7$da = _vm$productObject7.data) === null || _vm$productObject7$da === void 0 ? void 0 : _vm$productObject7$da.value, 2)) + " " + _vm._s((_vm$productObject8 = _vm.productObject) === null || _vm$productObject8 === void 0 ? void 0 : (_vm$productObject8$da = _vm$productObject8.data) === null || _vm$productObject8$da === void 0 ? void 0 : _vm$productObject8$da.currency))])])], 1)]) : _vm._e(), _c('div', {
          staticClass: "comparison__column"
        }, [_c('p', {
          staticClass: "title"
        }, [_vm._v("Transactions")]), _vm._l(_vm.selectedItems, function (transaction, index) {
          var _vm$productObject9, _vm$productObject10, _vm$productObject10$d;

          return _c('div', {
            key: transaction === null || transaction === void 0 ? void 0 : transaction.id,
            staticClass: "comparison__item"
          }, [_c('sygni-line', {
            staticClass: "grey comparison__divider"
          }), (_vm$productObject9 = _vm.productObject) !== null && _vm$productObject9 !== void 0 && _vm$productObject9.data && index === 0 ? _c('div', {
            staticClass: "comparison__group hide"
          }, [_c('p', {
            staticClass: "label"
          }, [_vm._v("TYPE")]), _c('p', {
            staticClass: "title"
          }, [_vm._v(_vm._s(_vm.getTypeText((_vm$productObject10 = _vm.productObject) === null || _vm$productObject10 === void 0 ? void 0 : (_vm$productObject10$d = _vm$productObject10.data) === null || _vm$productObject10$d === void 0 ? void 0 : _vm$productObject10$d.type) || ''))])]) : _vm._e(), _c('div', {
            staticClass: "comparison__group"
          }, [_c('p', {
            staticClass: "label"
          }, [_vm._v("COUNTERPARTY NAME:")]), _c('p', {
            staticClass: "title"
          }, [_vm._v(_vm._s(transaction === null || transaction === void 0 ? void 0 : transaction.counterpartyName))])]), _c('div', {
            staticClass: "comparison__group"
          }, [_c('p', {
            staticClass: "label"
          }, [_vm._v("TRANSFER NUMBER:")]), _c('p', {
            staticClass: "title"
          }, [_vm._v(_vm._s(transaction === null || transaction === void 0 ? void 0 : transaction.transferNumber))])]), _c('div', {
            staticClass: "comparison__group"
          }, [_c('p', {
            staticClass: "label"
          }, [_vm._v("TRANSFER DATE:")]), _c('p', {
            staticClass: "title"
          }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(transaction === null || transaction === void 0 ? void 0 : transaction.transferDate)))])]), _c('div', {
            staticClass: "comparison__group"
          }, [_c('p', {
            staticClass: "label"
          }, [_vm._v("ACCOUNT NUMBER:")]), _c('p', {
            staticClass: "title"
          }, [_vm._v(_vm._s(_vm.getAccountNumber(transaction === null || transaction === void 0 ? void 0 : transaction.accountNumber)))])]), _c('div', {
            staticClass: "comparison__group"
          }, [_c('p', {
            staticClass: "label"
          }, [_vm._v("AMOUNT:")]), _c('p', {
            staticClass: "title"
          }, [_vm._v(_vm._s((transaction === null || transaction === void 0 ? void 0 : transaction.direction) === -1 ? '-' : '') + _vm._s(_vm._f("numberFormat")(transaction === null || transaction === void 0 ? void 0 : transaction.amount, 2)) + " " + _vm._s(transaction === null || transaction === void 0 ? void 0 : transaction.currency))])])], 1);
        }), _c('div', {
          staticClass: "comparison__actions"
        }, [_c('p', {
          staticClass: "description mb-3"
        }, [_vm._v("Please check that every requested information are correct. any special information can be included here")]), _c('sygni-rect-button', {
          staticClass: "filled gn-secondary mb-4",
          attrs: {
            "arrowIcon": true
          },
          on: {
            "click": _vm.handleCombineModalAction
          }
        }, [_vm._v("Reconcile those documents")]), _c('div', {
          staticClass: "text-center"
        }, [_c('sygni-link-button', {
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.closeCombineModal
          }
        }, [_vm._v("Cancel")])], 1)], 1)], 2)])])], 1)];
      },
      proxy: true
    }])
  }), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$matchingResults = _vm.matchingResults) === null || _vm$matchingResults === void 0 ? void 0 : _vm$matchingResults.length,
      expression: "matchingResults?.length"
    }],
    staticClass: "matching-confirmation-modal",
    attrs: {
      "header": "The records have been processed",
      "size": "large",
      "description": _vm.matchingDescription,
      "confirmText": "OK",
      "cancelText": ""
    },
    on: {
      "close": _vm.clearMatchingResults,
      "confirm": _vm.clearMatchingResults
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table",
          attrs: {
            "innertable": "true",
            "fields": [{
              key: 'transferNumber',
              sortable: false,
              class: ['text-left transfer-number']
            }, {
              key: 'status',
              sortable: false,
              class: ['status']
            }],
            "items": _vm.matchingResults
          },
          scopedSlots: _vm._u([{
            key: "cell(transferNumber)",
            fn: function fn(rowData) {
              var _rowData$item25;

              return [_c('div', {
                staticClass: "text-left"
              }, [_vm._v(_vm._s(rowData === null || rowData === void 0 ? void 0 : (_rowData$item25 = rowData.item) === null || _rowData$item25 === void 0 ? void 0 : _rowData$item25.transferNumber))])];
            }
          }, {
            key: "cell(status)",
            fn: function fn(rowData) {
              var _rowData$item26, _rowData$item27;

              return [_c('div', {
                staticClass: "table__status d-flex justify-content-center align-items-center"
              }, [_c('sygni-rounded-button', {
                class: ['outline round', ((_rowData$item26 = rowData.item) === null || _rowData$item26 === void 0 ? void 0 : _rowData$item26.status) === 'Matched' ? 'primary' : 'danger'],
                attrs: {
                  "hoverable": false
                }
              }, [_vm._v(_vm._s(_vm.statusText((_rowData$item27 = rowData.item) === null || _rowData$item27 === void 0 ? void 0 : _rowData$item27.status)))])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('booking-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBookingModal,
      expression: "showBookingModal"
    }],
    ref: "bookingModal",
    attrs: {
      "selectedItems": _vm.bookingItems
    },
    on: {
      "cancel": _vm.closeBookingModal,
      "close": _vm.closeBookingModal
    }
  }), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMatchingConfirmationModal,
      expression: "showMatchingConfirmationModal"
    }],
    attrs: {
      "header": "Are you sure?",
      "description": "Some of the transactions were already matched and will be unmatched. Do you want to continue?",
      "cancelText": "No",
      "confirmText": "Yes"
    },
    on: {
      "close": _vm.closeMatchingConfirmationModal,
      "cancel": _vm.closeMatchingConfirmationModal,
      "confirm": _vm.combineModalAction
    }
  }), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$validationErrorMo = _vm.validationErrorModal) === null || _vm$validationErrorMo === void 0 ? void 0 : _vm$validationErrorMo.show,
      expression: "validationErrorModal?.show"
    }],
    attrs: {
      "header": "Validation error",
      "cancelText": "",
      "confirmText": "OK"
    },
    on: {
      "close": _vm.closeValidationErrorModal,
      "cancel": _vm.closeValidationErrorModal,
      "confirm": _vm.closeValidationErrorModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$validationErrorMo2;

        return [_c('p', {
          staticClass: "gn-modal__desc",
          domProps: {
            "innerHTML": _vm._s(((_vm$validationErrorMo2 = _vm.validationErrorModal) === null || _vm$validationErrorMo2 === void 0 ? void 0 : _vm$validationErrorMo2.message) || '')
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }