var render = function () {
  var _vm$package, _vm$package2, _vm$package3, _vm$package3$price, _vm$package4, _vm$package4$price;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-package"
  }, [_c('GenproxBreadcrumbs', {
    attrs: {
      "title": (_vm$package = _vm.package) === null || _vm$package === void 0 ? void 0 : _vm$package.name
    }
  }), _c('GenproxPageHeading', {
    attrs: {
      "title": "Agreement",
      "description": !((_vm$package2 = _vm.package) !== null && _vm$package2 !== void 0 && _vm$package2.active) ? 'Please read and accept our Agreement to proceed with your selected plan' : ''
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('sygni-rect-button', {
          staticClass: "outline gn-primary",
          attrs: {
            "arrowIconLeft": true
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Back")])];
      },
      proxy: true
    }])
  }), !_vm.regulation ? [_c('sygni-loader', {
    attrs: {
      "isLoading": true
    }
  })] : [_c('div', {
    staticClass: "accept-package__inner"
  }, [_c('div', {
    staticClass: "accept-package__body"
  }, [_c('div', {
    staticClass: "accept-package__content",
    domProps: {
      "innerHTML": _vm._s(_vm.regulation)
    }
  }), !_vm.package.active ? _c('div', {
    staticClass: "accept-package__additionals"
  }, [!_vm.package.active ? _c('div', {
    staticClass: "accept-package__checkbox"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary normalize",
    model: {
      value: _vm.areRegulationsAccepted,
      callback: function callback($$v) {
        _vm.areRegulationsAccepted = $$v;
      },
      expression: "areRegulationsAccepted"
    }
  }, [_vm._v(" I confirm that I have read the contract and accept its terms and conditions and that I have the capacity to represent the legal entity or have been authorised by the management to enter into a legally binding contract. ")])], 1) : _vm._e()]) : _vm._e()])])], _c('GenproxFormBar', {
    attrs: {
      "header": "".concat(((_vm$package3 = _vm.package) === null || _vm$package3 === void 0 ? void 0 : (_vm$package3$price = _vm$package3.price) === null || _vm$package3$price === void 0 ? void 0 : _vm$package3$price.value) | _vm.thousandSeparator, " ").concat((_vm$package4 = _vm.package) === null || _vm$package4 === void 0 ? void 0 : (_vm$package4$price = _vm$package4.price) === null || _vm$package4$price === void 0 ? void 0 : _vm$package4$price.currency),
      "description": _vm.paymentPlan
    },
    scopedSlots: _vm._u([{
      key: "actionsRight",
      fn: function fn() {
        var _vm$package5, _vm$package6, _vm$package7, _vm$package8, _vm$package9;

        return [_vm.packageId ? _c('sygni-rounded-button', {
          staticClass: "outline gn-secondary",
          on: {
            "click": _vm.generatePdf
          }
        }, [_vm._v("Download PDF")]) : _vm._e(), (_vm$package5 = _vm.package) !== null && _vm$package5 !== void 0 && _vm$package5.active && ((_vm$package6 = _vm.package) === null || _vm$package6 === void 0 ? void 0 : _vm$package6.name) === 'Wezwanie KNF ws. ESG w ASI' ? _c('sygni-rounded-button', {
          staticClass: "filled gn-primary",
          on: {
            "click": _vm.goToInsight
          }
        }, [_vm._v("Go to product")]) : _vm._e(), ((_vm$package7 = _vm.package) === null || _vm$package7 === void 0 ? void 0 : _vm$package7.name) === 'Regulatory filing (KNF Reporting)' ? _c('sygni-rounded-button', {
          staticClass: "filled gn-primary",
          attrs: {
            "disabled": !((_vm$package8 = _vm.package) !== null && _vm$package8 !== void 0 && _vm$package8.active)
          },
          on: {
            "click": _vm.goToRegReporting
          }
        }, [_vm._v("Go to Reg Reporting")]) : _vm._e(), !((_vm$package9 = _vm.package) !== null && _vm$package9 !== void 0 && _vm$package9.active) ? _c('sygni-rounded-button', {
          staticClass: "filled gn-primary gn-form-bar__btn",
          attrs: {
            "disabled": !_vm.areRegulationsAccepted
          },
          on: {
            "click": function click($event) {
              return _vm.accept();
            }
          }
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "fill": "none",
            "viewBox": "0 0 24 24",
            "stroke-width": "1.5",
            "stroke": "currentColor"
          }
        }, [_c('path', {
          attrs: {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "d": "M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          }
        })]), _vm._v(" Accept ")]) : _c('sygni-rounded-button', {
          staticClass: "outline primary gn-form-bar__btn",
          attrs: {
            "hoverable": false
          }
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "fill": "none",
            "viewBox": "0 0 24 24",
            "stroke-width": "1.5",
            "stroke": "currentColor"
          }
        }, [_c('path', {
          attrs: {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "d": "M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          }
        })]), _vm._v(" Active ")])];
      },
      proxy: true
    }])
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }