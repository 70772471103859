







































































































































































import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {
  EditInvestmentClientPayload,
  InvestmentClient
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
// import {
//   verifyInvestorStepThreeValidation,
// } from "@/modules/genprox/modules/fund/modules/fundraising/components/add-new-investor/validations";
import {mapGetters, mapState} from "vuex";
import {GlobalSelects} from "@/modules/shared/dictionary-store/types";
import {Prop} from "vue-property-decorator";
import {InvestorFormViewType} from "@/modules/genprox/modules/fund/modules/fundraising/models/InvestorFormViewType.enum";
import AddNewInvestorBoilerplate from './AddNewInvestorBoilerplate.vue';
import { zipCode } from '@/plugins/vuelidate';
import { required } from 'vuelidate/lib/validators';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniLoader,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle},
  computed: {
  ...mapGetters('dictionary', {
    globalSelects: 'globalSelects'
  }),
  ...mapState('investors', {
    editInvestmentClientData: (state: any) => state.editInvestmentClientData,
    propagate: (state: any) => state.propagate,
  })
  }
})
export default class AddNewInvestorFormStepThree extends AddNewInvestorBoilerplate {

  @Prop() investorId!: string;
  @Prop({default: InvestorFormViewType.newInvestor}) viewType!: InvestorFormViewType;
  editInvestmentClientData!: InvestmentClient;
  propagate!: boolean;

  investmentData: InvestmentClient = {} as any;
  loading: boolean = false;
  globalSelects!: GlobalSelects;

  get isReadOnly() {
    return this.viewType === InvestorFormViewType.previewInvestor
  }

  setPropagate(value: boolean) {
    this.$store.commit('investors/setPropagate', value)
  }

  beforeMount() {
    switch (this.viewType){
      case InvestorFormViewType.verifyInvestor:
      case InvestorFormViewType.newInvestor:
        this.investmentData = _.cloneDeep(this.$store.getters['investors/newInvestmentClient']);
        break;
      case InvestorFormViewType.editInvestor:
      case InvestorFormViewType.previewInvestor:
        this.investmentData = _.cloneDeep(this.editInvestmentClientData);
        break;
    }

    if(this.skip3rdStep) {
      this.goNext();
    }
  }

  async goNext() {
    if (this.viewType === InvestorFormViewType.previewInvestor) {
      return;
    }

    if(this.investmentData.isMailingAddressSameResidential){
      this.investmentData.mailingAddress = this.investmentData.residentialAddress;
    }

    this.$v.$touch();
    if(this.$v.$error) return;
    this.loading = true;
      switch (this.viewType){
        case InvestorFormViewType.verifyInvestor:
          await this.$store.dispatch('investors/authorizeInvestmentClient', this.investmentData);
          await this.$router.push('success');
          break;
        case InvestorFormViewType.newInvestor:
          try {
            const investmentClientId = await this.$store.dispatch('investors/addNewInvestmentClient', this.investmentData);
            const productType = this.$route.query?.productType?.toString() || undefined;
            const subscriptionId = this.$route.query?.subscriptionId;

            if (this.$route.query?.type === 'subscription_product') {
              const routeParams: any = { investorId: investmentClientId, productType }
              if (subscriptionId) {
                routeParams['subscriptionId'] = subscriptionId?.toString()
              }
              this.$router.push({ name: 'addNewProductStepOne', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/new/step-one`, params: routeParams })
            } else {
              await this.$router.push('success');
            }

          } catch(e) {
            const errorMessage = this.$options.filters.errorHandler(e);
            this.$notify({
              duration: 2500,
              type: 'error',
              title: 'Error',
              text: this.$t(errorMessage).toString()
            })
          }
          break;
        case InvestorFormViewType.editInvestor: {
          const action = this.propagate ? 'investors/editInvestmentClientAndPropagate' : 'investors/editInvestmentClient';
          const data: EditInvestmentClientPayload = { data: this.investmentData, id: this.investorId };
          try {
            await this.$store.dispatch(action, data);
            if(this.$route.query?.productId) {
              await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${(this.$route.query?.productId as string)}/for-legal-entity` });
            } else {
              await this.$router.push({name:'profile-dashboard-guest-investor', params: {id: this.investorId}})
            }
            this.$notify({
              type: 'success',
              title: 'Edit profile success',
              text: 'Changes has been saved'
            });
          } catch (e){
            this.$notify({
              type: 'error',
              title: 'Edit profile failed',
              text: 'An error occurred'
            });
          }
          break;
        }
      }
    this.loading = false;
  }

  get validationObject() {
    let verification: any = {};
    if(this.viewType === 'newInvestor' || this.viewType === 'editInvestor' || this.viewType == 'verifyInvestor'){
      verification = {
        investmentData: {
          residentialAddress: {
            zipCode: {
              zipCode: zipCode,
            }
          },
          mailingAddress: {
            zipCode: {
              zipCode: zipCode,
            }
          }
        }
      };

      if(this.isLeadFormFieldRequired('residential_address_street')) {
        verification.investmentData.residentialAddress.street = { required };
      }
      
      if(this.isLeadFormFieldRequired('residential_address_house_number')) {
        verification.investmentData.residentialAddress.houseNumber = { required };
      }

      if(this.isLeadFormFieldRequired('residential_address_flat_number')) {
        verification.investmentData.residentialAddress.flatNumber = { required };
      }

      if(this.isLeadFormFieldRequired('residential_address_country')) {
        verification.investmentData.residentialAddress.country = { required };
      }

      if(this.isLeadFormFieldRequired('residential_address_city')) {
        verification.investmentData.residentialAddress.city = { required };
      }

      if(this.isLeadFormFieldRequired('residential_address_zip_code')) {
        verification.investmentData.residentialAddress.zipCode = { required };
      }
      
      if(this.isLeadFormFieldRequired('residential_address_voivodeship')) {
        verification.investmentData.residentialAddress.voivodeship = { required };
      }

      if(this.isLeadFormFieldRequired('residential_address_county')) {
        verification.investmentData.residentialAddress.county = { required };
      }

      if(this.isLeadFormFieldRequired('residential_address_commune')) {
        verification.investmentData.residentialAddress.commune = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_street')) {
        verification.investmentData.mailingAddress.street = { required };
      }
      
      if(this.isLeadFormFieldRequired('mailing_address_house_number')) {
        verification.investmentData.mailingAddress.houseNumber = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_flat_number')) {
        verification.investmentData.mailingAddress.flatNumber = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_country')) {
        verification.investmentData.mailingAddress.country = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_city')) {
        verification.investmentData.mailingAddress.city = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_zip_code')) {
        verification.investmentData.mailingAddress.zipCode = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_voivodeship')) {
        verification.investmentData.mailingAddress.voivodeship = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_county')) {
        verification.investmentData.mailingAddress.county = { required };
      }

      if(this.isLeadFormFieldRequired('mailing_address_commune')) {
        verification.investmentData.mailingAddress.commune = { required };
      }
    } 
    
    // if (this.viewType === 'verifyInvestor'){
    //   verification = verifyInvestorStepThreeValidation();
    // }

    return verification;
  }

  validations () {
    return this.validationObject;
  }

}
