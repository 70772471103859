var render = function () {
  var _vm$$v, _vm$$v$newReportFormD, _vm$$v$newReportFormD2, _vm$newReportFormData, _vm$newReportFormData2, _vm$newReportFormData3, _vm$newReportFormData4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 offset-lg-2"
  }, [_c('sygni-container-title', [_vm._v("Krok 1 - Wybierz okres raportowania")]), _c('sygni-info-box', [_c('p', [_vm._v(" Sprawozdawczością regulacyjną do KNF objęte są wewnętrznie zarządzane ASI oraz zewnętrznie zarządzane ASI wpisane do rejestru KNF do końca III kwartału za rok, w którym składane jest sprawozdanie. Jeśli WZASI, ZASI lub jego ASI została zarejestrowana w IV kwartale, to nie ma obowiązku raportowania za dany rok. ")]), _c('p', [_vm._v(" Dla każdego zewnętrznie zarządzanego ASI tworzony jest odrębny raport, który wraz z raportem dla ZASI złożony musi być w systemie ESPI do KNF. W przypadku WZASI generowane są również dwa raporty, które muszą być złożone w systemie ESPI. ")])])], 1), _c('div', {
    staticClass: "col-12 col-lg-8 offset-lg-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "value": _vm.newReportFormData.reportYear.value,
      "format": 'YYYY',
      "label": 'Reporting year',
      "disabled-date": _vm.disabledDate,
      "disabled": _vm.isDisabled,
      "clearable": false,
      "type": "year"
    },
    on: {
      "change": _vm.updateReportYear
    }
  }), _c('div', {
    staticClass: "step__type"
  }, [_c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "value": _vm.newReportFormData.reportType.isFirstReport,
      "group": 'type',
      "label": 'First report',
      "disabled": _vm.isDisabled,
      "radio-value": true
    },
    on: {
      "input": function input($event) {
        return _vm.setFirstReportOption(true);
      }
    }
  }), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "value": _vm.newReportFormData.reportType.isFirstReport,
      "group": 'type',
      "label": 'Correction',
      "disabled": _vm.isDisabled,
      "radio-value": false
    },
    on: {
      "input": function input($event) {
        return _vm.setFirstReportOption(false);
      }
    }
  })], 1), !_vm.newReportFormData.reportType.isFirstReport ? _c('sygni-input', {
    ref: "correctionInput",
    attrs: {
      "value": _vm.newReportFormData.reportType.value,
      "useInvalidProperty": true,
      "disabled": _vm.isDisabled,
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$newReportFormD = _vm$$v.newReportFormData) === null || _vm$$v$newReportFormD === void 0 ? void 0 : (_vm$$v$newReportFormD2 = _vm$$v$newReportFormD.reportType) === null || _vm$$v$newReportFormD2 === void 0 ? void 0 : _vm$$v$newReportFormD2.value,
      "label": 'Correction number',
      "type": 'number',
      "max": "999",
      "min": "1",
      "mask": "threeDigitNumber"
    },
    on: {
      "input": _vm.setCorrectionNumber
    }
  }) : _vm._e(), !_vm.reportStatus && !((_vm$newReportFormData = _vm.newReportFormData) !== null && _vm$newReportFormData !== void 0 && (_vm$newReportFormData2 = _vm$newReportFormData.reportType) !== null && _vm$newReportFormData2 !== void 0 && _vm$newReportFormData2.isFirstReport) ? [_c('sygni-line', {
    class: ['grey mb-4', (_vm$newReportFormData3 = _vm.newReportFormData) !== null && _vm$newReportFormData3 !== void 0 && (_vm$newReportFormData4 = _vm$newReportFormData3.reportType) !== null && _vm$newReportFormData4 !== void 0 && _vm$newReportFormData4.isFirstReport ? 'mt-4' : 'mt-2']
  }), _c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "disabled": _vm.isLastReportDataLoadingDisabled,
      "value": _vm.loadLastCompletedReport,
      "label": "LOAD LAST COMPLETED REPORT DATA FROM SELECTED YEAR"
    },
    on: {
      "input": _vm.handleLoadLastCompletedReport
    }
  })] : _vm._e()], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }