























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniDropdownItem from "@/components/sygni-dropdown/SygniDropdownItem.vue";
import SygniDropdown, {DropdownImageItem} from "@/components/sygni-dropdown/SygniDropdown.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {mapState} from "vuex";
import LogoutButton from "@/components/LogoutButton.vue";
import {UserContext} from "@/modules/genprox/models/User";
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniContextSelect from '@/components/inputs/SygniContextSelect.vue';


@Component({
  components: {
    LogoutButton,
    SygniArrowButton, UserPresentation, SygniDropdownItem, SygniDropdown, SygniSquareButton, SygniRectButton, SygniContextSelect },
  computed: {
    ...mapState('genprox', {
      userContexts: (state: any) => state.userContexts,
      activeUserContextId: (state: any) => state.activeUserData.context?.id,
      isVerifiedProfile: (state: any) => state.activeUserData?.verified,
      user: (state: any) => state.activeUserData?.user,
    }),
  },

})
export default class AppHeader extends Vue {

  userContexts!: UserContext[];
  activeUserContextId!: string;
  isVerifiedProfile!: boolean;
  user: any;

  get dropdownUserContexts(): DropdownImageItem<UserContext>[] {
    if(this.userContexts?.length === 0 ) return null;
    return this.userContexts.map<DropdownImageItem<UserContext>>( (userContext: UserContext) => {
      return {
        text: userContext.name,
        subtext: '',
        image: userContext.logo,
        value: userContext
      }
    })
  }

  get dropdownUserContextOptions() {
    return this.dropdownUserContexts.map((el: any) => {
      return {
        label: el?.text,
        value: el?.value?.id,
        data: el?.value,
      }
    })
  }

  get hasPrivateFundManager() {
    return !!(this.dropdownUserContextOptions?.find((el: any) => el?.data?.context === 'private-fund-manager'))
  }

  get dropdownActiveContext(): DropdownImageItem<UserContext> {
      return this.dropdownUserContexts.find( context => context.value.id === this.activeUserContextId);
  }

  get activeUserDataContext() {
    return this.$store.getters['genprox/activeUserData']?.context?.context;
  }

  async contextHandler(id: string) {
    if(id) {
      const activeItem = this.dropdownUserContexts.find((el: any) => el.value.id === id)?.value;
      this.$emit('setUserContext', activeItem);
    } else {
      // check if only advisor panel
      if (this.dropdownUserContexts?.length === 1 && this.dropdownUserContexts[0]?.value?.context === 'advisor-panel') {
        this.$emit('setUserContext', this.dropdownUserContexts[0]?.value)
      } else {
        const advisorCurrentContext = this.dropdownUserContexts?.find((el: any) => el?.value?.context === 'advisor-panel' && el?.value?.id === this.activeUserContextId)
        if (advisorCurrentContext) {
          this.$emit('setUserContext', advisorCurrentContext?.value)
        }
      }
    }
  }

  async mounted(){
    this.userContexts.sort( (a) => {
      if(this.activeUserContextId === a.id) return 1;
      else return -1;
    });
  }

  goToProfile(): void {
    this.$router.push({name: 'profile'});
  }

  logout(): void {
    this.$store.dispatch('auth/logout');
  }
}
