import {Fund, FundManager} from "@/modules/genprox/models/User";
import {Report} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";

export interface OutputReportData {
    aif: AIF[],
    aifmName: string | null,
    aifmNumber: string | null,
    id: string | null,
    regulatoryDeRegistrationDate: string | null,
    regulatoryRegistrationDate: string | null,
}

export interface AIF {
    aifManagementStartDate: string | null,
    aifName: string | null,
    aifNumber: string | null,
    dateOfDiscontinuationOfAifManagement: string | null,
    id: string | null,
}

export interface RegRepState {
    funds: Array<Fund>
    reports: Array<Report>;
    fundData: FundManager,
    outputReportData: OutputReportData,
    newReportFormData?: any,
    assetsDataSchema: any,
    previousAsiOptions: any,
    liabilitiesDataSchema: any,
    additionalDataSchema: any,
    euroExchangeRates: Record<string, string | number>,
    loadLastCompletedReport: boolean,
}

export interface CreateRegRepDTO {
    year : number,
    fundIds : Array<any>,
    correctionNumber: number,
    draft: boolean,
    data: string | null,
}



export interface UploadFileToReportDTO {
    payload: {
        fileId: string,
        fundId: string,
        category: string
    },
    reportId: string,
    inputFileId?: string,
}

export interface DeleteFileDto {
    reportId: string,
    fileId: string
}

export enum ReportStatuses {
    NEW = 'new',
    GENERATED = 'generated',
    DRAFT = 'draft',
    APPROVED = 'approved',
    COMPLETED = 'completed',
    SENT_TO_ESPI = 'sent-to-espi'
}
