var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "genproxFormBar"
    }
  }, [_c('div', {
    staticClass: "gn-form-bar"
  }, [_c('div', {
    staticClass: "gn-form-bar__inner"
  }, [_c('div', {
    staticClass: "gn-form-bar__container"
  }, [_c('div', {
    staticClass: "gn-form-bar__left-col"
  }, [_vm._t("actionsLeft", function () {
    return [_c('div', {
      staticClass: "gn-form-bar__heading"
    }, [_c('p', {
      staticClass: "gn-form-bar__header"
    }, [_vm._v(_vm._s(_vm.header))]), _c('p', {
      staticClass: "gn-form-bar__desc"
    }, [_vm._v(_vm._s(_vm.description))])])];
  })], 2), _c('div', {
    staticClass: "gn-form-bar__right-col"
  }, [_vm._t("actionsRight")], 2)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }