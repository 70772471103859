var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['toolbox', _vm.isPlaceholder ? 'toolbox--placeholder' : '']
  }, [_vm.isPlaceholder ? [_c('div', {
    staticClass: "toolbox__btn"
  }, [_c('svg', {
    staticClass: "size-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
    }
  })])]), _c('p', {
    staticClass: "toolbox__desc"
  }, [_vm._v("We're working on more tools - stay tuned!")])] : [this.href ? _c('router-link', {
    staticClass: "toolbox__header",
    attrs: {
      "to": this.href
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _c('p', {
    staticClass: "toolbox__header"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "toolbox__desc"
  }, [_vm._v(_vm._s(_vm.description))]), _c('div', {
    staticClass: "toolbox__dragarea"
  }, [_c('div', {
    class: ['droparea', _vm.dragActive ? 'droparea--active' : ''],
    on: {
      "dragenter": function dragenter($event) {
        _vm.dragActive = true;
      },
      "dragleave": _vm.dragFileLeave,
      "dragover": _vm.dragFileOver,
      "drop": function drop($event) {
        return _vm.uploadXmlFile($event, $event.dataTransfer.files);
      }
    }
  }, [_c('div', {
    staticClass: "droparea__btn"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "2",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
    }
  })])]), _c('div', {
    staticClass: "droparea__text"
  }, [_c('input', {
    ref: "fileInput",
    attrs: {
      "id": _vm.uploadId,
      "accept": "text/xml",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadXmlFile($event, $event.target.files);
      }
    }
  }), _c('p', [_c('label', {
    attrs: {
      "for": _vm.uploadId
    }
  }, [_vm._v("Click to upload")]), _vm._v(" or drag and drop")]), _vm._m(0)])])])]], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" File format: XML "), _c('br'), _vm._v(" max 5 MB ")]);
}]

export { render, staticRenderFns }