var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-radio', _vm.disabled ? 'sygni-radio--disabled' : '', !_vm.legacyStyling ? 'tw-theme' : '']
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modelValue,
      expression: "modelValue"
    }],
    class: this.value ? 'sygni-checkbox--active' : '',
    attrs: {
      "type": "radio",
      "disabled": _vm.disabled,
      "name": _vm.group
    },
    domProps: {
      "value": _vm.radioValue,
      "checked": _vm._q(_vm.modelValue, _vm.radioValue)
    },
    on: {
      "input": function input($event) {
        return _vm.handleInput(_vm.radioValue);
      },
      "change": function change($event) {
        _vm.modelValue = _vm.radioValue;
      }
    }
  }), _c('div', {
    staticClass: "label",
    on: {
      "click": function click($event) {
        return _vm.clickCheckbox();
      }
    }
  }, [_vm._v(_vm._s(_vm.label))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }