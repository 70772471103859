
















































































import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniToggleSwitch from '@/components/inputs/SygniToggleSwitch.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniDroparea from '@/components/inputs/SygniDroparea.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import { Campaign } from '../../modules/campaigns/store/types';
import { AddProductSelects } from '@/modules/shared/dictionary-store/types';
import { mapGetters } from 'vuex';
import { Wallet } from '@/shared/interfaces/Wallet';
import { Prop, Watch } from 'vue-property-decorator';
import AddNewCampaignBoilerplate from './AddNewCampaignBoilerplate.vue';
import { CodeFormat, TimeTypes } from '../../store/types';
import { SelectOption } from '@/store/types';
import { Template } from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/store/types'

@Component({
  components: { SygniContainerTitle, SygniDroparea, SygniInput, SygniTextArea, SygniSelect, SygniRectButton, SygniLinkButton, SygniDatePicker, SygniToggleSwitch, SygniRadio },
  computed: {
    ...mapGetters('dictionary', {
      productSelects: 'addProductsSelects'
    }),
    ...mapGetters('genprox', {
      activeContextWallets: 'activeContextWallet'
    }),
  }
})
export default class AddNewCampaignFormStepFour extends AddNewCampaignBoilerplate {
  @Prop({ default: null }) validation: any;
  activeContextWallets!: Array<Wallet>;
  fixedReplacementDay: boolean = true;
  productSelects!: AddProductSelects;
  advancedOptions: boolean = false;
  campaignData: Campaign = null;
  templatesList: Template[] = [];

  get activeContextWalletsOptions() {
    return this.activeContextWallets.map(wallet => ({ label: this.$options.filters.bankAccountNumber(wallet.account), value: wallet.account }))
  }

  get hasId() {
    return this.$route?.params?.id ? true : false;
  }

  get isActiveCampaign() {
    return !!(this.campaignData?.status?.toLowerCase() === 'active')
  }

  get attachmentOptions(): SelectOption[] {
    return this.templatesList?.map((el: any) => {
      let title: string = el.title
      const obj: any = { label: title, value: el.id }

      if (this.activeUserData?.context?.id !== el?.legalEntityId) {
        obj.legalEntityName = el?.legalEntityName
      }

      return obj
    })
  }

  get isProductCodePrefixRequired() {
    const productCode = this.productCodeFormats?.find((format: CodeFormat) => format.value === this.campaignData?.productCodeFormat)

    return productCode ? productCode?.prefixRequired : false
  }

  get productCode() {
    return this.productCodeFormats?.find((format: CodeFormat) => format.value === this.campaignData?.productCodeFormat)
  }

  setDurationType(value: string) {
    if (this.campaignData.products[0].data.duration === null) {
      this.campaignData.products[0].data.duration = {
        type: value,
        count: undefined,
      }
    } else {
      this.campaignData.products[0].data.duration.type = value;
    }

    this.setCampaignData();
  }

  setDurationCount(value: number) {
    if (this.campaignData.products[0].data.duration === null) {
      this.campaignData.products[0].data.duration = {
        type: undefined,
        count: value,
      }
    } else {
      this.campaignData.products[0].data.duration.count = value;
    }

    this.setCampaignData();
  }

  setEnabledAdvancedOptions(value: boolean) {
    this.campaignData.products[0].data.enabledAdvancedOptions = value;

    this.removeUnselectedProductData();
    if (this.campaignData.products[0].data.enabledAdvancedOptions) {
      this.campaignData.products[0].data.advancedOptions = {
        commission: null,
        commissionPayment: '',
        commissionType: '',
        margin: null,
        marginPayment: '',
      }
    } else {
      this.campaignData.products[0].data.simpleOptions = {
        interest: null,
        interestPayment: '',
      }
    }

    this.setCampaignData();
  }

  setCommissionPA(value: any) {
    this.campaignData.products[0].data.advancedOptions.commission = value;

    this.setCampaignData();
  }

  setCommissionType(value: any) {
    this.campaignData.products[0].data.advancedOptions.commissionType = value;

    this.setCampaignData();
  }

  setMarginPA(value: any) {
    this.campaignData.products[0].data.advancedOptions.margin = value;

    this.setCampaignData();
  }

  setProductCodeFormat(value: any) {
    this.campaignData.productCodeFormat = value;

    this.setCampaignData();
  }

  setProductCodePrefix(value: any) {
    this.campaignData.productCodePrefix = value;

    this.setCampaignData();
  }

  setCommissionsPayment(value: any) {
    this.campaignData.products[0].data.advancedOptions.commissionPayment = value;

    this.setCampaignData();
  }

  setMarginPayment(value: any) {
    this.campaignData.products[0].data.advancedOptions.marginPayment = value;

    this.setCampaignData();
  }

  removeUnselectedProductData(): void {
    if (this.fixedReplacementDay) {
      this.campaignData.products[0].data.timeType = TimeTypes.fixedRepaymentDate;
      this.campaignData.products[0].data.duration = null;
    } else {
      this.campaignData.products[0].data.timeType = TimeTypes.duration;
      delete this.campaignData.products[0].data.fixedRepaymentDate;
    }
    if (this.campaignData.products[0].data.enabledAdvancedOptions) {
      this.campaignData.products[0].data.simpleOptions = null;
    } else {
      this.campaignData.products[0].data.advancedOptions = null;
    }

    this.setCampaignData();
  }

  setDebtor(value: string) {

    const option: any = this.activeContextWallets.find((el: any) => el.account === value);

    if(option?.account) {
      const debtor: any = {
        account: option.account,
        name: option.name,
        currency: option.currency,
        type: option.type,
      }
  
      this.campaignData.products[0].data.debtor = debtor;
    } else {
      this.campaignData.products[0].data.debtor = null;
    }


    this.setCampaignData();
  }

  @Watch('fixedReplacementDay') onFixedReplacementDay() {
    this.removeUnselectedProductData();
  }

  @Watch('isProductCodePrefixRequired') onProductCodePrefixRequiredChange() {
    if (!(this.productCode && this.productCode?.prefixRequired)) {
      this.campaignData.productCodePrefix = null
    }
  }

  async beforeMount() {
    this.fixedReplacementDay = this.campaignData.products[0].data?.timeType === TimeTypes.fixedRepaymentDate ? true : false;
    const templates = await this.$store.dispatch('templates/getTemplateChoices', { type: 'template', context: this.campaignData?.issuerId ? this.campaignData?.issuerId : this.activeUserData?.context?.id } )
    await this.$store.dispatch('investors/getProductCodeFormats')
    this.templatesList = templates
  }
}
