import {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import vuexStore from "@/store/rootStore";
import store from "@/store/rootStore";
import Vue from "vue";

const authModule = () => import('./containers/AuthModule.vue');
const registerForm = () => import('./components/new/RegisterPage.vue');
const resetPassword = () => import('./components/new/ResetPasswordPage.vue');
const remindPassword = () => import('./components/new/RemindPasswordPage.vue');
const login = () => import('./components/new/LoginPage.vue');
const confirmLogin = () => import('./components/new/LoginConfirmPage.vue');
const textContainer = () => import('./../auth/components/TextContainer.vue');
const activationAccount = () => import('./components/new/ActivateFromEmailPage.vue');

export const authRouting: Array<RouteConfig> = [
    {
        path: '/auth',
        name: 'auth',
        component: authModule,
        redirect: {
            name: 'login',
        },
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if(vuexStore.getters['auth/apiKey']) { // TODO: zmienic na rquest do authorization
                store.commit('auth/removeApiKey')
                next();
            } else {
                next();
            }
        },
        children: [
            {
                path: 'register',
                name: 'register',
                props: () => ({formType: 'register'}),
                component: registerForm,
            },
            {
                path: 'login',
                name: 'login',
                component: login,
            },
            {
                path: 'login/confirm',
                name: 'confirm-login',
                component: confirmLogin,
            },
            {
                path: 'demo',
                name: 'demo',
                props: () => ({formType: 'demo'}),
                component: registerForm,
            },
            {
                path: 'remind-password',
                name: 'remind-password',
                component: remindPassword
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                redirect: { name:'remind-password' },
                component: resetPassword
            },
            {
                path: 'reset-password/:id',
                name: 'reset-password',
                component: resetPassword
            },
            {
                path: 'logout',
                name: 'logout',
                beforeEnter: (to,from, next) => {
                    store.commit('auth/removeApiKey');
                    next({name: 'login'});
                }
            },
            {
                path: 'impersonate',
                name: 'impersonate',
                beforeEnter: async (to, from, next) => {
                    if(to?.query?.token && to?.query?.userId && to?.query?.adminUserId) {
                        await store.dispatch('auth/impersonate', {
                            token: to.query.token,
                            userId: to.query.userId,
                            adminUserId: to.query.adminUserId,
                        })
                        next({ name: 'welcome' });
                    } else {
                        next('/auth/login');
                    }
                }
            },
            {
                name: 'activate',
                path: 'activate-from-email/:id',
                component: activationAccount
            },
            {
                name: 'activate',
                path: 'activate/:id',
                beforeEnter: async (to, from, next) => {
                    try{
                        await store.dispatch('auth/activateAccount', {token: to.params.id});
                        setTimeout( () => {
                            Vue.prototype.$notify({
                                type: 'success',
                                title: 'Your account has been activated!'
                            });
                        }, 1000)
                    } catch (error) {
                        setTimeout( () => {
                            Vue.prototype.$notify({
                                type: 'error',
                                title: 'Error',
                                text: error.response.data.label
                            });
                        }, 1000)
                    }
                    next({ name: 'login' });
                }
            }
        ]
    },
    {
        path: '/',
        component: authModule,
        children: [
            {
                path: 'regulations',
                name: 'regulations',
                component: textContainer,
                beforeEnter: async (to, from, next) => {
                    const regulations=  store.dispatch('genprox/getRegulations');
                    const privacyPolicy =  store.dispatch('genprox/getPrivatePolicy');
                    to.meta.innerHTML = await regulations;
                    to.meta.innerHTML += await privacyPolicy;
                    to.meta.from = from.name;
                    next();
                },
                props: route => ({innerHTML: route.meta.innerHTML, from: route.meta.from})
            },
        ]
    }
];
