


















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import FormBoilerplate from '../FormBoilerplate.vue';
import { required, email } from 'vuelidate/lib/validators';

Component.registerHooks(['validations'])
@Component({
  components: { SygniInput, SygniRectButton, SygniLinkButton, FormBoilerplate }
})
export default class RemindPasswordPage extends Vue {
  email: string = '';
  isResetPasswordSent: boolean = false;
  loading: boolean = false;

  validations (){
    return {
        email: { required, email },
      }
  }

  goToLogin() {
    this.$router.push('/auth/login');
  }

  async sendRequestResetPassword(){
    this.$v.$touch();
    if(!this.$v.$error){
      this.loading = true
      try{
        await this.$store.dispatch('auth/sendRemindPasswordRequest', this.email);
        this.isResetPasswordSent = true;
      } catch (e) {
        const errorMessage = this.$options?.filters?.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.$v.$reset();
      this.email = '';
      this.loading = false
    }
  }
}
