import { render, staticRenderFns } from "./KycTable.vue?vue&type=template&id=48d4d603&scoped=true&"
import script from "./KycTable.vue?vue&type=script&lang=ts&"
export * from "./KycTable.vue?vue&type=script&lang=ts&"
import style0 from "./KycTable.vue?vue&type=style&index=0&id=48d4d603&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d4d603",
  null
  
)

export default component.exports