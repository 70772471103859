


























































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import FormBoilerplate from '../FormBoilerplate.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import { UserTypes } from '@/shared/consts';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import { RegisterDTO } from '../../store/types';
import GenproxModal from '@/components/layout/GenproxModal.vue';

Component.registerHooks(['validations'])
@Component({
  components: { SygniInput, SygniRectButton, SygniLinkButton, SygniInputError, SygniCheckbox, SygniRadio, FormBoilerplate, GenproxModal }
})
export default class RegisterPage extends Vue {
  UserTypes = UserTypes;
  email: string = '';
  name: string = '';
  surname: string = '';
  password: string = '';
  repeatPassword: string = '';
  registrationToken: string = null;
  registerProductId: string = '';
  isRegistered: boolean = false;
  showDemoModal: boolean = false;

  investorType: UserTypes = null;
  agreement: boolean = false;
  showRadios: boolean = true;
  loading: boolean = false;

  mounted(){
    if(this.$route.params.userData){
      const userData = this.$route.params.userData as any;
      this.registrationToken = this.$route.params.registrationToken;
      this.email = userData.email;
      this.name = userData.firstName;
      this.surname = userData.surname;
      this.investorType = UserTypes.privateInvestor;
      (this.$refs.emailInput as SygniInput).isDisabled = true;
      this.showRadios = false;
      this.registerProductId = localStorage.getItem('registerProductId');
    } else {
      this.$el.querySelectorAll<HTMLInputElement>('input[type="radio"]').forEach( radio => {
        if(radio.value === UserTypes.fundManager){
          radio.checked = true;
          this.investorType = UserTypes.fundManager;
        }
        radio.disabled = true;
      });
    }
  }

  async register() {
    this.$v.$touch();
    if(!this.$v.$error){
      const payload: RegisterDTO = this.getRegisterPayload();
      try{
        this.loading = true;
        await this.$store.dispatch('auth/register',payload);
        if(this.investorType === UserTypes.privateInvestor){
          await this.$store.dispatch('auth/login', {
            email: this.email,
            password: this.password,
          });
        } else if (this.investorType === UserTypes.fundManager){
          this.isRegistered = true
        }
      } catch (e: any){
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(e.response.data.label).toString()
        });
      }
      this.loading = false;
    }
  }

  getRegisterPayload(): RegisterDTO {
    const payload: RegisterDTO =  {
      email: this.email,
      plainPassword: this.password,
      firstName: this.name,
      surname: this.surname,
      type: this.investorType,
      token: null,
    };
    if(this.registrationToken){
      payload.token = this.registrationToken;
    }
    return payload;
  }

  validations () {
    return  {
      email: {required, email},
      name: {required},
      surname: {required},
      password: {required, strongPassword: (value: string) => {
          return Boolean(value?.match('^(?=.*[A-Z])(?=.*[!@#$&*^%])(?=.*[0-9])(?=.*[a-z]).{8,}$'));
        }},
      repeatPassword: {required, sameAsPassword: sameAs('password')},
      investorType: {required},
      agreement: {required, checked: (value: boolean) => { return value; }}
    }
  }

  openDemoModal() {
    this.showDemoModal = true
  }

  closeDemoModal() {
    this.showDemoModal = false
  }

  openDemoForm() {
    window.open('https://genprox.pipedrive.com/scheduler/KkRaMRIR/demo-fundequate', '_blank')
  }

  async showRegulations(){
    let routeData = this.$router.resolve({name: 'regulations'});
    window.open(routeData.href, '_blank');
  }
}
