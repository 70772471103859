












import _ from 'lodash';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class GenproxBreadcrumbs extends Vue {
  @Prop({ default: null }) title: string;
  items: any[] = []

  get formattedItems() {
    let formattedItems = _.cloneDeep(this.items)

    formattedItems = formattedItems.map((el: any) => {
      if (el?.text === ':title') {
        el.text = this.title || el?.text
      }

      return el
    })

    return formattedItems
  }

  mounted() {
    this.items = this.$route?.meta?.breadcrumbs || []
  }
}
