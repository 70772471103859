var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-boilerplate', {
    attrs: {
      "formTitle": !_vm.isResetPasswordSent ? 'Reset your password' : ''
    },
    scopedSlots: _vm._u([{
      key: "fields",
      fn: function fn() {
        var _vm$$v;

        return [!_vm.isResetPasswordSent ? [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'Email address',
            "name": "natural_person[email]",
            "legacyStyling": false,
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : _vm$$v.email
          },
          on: {
            "blur": function blur($event) {
              var _vm$$v2;

              (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : _vm$$v2.$touch();
            }
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        })] : [_c('div', {
          staticClass: "remind-password-sent"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/accept.svg")
          }
        }), _c('div', {
          staticClass: "title"
        }, [_vm._v("Your password reset has been sent.")]), _c('div', {
          staticClass: "description"
        }, [_vm._v(" Check your email.")])])]];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [!_vm.isResetPasswordSent ? [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-form__btn",
          attrs: {
            "legacyStyling": false,
            "loading": _vm.loading
          },
          on: {
            "click": function click($event) {
              return _vm.sendRequestResetPassword();
            }
          }
        }, [_vm._v("Reset password")])] : [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-form__btn",
          attrs: {
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/auth/login');
            }
          }
        }, [_vm._v("Go to login")])]];
      },
      proxy: true
    }, {
      key: "actionsFooter",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "footNote",
      fn: function fn() {
        return [_c('sygni-link-button', {
          staticClass: "gn-primary",
          attrs: {
            "type": "simple",
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/auth/login');
            }
          }
        }, [_vm._v("Go back to login")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }