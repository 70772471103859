import { render, staticRenderFns } from "./FreeToolsBoxLegacy.vue?vue&type=template&id=62aac3a7&scoped=true&"
import script from "./FreeToolsBoxLegacy.vue?vue&type=script&lang=ts&"
export * from "./FreeToolsBoxLegacy.vue?vue&type=script&lang=ts&"
import style0 from "./FreeToolsBoxLegacy.vue?vue&type=style&index=0&id=62aac3a7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62aac3a7",
  null
  
)

export default component.exports