import {RouteConfig} from "vue-router";
import {AddRegRepType} from "@/modules/genprox/modules/fund/modules/reg-reporting/store/types";
import {accountingRouting} from "@/modules/accounting/accounting.routing";
import {reportingRouting} from "@/modules/genprox/modules/reporting/reporting.routing";
import store from "@/store/rootStore";
import { statementsRouting } from "@/modules/statements/statements.routing";
import { analyticsRouting } from "../analytics/analytics.routing";

const AIFMModule = () => import('./containers/AIFMModule.vue');
const dashboard = () => import('./modules/dashboard/containers/Dashboard.vue');
const regReportingModule = () => import('../fund/modules/reg-reporting/containers/RegReportingModule.vue');
const regReportingBlindModule = () => import('../fund/modules/reg-reporting/containers/RegReportingBlindModule.vue');
const addReport = () => import('./modules/reg-reporting/containers/AddNewReport.vue');
const knfXmlPreview = () => import('./modules/reg-reporting/containers/KnfXmlPreview.vue');
const xmlPreview = () => import('./modules/reg-reporting/containers/XmlPreview.vue');

export const AIFMRouting: Array<RouteConfig>  = [
    {
        path: 'fund-manager',
        name: 'AIFM',
        component: AIFMModule,
        children: [
            ...accountingRouting,
            ...statementsRouting,
            ...reportingRouting,
            {
                path: 'dashboard',
                name: 'AIFM-dashboard',
                component: dashboard,
            },
            {
                path: 'regulatory-reporting',
                name: 'AIFM-regulatory-reporting',
                props: true,
                component: regReportingModule,
                async beforeEnter(to, from, next) {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.regulatoryReporting) {
                        next();
                    } else {
                        next(`/fund-manager/access-denied`);
                    }
                },
            },
            {
                path: 'regulatory-reporting/knf-xml-preview',
                name: 'aifm-reg-xml-preview',
                props: true,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: 'Reg reporting', href: '/fund-manager/regulatory-reporting' },
                        { text: 'XML Reader for AIF Regulatory Reporting', href: '' },
                    ]
                },
                component: knfXmlPreview,
            },
            {
                path: 'regulatory-reporting/xml-preview',
                name: 'aifm-xml-preview',
                props: true,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: 'Reg reporting', href: '/fund-manager/regulatory-reporting' },
                        { text: 'XML Reader for Financial Statements', href: '' },
                    ]
                },
                component: xmlPreview,
            },
            {
                path: 'regulatory-reporting/access-denied',
                name: 'aifm-reg-reporting-access-denied',
                component: regReportingBlindModule,
                meta: {
                    accessModule: ['regulatoryReporting'],
                },
            },
            ...analyticsRouting,
        ],
    },
    {
        path: 'fund-manager/access-denied',
        name: 'access-denied',
        component: regReportingBlindModule,
        meta: {
            accessModule: ['regulatoryReporting'],
        },
    }
];
