









import Vue from 'vue'
import Component from 'vue-class-component';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { SelectOption } from '@/store/types';
import { required } from 'vuelidate/lib/validators';

Component.registerHooks(['validations'])
@Component({
  components: { GenproxModal, SygniSelect }
})

export default class OwnerModal extends Vue {
  @Prop({ default: null }) owner: any
  isLoading: boolean = false
  brokerOptions: SelectOption[] = []
  selectedOwner: string = ''

  close() {
    this.clearOwner()
    this.$emit('close')
  }

  closeAndSave() {
    this.$emit('change')
    this.close()
  }

  clearOwner() {
    this.$v?.$reset()
    this.selectedOwner = ''
  }

  async changeOwner() {
    this.$v?.$touch()

    if (!this.$v?.$error) {
      this.isLoading = true

      try {
        await this.$store.dispatch('investors/changeOffersOwner', { investmentClientId: this.owner?.id, ownerId: this.selectedOwner })
        this.closeAndSave()
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }

      this.isLoading = false
    }
  }

  async mounted() {
    this.isLoading = true

    try {
      let brokers = await this.$store.dispatch('advisors/getAllOwners')
      brokers = brokers?.filter((el: any) => el?.status?.toLowerCase() === 'active').map((el: any) => {
        return { value: el.naturalPersonId, label: `${el.firstName} ${el.surname}` }
      })
      this.brokerOptions = brokers
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  validations() {
    return {
      selectedOwner: { required }
    }
  }

  @Watch('selectedOwner') onSelectedOwnerChange() {
    this.$v?.$reset()
  }
}
