

























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniFundSelect from "@/components/inputs/SygniFundSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {required} from "vuelidate/lib/validators";
import { FundSelect, FundSelectObject } from '@/modules/genprox/store/types';
import { DropdownImageItem } from '@/components/sygni-dropdown/SygniDropdown.vue';
import { UserContext } from '@/modules/genprox/models/User';
import { mapState } from 'vuex';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniRoundedButton, 
    SygniFundSelect, 
    SygniContainerTitle
  },
  computed: {
    ...mapState('genprox', {
      userContexts: (state: any) => state.userContexts,
    }),
  },
})
export default class AddNewFundStepOneNew extends Vue {
  userContexts!: UserContext[];
  fundId: string = '';

  get availableFunds(): FundSelect[] {
    return this.$store.getters['genprox/funds'].map((el: FundSelectObject) => {
      return {
        label: el.name,
        value: el.id,
        image: el.logo,
        type: el.type,
        address: el.address
      }
    }).sort((a: FundSelect, b: FundSelect) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
  }
  
  get dropdownUserContexts(): DropdownImageItem<UserContext>[] {
    if (this.userContexts?.length === 0) return null;
    return this.userContexts.map<DropdownImageItem<UserContext>>((userContext: UserContext) => {
      return {
        text: userContext.name,
        subtext: '',
        image: userContext.logo,
        value: userContext
      }
    })
  }

  get isLegalEntityAlreadyUsed() {
    return !!(this.dropdownUserContextOptions?.find((el: any) => el.value === this.fundId))
  }

  get selectedContext() {
    return this.dropdownUserContextOptions?.find((el: any) => el.value === this.fundId)?.data
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get dropdownUserContextOptions() {
    return this.dropdownUserContexts.map((el: any) => {
      return {
        label: el?.text,
        value: el?.value?.id,
        data: el?.value,
      }
    })
  }

  registerNewFund() {
    this.$router.push({ name: 'choose-fund-type', query: { id: this.$route.query.id } })
  }

  async goNext(){
    this.$v.$touch();
    if(!this.$v.$error) {
      try {
        if (!this.isLegalEntityAlreadyUsed) {
          await this.$store.dispatch('genprox/askToJoinFund', this.fundId);
          this.$notify({
            duration: 3000,
            type: 'success',
            title: 'Success',
            text: 'Request sent successfully.'
          })
          this.$router.push('/welcome');
        } else {
          this.$root.$emit('setUserContext', this.selectedContext, this.$route?.query?.id ? `/fund/accept-package/${this.$route?.query?.id}` : null)
        }
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }
    }
  }

  validations (){
    return  {
      fundId:  {required}
    }
  }

  async beforeMount() {
    if (this.activeUserData?.context?.context !== 'private-fund-manager') {
      await this.$router.push('/welcome')
      this.$notify({
        type: 'error',
        title: 'Access denied',
        text: `You don't have permission to access this page`
      })
    }

    if(!this.availableFunds.length) {
      await this.$store.dispatch('genprox/getFunds');
    }
  }
}
