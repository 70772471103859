var render = function () {
  var _vm$pack, _vm$pack2, _vm$pack3, _vm$pack4, _vm$pack$price, _vm$pack$price2, _vm$pack5, _vm$pack6, _vm$pack7, _vm$pack8, _vm$pack9;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.pack ? _c('div', {
    class: ['plan-card', (_vm$pack = _vm.pack) !== null && _vm$pack !== void 0 && _vm$pack.active ? 'plan-card--active' : '', ((_vm$pack2 = _vm.pack) === null || _vm$pack2 === void 0 ? void 0 : _vm$pack2.type) === 'on-demand' ? 'plan-card--demand' : ''],
    on: {
      "click": function click($event) {
        return _vm.buttonClick();
      }
    }
  }, [_c('div', {
    staticClass: "plan-card__inner"
  }, [_c('div', {
    staticClass: "plan-card__info"
  }, [_c('div', {
    staticClass: "plan-card__prefix"
  }, [_vm._v(_vm._s(_vm.packPrefix))]), _c('p', {
    staticClass: "plan-card__name"
  }, [_vm._v(_vm._s((_vm$pack3 = _vm.pack) === null || _vm$pack3 === void 0 ? void 0 : _vm$pack3.name))]), _c('p', {
    staticClass: "plan-card__description",
    domProps: {
      "innerHTML": _vm._s((_vm$pack4 = _vm.pack) === null || _vm$pack4 === void 0 ? void 0 : _vm$pack4.description)
    }
  }), _vm.pack.www ? _c('a', {
    staticClass: "plan-card__read-more",
    attrs: {
      "href": _vm.pack.www || 'test',
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v("Read more")]) : _vm._e()]), _c('div', {
    staticClass: "plan-card__footer"
  }, [_c('div', {
    staticClass: "plan-card__pricing"
  }, [_c('p', {
    staticClass: "plan-card__price"
  }, [_vm._v(_vm._s(_vm._f("thousandSeparator")((_vm$pack$price = _vm.pack.price) === null || _vm$pack$price === void 0 ? void 0 : _vm$pack$price.value)) + " " + _vm._s((_vm$pack$price2 = _vm.pack.price) === null || _vm$pack$price2 === void 0 ? void 0 : _vm$pack$price2.currency))]), _c('p', {
    staticClass: "plan-card__payment"
  }, [_vm._v(_vm._s(_vm.paymentPlan))])]), _c('div', {
    staticClass: "plan-card__action"
  }, [((_vm$pack5 = _vm.pack) === null || _vm$pack5 === void 0 ? void 0 : _vm$pack5.type) === 'on-demand' ? _c('sygni-rounded-button', {
    class: {
      'filled': !_vm.pack.active,
      'gn-primary': !_vm.pack.active,
      'outline': _vm.pack.active,
      'primary': _vm.pack.active
    },
    attrs: {
      "hoverable": !((_vm$pack6 = _vm.pack) !== null && _vm$pack6 !== void 0 && _vm$pack6.active),
      "disabled": _vm.requestSended
    },
    on: {
      "click": function click($event) {
        return _vm.sendRequest();
      }
    }
  }, [(_vm$pack7 = _vm.pack) !== null && _vm$pack7 !== void 0 && _vm$pack7.active ? _c('svg', {
    staticClass: "size-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    }
  })]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.requestSended ? 'Requested' : _vm.pack.active ? 'Active' : 'Request Contact'))])]) : _c('sygni-rounded-button', {
    class: {
      'filled': !_vm.pack.active,
      'gn-primary': !_vm.pack.active,
      'outline': _vm.pack.active,
      'primary': _vm.pack.active
    },
    attrs: {
      "hoverable": !((_vm$pack8 = _vm.pack) !== null && _vm$pack8 !== void 0 && _vm$pack8.active)
    }
  }, [(_vm$pack9 = _vm.pack) !== null && _vm$pack9 !== void 0 && _vm$pack9.active ? _c('svg', {
    staticClass: "size-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "d": "M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    }
  })]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.pack.active ? 'Active' : 'Choose plan'))])])], 1)])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }