










import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop} from "vue-property-decorator";

@Component
export default class SygniLinkButton extends Vue {
  @Prop({default: 'right'}) arrowSide: 'right' | 'left';
  @Prop({default: 'arrow'}) type: 'arrow' | 'simple';
  @Prop({default: true}) legacyStyling: boolean;
  @Prop({default: ''}) tooltip: string;
  @Emit('click') click(){
    return true;
  }
}
