var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenproxModal', {
    staticClass: "owner-modal",
    attrs: {
      "smallerActionsPadding": true,
      "overflow": false,
      "isLoading": _vm.isLoading,
      "header": "Change owner",
      "description": "Choose which owner to assign",
      "confirmText": "Change",
      "cancelText": "Cancel"
    },
    on: {
      "close": _vm.close,
      "cancel": _vm.close,
      "confirm": _vm.changeOwner
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$owner, _vm$owner2, _vm$$v;

        return [_c('sygni-select', {
          staticClass: "owner-modal__select",
          attrs: {
            "searchable": true,
            "options": _vm.brokerOptions,
            "label": "",
            "placeholder": (_vm$owner = _vm.owner) !== null && _vm$owner !== void 0 && _vm$owner.ownerName ? "Current owner: ".concat((_vm$owner2 = _vm.owner) === null || _vm$owner2 === void 0 ? void 0 : _vm$owner2.ownerName) : 'Select owner',
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : _vm$$v.selectedOwner
          },
          model: {
            value: _vm.selectedOwner,
            callback: function callback($$v) {
              _vm.selectedOwner = $$v;
            },
            expression: "selectedOwner"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }