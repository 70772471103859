var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.reRender,
    staticClass: "dashboard",
    class: [{
      'dashboard--blocked': _vm.formOpened
    }]
  }, [_c('div', {
    staticClass: "dashboard__left"
  }, [_c('FundManagerCard', {
    attrs: {
      "fundManagerData": _vm.fundManagerData
    }
  }), _c('div', {
    staticClass: "dashboard__options"
  }, [_c('sygni-rounded-button', {
    staticClass: "gn-primary filled add-new-report",
    attrs: {
      "plus-icon": true
    },
    on: {
      "click": _vm.openForm
    }
  }, [_c('span', {
    staticClass: "hide-lg"
  }, [_vm._v("Add new")]), _vm._v(" "), _c('span', {
    staticClass: "show-lg"
  }, [_vm._v("Add new reporting")])])], 1), _c('sygni-container-title', {
    staticClass: "fund-title"
  }, [_vm._v(" Regulatory Reporting ")]), _c('div', {
    staticClass: "reports"
  }, _vm._l(_vm.groupedReports, function (reports, index) {
    return _c('RegRepCardNew', {
      key: "reports-".concat(index, "-").concat(_vm.reRenderReports),
      ref: "reportCard".concat(index),
      refInFor: true,
      attrs: {
        "openCardId": _vm.openCardId,
        "createdReport": _vm.createdReport,
        "reports": reports
      },
      on: {
        "editDraftReport": _vm.editDraftReport,
        "openReportPreview": _vm.openReportPreview
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "dashboard__right"
  }, [_c('sygni-container-title', [_vm._v(" Management Team")]), _c('management-team', {
    attrs: {
      "team": _vm.managementTeam
    }
  }), _c('sygni-container-title', [_vm._v(" Information")]), _c('fund-information-card', {
    attrs: {
      "legalEntity": _vm.fundManagerData
    }
  })], 1), _vm.formOpened ? _c('report-form', {
    ref: "reportForm",
    attrs: {
      "reports": _vm.groupedReports,
      "reportId": _vm.reportId,
      "reportStatus": _vm.reportStatus
    },
    on: {
      "closeForm": _vm.closeForm,
      "reportCreated": _vm.refreshReports,
      "editDraftReport": _vm.editDraftReport,
      "openReportPreview": _vm.openReportPreview,
      "reportDraftCreated": _vm.handleReportDraftCreation
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }