



































































import Vue from 'vue'
import Component from 'vue-class-component'
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import ParticipantsTable from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/components/ParticipantsTable.vue';
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import ParticipantModal from './ParticipantModal.vue';
import { Prop } from 'vue-property-decorator';
import { AutentiConfiguration, AutentiParticipant, AutentiTag } from '../store/types';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { maxValue } from 'vuelidate/lib/validators';

Component.registerHooks(['validations'])
@Component({
  components: { ParticipantModal, EditorComponent, ParticipantsTable, SygniRoundedButton, SygniRectButton, SygniContainerTitle, SygniLoader, SygniLinkButton, SygniToggleSwitch, SygniModal, GenproxModal, SygniMultiSelect, SygniSelect, SygniInput }
})
export default class AutentiForm extends Vue {
  @Prop({ default: 'single' }) mode: 'single' | 'global'
  @Prop({ default: null }) showSaveButton: boolean;
  @Prop({ default: null }) templateId?: string;
  @Prop({ default: false }) isDisabled: boolean;
  @Prop({ default: false }) hasAutenti: boolean;

  participantModal: { show: boolean, participantData: AutentiParticipant | null, mode: 'add' | 'edit' } = {
    show: false,
    mode: 'add',
    participantData: null
  }

  loadGlobalConfigModal: { show: boolean, isLoading: boolean, configurationIsActive: boolean, header: string, description: string, cancelText: string, confirmText: string } = {
    show: false,
    isLoading: false,
    configurationIsActive: false,
    header: '',
    description: '',
    cancelText: 'No',
    confirmText: 'Yes',
  }

  dictionaries: any = null;
  isLoading: boolean = false;
  autentiTemplates: any[] = [];
  selectedTags: string[] = [];

  deletingParticipantId: string = null
  maxCharactersLength: number = 5000
  charactersAmount: number = 0

  isParticipationPriorityDisabled: boolean = false
  configuration: AutentiConfiguration = {
    documentName: '',
    tags: [],
    signatureModel: null,
    language: null,
    messageToParticipants: '',
    active: false,
    participationPriority: false,
    participant: [],
    participants: [],
  }

  get isGlobal() {
    return this.mode === 'global'
  }

  get languages(): any[] {
    return this.dictionaries?.languages || []
  }

  get signatureModels(): any[] {
    return this.dictionaries?.signatureModels || []
  }

  get tags() {
    return this.dictionaries?.tags?.filter((el: AutentiTag) => el?.type?.toUpperCase() === 'ORGANIZATION_SHARED')?.map((el: AutentiTag) => {
      return { label: el?.description || '', value: el?.id }
    }) || []
  }

  checkApprovers(participants: any[]) {
    const hasApprovers = !!(participants?.filter((el: any) => el?.role?.toUpperCase() === 'APPROVER')?.length > 0)
    
    if (hasApprovers) {
      this.$set(this.configuration, 'participationPriority', true)
    }
    this.isParticipationPriorityDisabled = hasApprovers
  }

  commitFormUpdate() {
    this.charactersAmount = (this.$refs.editorEl as EditorComponent)?.charactersAmount || 0
    this.$emit('formUpdated')
  }

  async showGlobalConfigModal() {
    this.loadGlobalConfigModal.show = true
    this.loadGlobalConfigModal.isLoading = true
    try {
      const { active } = await this.$store.dispatch('templates/getAutentiGlobalConfig')
      this.loadGlobalConfigModal.configurationIsActive = active

      if (this.loadGlobalConfigModal?.configurationIsActive) {
        this.loadGlobalConfigModal.header = 'Are you sure?'
        this.loadGlobalConfigModal.description = 'This action will insert data from your global configuration and overwrite all of the fields and participants. Do you want to continue?'
        this.loadGlobalConfigModal.cancelText = 'No'
        this.loadGlobalConfigModal.confirmText = 'Yes'
      } else {
        this.loadGlobalConfigModal.header = 'Action unavailable'
        this.loadGlobalConfigModal.description = 'Global configuration is not active and data will not be inserted into current template. You can access global configuration by clicking an Autenti button available at Fundraising > Templates page.'
        this.loadGlobalConfigModal.cancelText = ''
        this.loadGlobalConfigModal.confirmText = 'OK'
      }
    } catch (e) {
      e;
    }

    this.loadGlobalConfigModal.isLoading = false
  }

  closeGlobalConfigModal() {
    this.loadGlobalConfigModal.show = false
    this.loadGlobalConfigModal.configurationIsActive = false
  }

  async useGlobalConfiguration() {
    if (!this.loadGlobalConfigModal?.configurationIsActive) {
      this.closeGlobalConfigModal()
      return false
    }
    
    this.isLoading = true
    
    try {
      if (this.loadGlobalConfigModal?.configurationIsActive) {
        this.closeGlobalConfigModal();
        await this.loadGlobalConfig();
      } else {
        await this.handleSubmit(true, this.templateId);
        this.closeGlobalConfigModal();
        if (!this.$v?.$error) {
          this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/autenti/settings`})
        }
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    
    this.commitFormUpdate()
    this.isLoading = false
  }

  hasPriority(role: string) {
    return role?.toUpperCase() === 'SIGNER' || role?.toUpperCase() === 'APPROVER'
  }

  handleParticipantModalAction(actionName: 'add' | 'edit', payload: any) {
    const participantsData: any = _.cloneDeep(this.configuration.participants)

    if (actionName === 'add') {
      payload.tempId = uuidv4()
      if (this.hasPriority(payload?.role)) {
        const indexToInsert = participantsData?.findLastIndex((el: AutentiParticipant) => this.hasPriority(el?.role))
        if (indexToInsert != -1) {
          participantsData.splice(indexToInsert + 1, 0, payload)
        } else {
          participantsData.unshift(payload)
        }
      } else {
        participantsData.push(payload)
      }
    } else if (actionName === 'edit') {
      const participantIndex: number = participantsData?.findIndex((item: AutentiParticipant) => (item?.id && item?.id === payload?.participantId) || (item?.tempId && item?.tempId === payload?.data?.tempId))

      if (participantIndex != -1) {
        participantsData[participantIndex] = { id: participantsData[participantIndex]?.id, ...payload?.data}
      }
    }
    
    const signers = [...participantsData]?.filter((el: any) => this.hasPriority(el.role))
    const rest = [...participantsData]?.filter((el: any) => !this.hasPriority(el.role))
    
    this.$set(this.configuration, 'participants', [...signers, ...rest])
    
    this.checkApprovers(this.configuration?.participants)
    this.confirmParticipantModal()
  }

  handleParticipantsUpdate(items: AutentiParticipant[]) {
    this.configuration.participants = items
    this.checkApprovers(this.configuration?.participants)
    this.commitFormUpdate()
  }

  openDeletingParticipantModal(id: string) {
    this.deletingParticipantId = id
  }

  closeDeleteConfirmationModal() {
    this.deletingParticipantId = null
  }

  confirmParticipantModal() {
    this.closeParticipantModal();
    this.commitFormUpdate()
  }

  openParticipantModal(mode: 'add' | 'edit' = 'add', participantData: AutentiParticipant = null) {
    this.participantModal.mode = mode
    this.participantModal.participantData = participantData
    this.participantModal.show = true
  }

  closeParticipantModal() {
    this.participantModal.participantData = null
    this.participantModal.mode = 'add'
    this.participantModal.show = false
  }

  handleMessageUpdate(content: string) {
    this.configuration.messageToParticipants = content
    this.commitFormUpdate()
  }

  updateTags(tags: string[]) {
    const selectedTags = tags?.map((id: string) => {
      return this.dictionaries?.tags?.find((tag: AutentiTag) => tag.id === id)
    })
    this.configuration.tags = selectedTags
    this.commitFormUpdate()
  }

  toggleAllTags() {
    if (this.selectedTags?.length < this.tags?.length) {
      const tags = this.tags?.map((el: any) => el.value);
      this.selectedTags = tags;
      this.updateTags(tags);
    } else {
      this.selectedTags = [];
      this.updateTags([]);
    }
  }

  async loadDictionaries() {
    this.dictionaries = await this.$store.dispatch('templates/getAutentiDictionaries')

    if (this.dictionaries?.signatureModels && this.dictionaries?.signatureModels?.length == 1) {
      this.configuration.signatureModel = this.dictionaries?.signatureModels[0]?.value || null
    }

    if (this.dictionaries?.languages) {
      const defaultLanguage = this.dictionaries?.languages?.find((el: any) => el.value?.toLowerCase() === 'pl')
      this.configuration.language = defaultLanguage.value
    }
  }

  async loadConfig() {
    if (this.mode === 'global') {
      await this.loadGlobalConfig()
    }

    if (this.mode === 'single' && this.templateId) {
      await this.loadDocumentConfig()
    }
  }

  async loadDocumentConfig() {
    const singleConfig = await this.$store.dispatch('templates/getAutentiDocumentConfig', this.templateId)

    if (singleConfig?.active !== undefined) {
      if (singleConfig?.tags?.length) {
        this.selectedTags = singleConfig?.tags?.map((tag: AutentiTag) => tag.id)
      }

      this.configuration = { ...singleConfig }

      if (this.configuration?.participants === undefined) {
        this.configuration.participants = []
      }

      const signers = [...this.configuration.participants, ...this.configuration.participant]?.filter((el: any) => this.hasPriority(el.role))
      const rest = [...this.configuration.participants, ...this.configuration.participant]?.filter((el: any) => !this.hasPriority(el.role))

      this.configuration.participants = [...signers, ...rest]
    }
  }

  async loadGlobalConfig() {
    const globalConfig = await this.$store.dispatch('templates/getAutentiGlobalConfig')

    if (globalConfig?.active !== undefined) {
      if (globalConfig?.tags?.length) {
        this.selectedTags = globalConfig?.tags?.map((tag: AutentiTag) => tag.id)
      }

      this.configuration = { ...globalConfig }

      if (this.configuration?.participants === undefined) {
        this.configuration.participants = []
      }

      const signers = [...this.configuration.participants, ...this.configuration.participant]?.filter((el: any) => this.hasPriority(el.role))
      const rest = [...this.configuration.participants, ...this.configuration.participant]?.filter((el: any) => !this.hasPriority(el.role))

      this.configuration.participants = [...signers, ...rest]
    }
  }

  async deleteParticipant() {
    if (this.deletingParticipantId) {
      this.isLoading = true
      try {
        const participantsData = _.cloneDeep(this.configuration.participants)
        const participantIndex: number = participantsData?.findIndex((item: AutentiParticipant) => item?.id === this.deletingParticipantId)

        if (participantIndex != -1) {
          participantsData.splice(participantIndex, 1)
        }

        this.$set(this.configuration, 'participants', participantsData)

        // const payload = this.mode === 'single' ? { documentId: this.templateId, participantId: this.deletingParticipantId } : this.deletingParticipantId
        // const actionName = this.mode === 'global' ? 'templates/deleteGlobalParticipant' : 'templates/deleteDocumentParticipant'
        // await this.$store.dispatch(actionName, payload)
        // await this.loadConfig();

        this.checkApprovers(this.configuration?.participants)
        
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Participant deleted'
        })

      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isLoading = false
    }

    this.closeDeleteConfirmationModal()
    this.commitFormUpdate()
  }

  getRequestPayload(templateId: string) {
    const payload: AutentiConfiguration = _.cloneDeep(this.configuration);

    if (payload.participants?.length) {
      payload.participants = payload.participants?.map((participant: AutentiParticipant, index: number) => {
        participant.priority = index + 1
        delete participant?.tempId

        return participant
      })
    }

    if (!this.isGlobal) {
      return {
        templateId,
        data: payload
      }
    }

    return payload
  }

  async handleSubmit(notify: boolean = false, templateId: string = null) {
    this.$v?.$touch();

    if (!this.$v?.$error) {
      this.isLoading = true;

      try {
        const action = this.isGlobal ? 'templates/saveAutentiGlobalConfig' : 'templates/saveAutentiDocumentConfig'
        const requestPayload = this.getRequestPayload(templateId)
        await this.$store.dispatch(action, requestPayload)
        await this.loadConfig()

        if (notify) {
          this.$notify({
            duration: 2500,
            type: 'success',
            title: 'Success',
            text: 'Configuration saved!'
          })
        }

      } catch (e) {
        if (notify) {
          const errorMessage = this.$options.filters.errorHandler(e)
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: errorMessage
          })
        }
      }

      this.isLoading = false;
    }
  }

  async handleSubmitPromise(notify: boolean = false, templateId: string = null) {
    this.$v?.$touch();

    if (!this.$v?.$error) {
      this.isLoading = true;

      const action = this.isGlobal ? 'templates/saveAutentiGlobalConfig' : 'templates/saveAutentiDocumentConfig'
      const requestPayload = this.getRequestPayload(templateId)
      await this.$store.dispatch(action, requestPayload)
      await this.loadConfig()

      if (notify) {
        this.$notify({
          duration: 2500,
          type: 'success',
          title: 'Success',
          text: 'Configuration saved!'
        })
      }

      this.isLoading = false;
    }
  }

  async mounted() {
    this.isLoading = true
    
    try {
      if (this.hasAutenti) await this.loadDictionaries()
      await this.loadConfig()
      this.checkApprovers(this.configuration?.participants)
      
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  checkValidationStatus() {
    this.$v?.$touch()
    return this.$v?.$error
  }

  get validationObject() {
    if (this.configuration?.active) {
      return {
        charactersAmount: {
          maxValue: maxValue(this.maxCharactersLength)
        },
        configuration: {
          documentName: !this.isGlobal ? { required } : {},
          tags: !this.isGlobal ? { required } : {},
          signatureModel: { required },
          language: { required },
        }
      }
    }

    return {}
  }

  validations() {
    return this.validationObject
  }
}
