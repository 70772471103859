































































































import Vue from 'vue'
import Component from 'vue-class-component'
import GenproxModal from '@/components/layout/GenproxModal.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { ViewType } from '../store/types';
import { BSpinner } from 'bootstrap-vue';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: { GenproxModal, SygniSelect, SygniCheckbox, SygniDatePicker, SygniRadio, SygniInput, SygniLinkButton, SygniRoundedButton, BSpinner },
})
export default class BookingModal extends Vue {
  @Prop({ default: [] }) selectedItems: any[]
  @Prop({ default: false }) useName: boolean
  viewType: ViewType = 'synchronise'
  radioBtnValues: any = {}
  reRender: number = 0
  counterpartiesData: any = {}
  counterpartiesValues: any = {}
  currentInvestorIndex: number = 0
  accountingDate: string = null
  counterpartiesInfo: any = {}
  hasError: boolean = false
  isLoading: boolean = true
  bookingResults: any = {}

  get currentInvestorId() {
    return Object.keys(this.counterpartiesData)[this.currentInvestorIndex]
  }

  get filteredSelectedItems() {
    return this.selectedItems?.filter((el: any) => el.investmentClientId === this.currentInvestorId)
  }

  get investorsAmount() {
    return Object.keys(this.counterpartiesData)?.length 
  }

  get isOppositeAccountDisabled() {
    return !(this.viewType === 'generateOppositeAccounts')
  }

  get showOppositeAccountField() {
    return this.viewType === 'generateOppositeAccounts' || this.viewType === 'summary'
  }

  get modalHeader() {
    switch (this.viewType) {
      case "generateOppositeAccounts":
        return 'Generate opposite accounts'
      case "summary":
        return `Book transaction${this.selectedItems?.length > 1 ? 's' : ''} summary`
      case "result":
        return "Booking result"
      default:
        return `Synchronise investor (${this.currentInvestorIndex + 1}/${this.investorsAmount})`
    }
  }

  get modalDescription() {
    const successfullyUploadedItemsAmount: number = Object.values(this.bookingResults)?.filter((el: any) => el.status === 'fulfilled')?.length || 0
    return this.viewType === 'result' ? `${successfullyUploadedItemsAmount}/${this.selectedItems?.length} transactions were successfully booked.` : ''
  }

  get isDisabled() {
    return !(this.viewType === 'synchronise')
  }

  get cancelText() {
    switch (this.viewType) {
      case "synchronise":
      case "generateOppositeAccounts":
        return 'Close'
      case "summary":
        return 'Go back'
      default:
        return ""
    }
  }

  get confirmText() {
    switch (this.viewType) {
      case "synchronise":
        // Go next or Synchronise if there was an error
        return !this.hasError ? 'Go next' : 'Synchronise'
      case "generateOppositeAccounts":
        return "Go to summary"
      case "summary":
        return "Book transactions"
      case "result":
        return "Close"
      default:
        return "OK"
    }
  }

  setOppositeAccount(value: string, item: any, ref?: string) {
    if (!this.isOppositeAccountDisabled) {
      item.oppositeAccount = value
      this.onDebouncedCheckOppositeAccount(item, value)
      if (ref) {
        (this.$refs[ref] as any)[0]?.$el?.querySelector('input[type="text"]')?.focus()
      }
    }
  }

  isRadioBtnDisabled(items: any[]) {
    return items?.length === 0 ? true : false
  }

  get isStepValidated() {
    if (this.viewType === 'synchronise' || this.viewType === 'summary' || this.viewType === 'result') {
      return true
    } 
    
    if (this.viewType === 'generateOppositeAccounts') {
      return !!(this.selectedItems.filter((el: any) => !el?.oppositeAccountExists)?.length === 0)
    }

    return false
  }

  closeBookingModal() {
    this.counterpartiesInfo = {}
    this.counterpartiesData = {}
    this.counterpartiesValues = {}
    this.bookingResults = {}
    this.$emit('close')
    this.isLoading = true
    this.viewType = 'synchronise'
  }

  cancelBookingModal() {
    this.counterpartiesInfo = {}
    this.counterpartiesData = {}
    this.counterpartiesValues = {}
    this.bookingResults = {}
    this.$emit('cancel')
    this.isLoading = true
    this.viewType = 'synchronise'
  }

  selectRadioBtn(id: string, value: boolean) {
    this.$nextTick(() => {
      this.radioBtnValues[id] = value
      this.reRender++
    })
  }

  async onDebouncedCheckOppositeAccount(item: any, oppositeAccount: string) {
    await this.checkOppositeAccount(item, oppositeAccount)
  }

  created() {
    this.onDebouncedCheckOppositeAccount = _.debounce(this.onDebouncedCheckOppositeAccount, 400)
  }

  async checkOppositeAccount(item: any, oppositeAccount: string, updateLoadingStates: boolean = true) {
    if (oppositeAccount) {
      if (updateLoadingStates) {
        this.$set(item, 'isLoading', true)
      }
      try {
        // update check only if opposite account generated
        const check = await this.$store.dispatch('statements/checkOppositeAccount', { oppositeAccount, accountingPeriod: this.accountingDate })
        this.$set(item, 'oppositeAccountExists', check)
      } catch (e) {
        if (updateLoadingStates) {
          this.$set(item, 'isLoading', false)
        }
        e;
      }
    }

    if (updateLoadingStates) {
      this.$set(item, 'isLoading', false)
    }
  }

  setItemsIsLoading(items: any[], value: boolean) {
    return items?.map((item: any) => {
      this.$set(item, 'isLoading', value)
      return item
    })
  }

  async addAllOppositeAccounts() {
    if (!this.isLoading) {
      this.isLoading = true
      const items: any[] = this.selectedItems?.filter((el: any) => !el.oppositeAccountExists)
      this.setItemsIsLoading(items, true)

      try {
        const addOppositeAccountPromises: any[] = []
        const checkOppositeAccountPromises: any[] = []
  
        items?.forEach(async (item: any) => {
          const payload: { oppositeAccount: string, accountingPeriod: string, object: { objectType: string, objectId: string } } = {
            oppositeAccount: item.oppositeAccount,
            accountingPeriod: this.accountingDate,
            object: {
              objectType: item.matchedObjectType,
              objectId: item.matchedObjectId
            }
          }
          addOppositeAccountPromises.push(this.$store.dispatch('statements/addOppositeAccount', payload))
        })
        
        await Promise.all(addOppositeAccountPromises)

        items?.forEach(async (item: any) => {
          checkOppositeAccountPromises.push(this.checkOppositeAccount(item, item.oppositeAccount, false))
        })
        
        await Promise.all(checkOppositeAccountPromises)

        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Opposite accounts added successfully.'
        })
      
      } catch (e) {
        this.$notify({
          duration: 5000,
          type: 'error',
          title: 'Error',
          text: 'Something went wrong and not every opposite account have been added. Try again later.'
        })
      }
  
      this.setItemsIsLoading(items, false)
      this.isLoading = false
    }
  }

  async addOppositeAccount(item: any) {
    if (!item?.oppositeAccountExists) {
      this.$set(item, 'isLoading', true)

      try {
        const payload: { oppositeAccount: string, accountingPeriod: string, object: { objectType: string, objectId: string } } = {
          oppositeAccount: item.oppositeAccount,
          accountingPeriod: this.accountingDate,
          object: {
            objectType: item.matchedObjectType,
            objectId: item.matchedObjectId
          }
        } 
        await this.$store.dispatch('statements/addOppositeAccount', payload)
        await this.checkOppositeAccount(item, item.oppositeAccount, false)

        if (item.oppositeAccountExists) {
          this.$notify({
            duration: 3000,
            type: 'success',
            title: 'Success',
            text: 'Opposite account added.'
          })
        } else {
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: 'Something went wrong. Try again later.'
          })
        }

      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }

      this.$set(item, 'isLoading', false)
    }
  }

  async changeViewType(type: ViewType) {
    if (type === 'generateOppositeAccounts') {
      this.isLoading = true

      if (!this.accountingDate) {
        this.accountingDate = moment().subtract(1, 'year').year()?.toString()
      }

      this.selectedItems?.filter((el: any) => !el?.oppositeAccount).map(async (el: any) => {
        // default value
        this.$set(el, 'oppositeAccountExists', false)

        const suggestedOppositeAccount = await this.$store.dispatch('statements/generateOppositeAccount', { transactionId: el?.id, objectType: el?.matchedObjectType, objectId: el?.matchedObjectId })

        if (suggestedOppositeAccount) {
          this.$set(el, 'suggestedOppositeAccount', suggestedOppositeAccount)
          this.$set(el, 'oppositeAccount', suggestedOppositeAccount)

          await this.checkOppositeAccount(el, suggestedOppositeAccount)
        }

        return el
      })
        
      this.isLoading = false
    } else if (type === 'result') {
      this.isLoading = true

      const requestIds: string[] = []
      const promises: any[] = []
      for (const transaction of this.selectedItems) {
        const payload = { transactionId: transaction.id, accountingDate: this.accountingDate, oppositeAccount: transaction.oppositeAccount }
        
        requestIds.push(transaction.id)
        promises.push(this.$store.dispatch('statements/bookTransaction', payload))
      }

      const resp: any = await Promise.allSettled(promises)

      resp.forEach((result: any, index: number) => {
        this.$set(this.bookingResults, requestIds[index], {
          status: result.status,
          isLoading: false,
        })
      })

      this.isLoading = false
    }

    this.hasError = false
    this.viewType = type;
    (this.$refs.sygniModal as GenproxModal).scrollToTop()
  }

  async bookTransaction(transactionItem: { status: 'fulfilled' | 'rejected', isLoading: boolean }, transactionId: string) {
    if (transactionItem.status === 'rejected') {
      try {
        const transaction: any = this.selectedItems.find((el: any) => el.id === transactionId)
        this.$set(transactionItem, 'isLoading', true)

        const payload: { transactionId: string, accountingDate: string, oppositeAccount: string } = { transactionId, accountingDate: this.accountingDate, oppositeAccount: transaction.oppositeAccount }
        
        await this.$store.dispatch('statements/bookTransaction', payload)
        
        this.$set(transactionItem, 'status', 'fulfilled')
      } catch (e) {
        this.$set(transactionItem, 'status', 'rejected')
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 3000,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
        e;
      }
      this.$set(transactionItem, 'isLoading', false)
    }
  }

  handleCloseAction() {
    this.closeBookingModal()
    this.changeViewType('synchronise')
  }

  handleCancelAction() {
    if (this.viewType === 'synchronise' || this.viewType === 'generateOppositeAccounts' || this.viewType === 'result') {
      this.cancelBookingModal()
    } else if (this.viewType === 'summary') {
      this.changeViewType('generateOppositeAccounts')
    }
  }

  displayErrorMessage(e: any) {
    const errorMessage = this.$options.filters.errorHandler(e)
    this.$notify({
      duration: 2500,
      type: 'error',
      title: 'Error',
      text: errorMessage
    })
  }

  async goNext(viewType: ViewType) {
    if (viewType === 'synchronise') {
      const addNewInvestor = !this.radioBtnValues[this.currentInvestorId]
      const lastInvestor = this.currentInvestorIndex + 1 >= this.investorsAmount
      await this.synchroniseInvestorActions(addNewInvestor, lastInvestor)
    } else if (viewType === 'generateOppositeAccounts') {
      this.changeViewType('summary')
    } else if (viewType === 'summary') {
      this.changeViewType('result')
    } else if (viewType === 'result') {
      this.handleCloseAction()
    }
  }

  async synchroniseInvestorActions(addNewInvestor: boolean, lastInvestor: boolean) {
    this.isLoading = true
    if (addNewInvestor || this.hasError) {
      // add new counterparty for investment client

      if (!this.hasError) {
        try {
          await this.$store.dispatch('statements/addNewCounterpartyToInvestmentClient', this.currentInvestorId)
        } catch (e) {
          this.displayErrorMessage(e)
          return
        }
      }

      // synchronise new counterparty with optima
      try {
        await this.$store.dispatch('statements/syncNewCounterpartyToInvestmentClient', this.currentInvestorId)
        
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Investor synchronised successfully.'
        })
        
        if (lastInvestor) { 
          this.changeViewType('generateOppositeAccounts') 
        } else {
          this.goToNextInvestor()
        }
      } catch (e) {
        // if error then stay in view but display error message and hide radio btns
        this.hasError = true
        this.displayErrorMessage(e)
      }
    } else { // if selecting existing investor from optima
      // approve new counterparty for investment client
      try {
        await this.$store.dispatch('statements/approveNewCounterpartyForInvestmentClient', { investmentClientId: this.currentInvestorId, counterpartyId: this.counterpartiesValues[this.currentInvestorId] })

        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Investor synchronised successfully.'
        })

        if (lastInvestor) {
          this.changeViewType('generateOppositeAccounts')
        } else {
          this.goToNextInvestor()
        }
      } catch (e) {
        this.displayErrorMessage(e)
      }
    }
    this.isLoading = false
  }

  goToNextInvestor() {
    this.hasError = false
    this.currentInvestorIndex++
  }

  handleConfirmAction() {
    this.goNext(this.viewType)
  }

  async getCounterparties(investmentClientIds: { investmentClientId: string, data: any }[]) {
    if (!investmentClientIds?.length) {
      this.isLoading = false
      this.changeViewType('generateOppositeAccounts')
      return
    }

    try {
      investmentClientIds?.forEach(async (item: any) => {
        const resp = await this.$store.dispatch('statements/getInvestorCounterpartyList', item.investmentClientId)
        // map resp to have select option structure
        this.$set(this.counterpartiesInfo, item.investmentClientId, item.data)
        this.$set(this.counterpartiesData, item.investmentClientId, resp?.map((el: any) => {
          return { label: el.name, value: el.id }
        }))
        this.$set(this.counterpartiesValues, item.investmentClientId, null)
      })
  
      this.$nextTick(() => {
        this.isLoading = false
        this.reRender++
      })
    } catch (e) {
      this.displayErrorMessage(e)
      this.closeBookingModal()
    }
  }

  @Watch('accountingDate', { immediate: true }) async onAccountingDateUpdate() {
    for (const item of this.selectedItems) {
      await this.checkOppositeAccount(item, item.oppositeAccount)
    }
  }

  @Watch('currentInvestorId', { immediate: true }) onCurrentInvestorIdUpdate() {
    const counterpartyInfo = this.counterpartiesInfo[this.currentInvestorId]

    if (counterpartyInfo && counterpartyInfo?.counterpartyId && !counterpartyInfo?.code && !counterpartyInfo?.externalId) {
      this.hasError = true
    }
  }

  @Watch('counterpartiesData', { deep: true, immediate: true }) async onCounterpartiesDataUpdate() {
    Object.keys(this.counterpartiesData)?.forEach(async (id: string) => {
      if (this.counterpartiesData[id]?.length === 1) {
        this.$set(this.radioBtnValues, id, true)
        this.$set(this.counterpartiesValues, id, this.counterpartiesData[id][0]?.value)
      } else {
        this.$set(this.radioBtnValues, id, false)
      }
    })
  }
}
