var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "funds-list"
  }, [_c('div', {
    staticClass: "funds-list-wrapper"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "busy": _vm.isBusy
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('user-presentation', {
          staticClass: "funds-list__user-presentation",
          attrs: {
            "name": rowData.item.name,
            "description": rowData.item.description,
            "image": rowData.item.logo
          }
        })];
      }
    }, {
      key: "cell(dateOfDiscontinuationOfAifManagement)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "text-center nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData === null || rowData === void 0 ? void 0 : (_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.dateOfDiscontinuationOfAifManagement)))])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(rowData) {
        return [rowData.item.actions.length > 0 ? _c('div', {
          staticClass: "funds-list__actions"
        }, _vm._l(rowData.item.actions, function (action) {
          return _c('sygni-rounded-button', {
            key: action,
            staticClass: "outline disabled funds-list__action-button",
            attrs: {
              "arrow-icon": true
            },
            on: {
              "click": function click($event) {
                return _vm.performAction(action, rowData.item);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t(action)))]);
        }), 1) : _vm._e()];
      }
    }])
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }