


















import Vue from 'vue';
import { ResizeObserver } from 'vue-resize'
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRoundedButton, ResizeObserver },
})
export default class PostBox extends Vue {
  @Prop({default: false}) featured: boolean;
  @Prop({}) details: any;

  maxLines: number = 0;

  get detailsTag() {
    return this.details.tags.join(', ');
  }

  get detailsTagsExcerpt() {
    if(this.detailsTag.length > 40) return this.detailsTag.slice(0, 40) + ' ...'

    return this.detailsTag;
  }

  updateTextOverflow(data: any) {
    if (this.featured) {
      const lineHeight = window?.matchMedia("(min-width: 1600px)")?.matches ? 31 : 23;
      const { height } = data
      const lines = Math.floor(height / lineHeight)

      this.maxLines = (lines > 1) ? lines : 0
    }
  }
}
