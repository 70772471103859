


















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniDropdownItem from "@/components/sygni-dropdown/SygniDropdownItem.vue";
import SygniDropdown from "@/components/sygni-dropdown/SygniDropdown.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue"
import UserPresentation from "@/components/UserPresentation.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniCart from "@/components/layout/SygniCard.vue";
import SygniLinkButton from "@/components/buttons/SygniLinkButton.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SimplePostBox from '@/modules/genprox/modules/insights/components/SimplePostBox.vue';
import {
  emptyInvestmentClient,
  GetProduct,
  InvestmentClient
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {mapState} from "vuex";
import {BSpinner} from "bootstrap-vue";
import ProductCard from "@/modules/genprox/modules/fund/modules/fundraising/components/product/ProductCard.vue";
import FreeTools from "@/modules/genprox/components/welcome/FreeTools.vue";
import {REDIRECTED_PATH} from "@/modules/genprox/genprox.routing";
import Packages from "@/modules/genprox/components/welcome/Packages.vue";
import {Contexts, LegalEntity, UserContext} from "@/modules/genprox/models/User";
import AuthorizeData from "@/modules/genprox/components/welcome/AuthorizeData.vue";
import {WelcomeTextData} from "@/modules/genprox/store/types";
import {ProductSummaryData} from "@/modules/genprox/modules/fund/modules/fundraising/models/ProductSummaryData";
import { InsightItem } from '../modules/insights/store/types';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import { EventBus } from '@/main';

@Component({
  components: {
    AuthorizeData,
    Packages,
    FreeTools,
    ProductCard,
    SygniContainerTitle,
    SygniRoundedButton, BSpinner,
    SygniCart, SygniLinkButton, SygniArrowButton, UserPresentation, SygniDropdownItem, SygniDropdown, SygniSquareButton, SimplePostBox, SygniLoader },
    computed:{
      ...mapState('genprox', {
        activeUserContext: (state: any) => state.activeUserData.context,
        welcomeText: (state: any) => state.welcomeText,
        authorized: (state: any) => state.activeUserData?.verified,
      }),
      ...mapState('investors', {
        investorData: (state: any) => state.newInvestmentClient,
      }),
      ...mapState('auth', {
        contextId: (state: any) => state.jwtTokenData?.context_id,
      }),
    }
})
export default class Welcome extends Vue {

  readonly Contexts = Contexts;
  activeUserContext!: UserContext;
  investorData!: InvestmentClient;
  contextId!: string;
  authorized!: boolean;
  welcomeText: WelcomeTextData;
  arePostsLoading: boolean = false;
  postTag: string = 'Get%20started%20with%20Fundequate';
  posts: Array<InsightItem> = [];

  product: ProductSummaryData = {} as any;
  loading: boolean = true;
  xmlLoading: boolean = false;

  company: LegalEntity = null;

  async loadPosts() {
    this.arePostsLoading = true;
    const posts = await this.$store.dispatch('insights/getInsights', `&offset=0&filters[tags][0][value]=${this.postTag}&filters[tags][0][operator]=contain`);
    this.arePostsLoading = false;
    this.posts = posts;
  }

  async beforeMount() {
    await this.loadPosts();
  }

  async handleMounted() {
    this.redirectToRouteWithIdIfProvided();
    if (localStorage.getItem(REDIRECTED_PATH)) {
      this.$router.push({ path: localStorage.getItem(REDIRECTED_PATH) });
      localStorage.removeItem(REDIRECTED_PATH);
    }
    await this.getUnacceptedProduct();
    this.loading = false;
    if (this.product?.id || this.$route.params?.id) {
      const id = this.product.id ? this.product.id : this.$route.params.id;

      if (this.activeUserContext.context === Contexts.privateInvestor) {
        const promises = [
          this.$store.dispatch('investors/getProduct', { type: "for-investment-client", id: id } as GetProduct),
          this.$store.dispatch('investors/getDataForAuthorize', id)
        ];
  
        const response = await Promise.all(promises);
  
        this.product = response[0].data;
        const currentInvestor: InvestmentClient = response[1];
  
        this.$store.commit('investors/setNewInvestmentClient', currentInvestor);
      }

      this.getProductCompanyData();
    }
  }

  async mounted() {
    await this.handleMounted();
  }

  redirectToRouteWithIdIfProvided() {
    const registerProductId = localStorage.getItem('registerProductId');
    if(registerProductId && !this.$route.params.id){
      this.$router.push(`welcome/${registerProductId}`);
    }
  }

  async getUnacceptedProduct(): Promise<void> {
    if(!this.$route.params?.id && this.activeUserContext.context === Contexts.privateInvestor) {
      this.product = (await this.$store.dispatch('investors/getProductForAccept')).data;
    }
  }

  async getProductCompanyData(): Promise<void> {
    this.company = await this.$store.dispatch('genprox/getLeContextData', this.product.legalEntityId);
  }

  get freeToolsAvailable() {
    return this.activeUserContext.context === Contexts.fundManager || this.activeUserContext.context === Contexts.fundManagerPrivate || this.activeUserContext.context === Contexts.fund
  }

  setLoading(val: boolean) {
    this.xmlLoading = val;
  }

  goToAuthorize(): void {
    this.$store.commit('investors/setNewInvestor', {...emptyInvestmentClient, ...this.investorData, type: 'existing'});
    this.$router.push({name: 'verifyInvestor'});
  }

  acceptProduct(): void {
    this.$router.push({name: 'acceptProductById', params: { id: this.product.id }});
  }

  async updated() {
    EventBus.$off('contextChanged');
    EventBus.$once('contextChanged', async () => {
      await this.handleMounted();
    })
  }
}
