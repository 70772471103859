





























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import { Prop } from 'vue-property-decorator';
import AuthModuleBoilerplate from './AuthModuleBoilerplate.vue';

Component.registerHooks(['validations'])
@Component({
  components: { AuthModuleBoilerplate, SygniRectButton, SygniInput, SygniLinkButton }
})
export default class FormBoilerplate extends Vue {
  @Prop({ default: null }) formTitle?: string
  @Prop({ default: 'normal' }) size?: 'normal' | 'wide'
}
