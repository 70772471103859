var render = function () {
  var _vm$bulkDetailsData, _vm$bulkDetailsData2, _vm$bulkDetailsData2$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payments-table"
  }, [_c('div', {
    staticClass: "payments-table__inner"
  }, [_vm.showBulkOptions ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedRowsLength > 0,
      expression: "selectedRowsLength > 0"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": _vm.isPaymentStatusLoading || _vm.isAccountingDateLoading || _vm.isCsvExporting
    }
  }), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedRowsLength))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline disabled",
    on: {
      "click": _vm.clearTableSelection
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    class: ['dropdown', _vm.accountingDateOpened ? 'dropdown--opened' : '']
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('div', {
    staticClass: "dropdown__inner"
  }, [_vm.isPaymentManager ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": _vm.openBulkDetailsModal
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Update details")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('Fund'),
      "alt": "Update details"
    }
  })])]) : _vm._e(), _vm.isMarketplaceAdmin || _vm.isMarketplaceHead || _vm.isMarketplaceAccountant ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": _vm.exportCsv
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Export to CSV")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOWNLOAD'),
      "alt": "Download CSV"
    }
  })])]) : _vm._e(), _vm.isPaymentManager ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.enableBankAccountModal();
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Generate payment package")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('WALLET'),
      "alt": "Generate payment package"
    }
  })])]) : _vm._e(), !_vm.isPaymentManager && !(_vm.isMarketplaceAdmin || _vm.isMarketplaceHead || _vm.isMarketplaceAccountant) ? _c('div', {
    staticClass: "dropdown__option"
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("No actions available")])]) : _vm._e()])])], 1)])], 1) : _vm._e(), _c('b-table', _vm._g(_vm._b({
    ref: "paymentsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFieldsComputed,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "show-empty": true,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData === null || rowData === void 0 ? void 0 : rowData.index], rowData === null || rowData === void 0 ? void 0 : rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.bulkRows[rowData.index].value,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkRows[rowData.index], "value", $$v);
            },
            expression: "bulkRows[rowData.index].value"
          }
        })], 1)];
      }
    }, {
      key: "cell(campaignName)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item2;

        return [_c('div', {
          class: [rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.campaignId ? 'code' : ''],
          on: {
            "click": function click($event) {
              return _vm.goToCampaign(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s((_rowData$item2 = rowData.item) !== null && _rowData$item2 !== void 0 && _rowData$item2.campaignName ? rowData.item.campaignName : '') + " ")])];
      }
    }, {
      key: "cell(productCode)",
      fn: function fn(rowData) {
        var _rowData$item3;

        return [_c('div', {
          staticClass: "code",
          on: {
            "click": function click($event) {
              return _vm.goToProductSummary(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s((_rowData$item3 = rowData.item) !== null && _rowData$item3 !== void 0 && _rowData$item3.productCode ? rowData.item.productCode : 'NONE') + " ")])];
      }
    }, {
      key: "cell(investmentClient)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "investor-name"
        }, [_c('span', {
          staticClass: "text-nowrap",
          on: {
            "click": function click($event) {
              return _vm.goToProfile(rowData.item);
            }
          }
        }, [_vm._v(_vm._s(rowData.item.investmentClient))])])];
      }
    }, {
      key: "cell(paymentBookDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentBookDate)))])];
      }
    }, {
      key: "cell(repaymentDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.repaymentDate)))])];
      }
    }, {
      key: "cell(interestPeriodFrom)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.interestPeriodFrom)))])];
      }
    }, {
      key: "cell(interestPeriodTo)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.interestPeriodTo)))])];
      }
    }, {
      key: "cell(productType)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.productType)) + " ")];
      }
    }, {
      key: "cell(paymentType)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("capitalizeFirstLetter")(rowData.item.paymentType)) + " ")];
      }
    }, {
      key: "cell(interestAmountNet)",
      fn: function fn(rowData) {
        var _rowData$item4;

        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.interestAmountNet)) + " " + _vm._s(rowData !== null && rowData !== void 0 && (_rowData$item4 = rowData.item) !== null && _rowData$item4 !== void 0 && _rowData$item4.currency ? rowData.item.currency : 'PLN') + " ")])];
      }
    }, {
      key: "cell(interestAmountGross)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.interestAmountGross)) + " " + _vm._s(rowData !== null && rowData !== void 0 && (_rowData$item5 = rowData.item) !== null && _rowData$item5 !== void 0 && _rowData$item5.currency ? rowData.item.currency : 'PLN') + " ")])];
      }
    }, {
      key: "cell(interestTax)",
      fn: function fn(rowData) {
        var _rowData$item6;

        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.interestTax)) + " " + _vm._s(rowData !== null && rowData !== void 0 && (_rowData$item6 = rowData.item) !== null && _rowData$item6 !== void 0 && _rowData$item6.currency ? rowData.item.currency : 'PLN') + " ")])];
      }
    }, {
      key: "cell(paymentStatus)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "filled round ".concat(_vm.statusClass(rowData.item.paymentStatus)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm._f("capitalizeFirstLetter")(rowData.item.paymentStatus)) + " ")])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "action"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.isPaymentManager && !_vm.isMarketplaceAccountant ? 'Edit details' : 'Show details',
            expression: "isPaymentManager && !isMarketplaceAccountant ? 'Edit details' : 'Show details'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el",
          on: {
            "click": function click($event) {
              return _vm.openDetailsModal(rowData.item.id);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")(_vm.isPaymentManager && !_vm.isMarketplaceAccountant ? 'EDIT' : 'Fund')
          }
        })])]), _vm.isPaymentManager ? _c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.enableBankAccountModal(rowData.item);
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Generate payment package',
            expression: "'Generate payment package'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('WALLET')
          }
        })])]) : _vm._e()])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("There are no payments on your list")])];
      },
      proxy: true
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3, _data$fields4, _data$fields5, _data$fields6;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'productCode' ? _c('div', [_c('p', [_vm._v("Total payments:")])]) : _vm._e(), ((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'investmentClient' ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'paymentType' ? _c('div', [_c('p', [_vm._v("Total amount:")])]) : _vm._e(), ((_data$fields4 = data.fields[i - 1]) === null || _data$fields4 === void 0 ? void 0 : _data$fields4.key) == 'interestAmountNet' ? _c('div', {
            staticClass: "text-right"
          }, _vm._l(Object.entries(_vm.netTotals), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(key))]);
          }), 0) : _vm._e(), ((_data$fields5 = data.fields[i - 1]) === null || _data$fields5 === void 0 ? void 0 : _data$fields5.key) === 'interestAmountGross' ? _c('div', {
            staticClass: "text-right"
          }, _vm._l(Object.entries(_vm.grossTotals), function (_ref2) {
            var key = _ref2[0],
                value = _ref2[1];
            return _c('p', {
              key: "gross-".concat(key)
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(key))]);
          }), 0) : _vm._e(), ((_data$fields6 = data.fields[i - 1]) === null || _data$fields6 === void 0 ? void 0 : _data$fields6.key) === 'interestTax' ? _c('div', {
            staticClass: "text-right"
          }, _vm._l(Object.entries(_vm.taxTotals), function (_ref3) {
            var key = _ref3[0],
                value = _ref3[1];
            return _c('p', {
              key: "gross-".concat(key)
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(key))]);
          }), 0) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _vm.showBankAccountModal ? _c('GenproxModal', {
    attrs: {
      "isLoading": _vm.isPaymentPackageLoading,
      "header": "Payment Package Details",
      "cancelText": "Cancel",
      "confirmText": "Generate package",
      "size": "medium"
    },
    on: {
      "cancel": _vm.closeBankAccountModal,
      "confirm": _vm.generatePaymentPackage,
      "close": _vm.closeBankAccountModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("1. Execute payment package:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__radios"
        }, [_c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": true,
            "label": "on a custom date"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        }), _c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": false,
            "label": "at date of payment of each document"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        })], 1), _c('sygni-date-picker', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.useCustomPaymentDate,
            expression: "useCustomPaymentDate"
          }],
          attrs: {
            "format": "YYYY-MM-DD",
            "validation": _vm.$v.customPaymentDate
          },
          model: {
            value: _vm.customPaymentDate,
            callback: function callback($$v) {
              _vm.customPaymentDate = $$v;
            },
            expression: "customPaymentDate"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("2. Payment package name and SEPA:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-input', {
          attrs: {
            "label": "",
            "placeholder": _vm.defaultPaymentPackageName
          },
          model: {
            value: _vm.customPackageName,
            callback: function callback($$v) {
              _vm.customPackageName = $$v;
            },
            expression: "customPackageName"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form__row"
        }, [_c('div', {
          staticClass: "sygni-modal-form__row-checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "clickableLabel": true
          },
          model: {
            value: _vm.isSepa,
            callback: function callback($$v) {
              _vm.isSepa = $$v;
            },
            expression: "isSepa"
          }
        }, [_vm._v("SEPA")])], 1)])])];
      },
      proxy: true
    }], null, false, 149292505)
  }) : _vm._e(), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailsModalId,
      expression: "detailsModalId"
    }],
    attrs: {
      "header": "Payment details",
      "size": "large",
      "smallerActionsPadding": true,
      "isLoading": _vm.isModalLoading,
      "confirmText": _vm.isPaymentManager ? 'Save changes' : '',
      "cancelText": "Close"
    },
    on: {
      "cancel": _vm.closeDetailsModal,
      "close": _vm.closeDetailsModal,
      "confirm": _vm.updatePaymentData
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$openedRow, _vm$openedRow2, _vm$openedRow3;

        return [_c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": "PAYMENT STATUS",
            "options": _vm.paymentStatuses,
            "value": (_vm$openedRow = _vm.openedRow) === null || _vm$openedRow === void 0 ? void 0 : _vm$openedRow.paymentStatus,
            "allow-empty": false,
            "disabled": !_vm.isPaymentManager
          },
          on: {
            "input": _vm.setPaymentStatus
          }
        })], 1), _c('div', {
          staticClass: "form__col"
        }, [_c('sygni-date-picker', {
          attrs: {
            "label": "PAYMENT BOOK DATE",
            "value": (_vm$openedRow2 = _vm.openedRow) === null || _vm$openedRow2 === void 0 ? void 0 : _vm$openedRow2.paymentBookDate,
            "disabled": !_vm.isPaymentManager
          },
          on: {
            "change": _vm.setPaymentBookDate
          }
        })], 1)]), _c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-text-area', {
          attrs: {
            "label": "DESCRIPTION",
            "value": (_vm$openedRow3 = _vm.openedRow) === null || _vm$openedRow3 === void 0 ? void 0 : _vm$openedRow3.description,
            "disabled": !_vm.isPaymentManager
          },
          on: {
            "input": _vm.setDescription
          }
        })], 1)])])];
      },
      proxy: true
    }])
  }), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBulkDetailsModal,
      expression: "showBulkDetailsModal"
    }],
    attrs: {
      "header": "Update payment details",
      "smallerActionsPadding": true,
      "size": "large",
      "isLoading": _vm.isBulkModalLoading,
      "disabled": !((_vm$bulkDetailsData = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData !== void 0 && _vm$bulkDetailsData.paymentStatus) && ((_vm$bulkDetailsData2 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData2 === void 0 ? void 0 : (_vm$bulkDetailsData2$ = _vm$bulkDetailsData2.toUpdate) === null || _vm$bulkDetailsData2$ === void 0 ? void 0 : _vm$bulkDetailsData2$.paymentStatus),
      "confirmText": _vm.isPaymentManager ? 'Save changes' : '',
      "cancelText": "Close"
    },
    on: {
      "cancel": _vm.closeBulkDetailsModal,
      "close": _vm.closeBulkDetailsModal,
      "confirm": _vm.updateBulkPaymentData
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$bulkDetailsData3, _vm$bulkDetailsData4, _vm$bulkDetailsData4$, _vm$bulkDetailsData5, _vm$bulkDetailsData6, _vm$bulkDetailsData6$, _vm$bulkDetailsData7, _vm$bulkDetailsData8, _vm$bulkDetailsData8$;

        return [_c('div', {
          staticClass: "options"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary inline",
          attrs: {
            "label": "UPDATE PAYMENT STATUS"
          },
          model: {
            value: _vm.bulkDetailsData.toUpdate.paymentStatus,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkDetailsData.toUpdate, "paymentStatus", $$v);
            },
            expression: "bulkDetailsData.toUpdate.paymentStatus"
          }
        }), _c('sygni-checkbox', {
          staticClass: "secondary inline",
          attrs: {
            "label": "UPDATE PAYMENT BOOK DATE"
          },
          model: {
            value: _vm.bulkDetailsData.toUpdate.paymentBookDate,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkDetailsData.toUpdate, "paymentBookDate", $$v);
            },
            expression: "bulkDetailsData.toUpdate.paymentBookDate"
          }
        }), _c('sygni-checkbox', {
          staticClass: "secondary inline",
          attrs: {
            "label": "UPDATE DESCRIPTION"
          },
          model: {
            value: _vm.bulkDetailsData.toUpdate.description,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkDetailsData.toUpdate, "description", $$v);
            },
            expression: "bulkDetailsData.toUpdate.description"
          }
        })], 1), _c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": "PAYMENT STATUS",
            "options": _vm.paymentStatuses,
            "value": (_vm$bulkDetailsData3 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData3 === void 0 ? void 0 : _vm$bulkDetailsData3.paymentStatus,
            "allow-empty": false,
            "disabled": !_vm.isPaymentManager || !((_vm$bulkDetailsData4 = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData4 !== void 0 && (_vm$bulkDetailsData4$ = _vm$bulkDetailsData4.toUpdate) !== null && _vm$bulkDetailsData4$ !== void 0 && _vm$bulkDetailsData4$.paymentStatus)
          },
          on: {
            "input": _vm.setBulkPaymentStatus
          }
        })], 1), _c('div', {
          staticClass: "form__col"
        }, [_c('sygni-date-picker', {
          attrs: {
            "label": "PAYMENT BOOK DATE",
            "value": (_vm$bulkDetailsData5 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData5 === void 0 ? void 0 : _vm$bulkDetailsData5.paymentBookDate,
            "disabled": !_vm.isPaymentManager || !((_vm$bulkDetailsData6 = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData6 !== void 0 && (_vm$bulkDetailsData6$ = _vm$bulkDetailsData6.toUpdate) !== null && _vm$bulkDetailsData6$ !== void 0 && _vm$bulkDetailsData6$.paymentBookDate)
          },
          on: {
            "change": _vm.setBulkPaymentBookDate
          }
        })], 1)]), _c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-text-area', {
          attrs: {
            "label": "DESCRIPTION",
            "value": (_vm$bulkDetailsData7 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData7 === void 0 ? void 0 : _vm$bulkDetailsData7.description,
            "disabled": !_vm.isPaymentManager || !((_vm$bulkDetailsData8 = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData8 !== void 0 && (_vm$bulkDetailsData8$ = _vm$bulkDetailsData8.toUpdate) !== null && _vm$bulkDetailsData8$ !== void 0 && _vm$bulkDetailsData8$.description)
          },
          on: {
            "input": _vm.setBulkDescription
          }
        })], 1)])])];
      },
      proxy: true
    }])
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }