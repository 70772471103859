




import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { SelectOption } from '@/store/types';
import _ from 'lodash';
import { Campaign } from '../../modules/campaigns/store/types';
import { CodeFormat } from '../../store/types';

@Component({})
export default class AddNewCampaignBoilerplate extends Vue {
  @Prop({ default: [] }) languages: SelectOption<string>[];
  @Prop({ default: 'PL' }) selectedLanguage: string;
  campaignData: Campaign = null;
  galleryPhotos: any = [];
  reRender: number = 0;
  
  headline: any = {
    PL: '',
  };
  description: any = {
    PL: '',
  };
  textSections: any = {
    PL: [],
  }
  sectionNames: any = {
    PL: {
      gallery: 'Gallery',
      documents: 'Documents',
    }
  }
  consentData: any = {
    content: ''
  }
  translations: any = {
    PL: {
      dictionaries: {
        title: {
          'Mr.': 'Pan',
          'Mrs.': 'Pani'
        },
        documentType: {
          ID_CARD: 'dowód osobisty',
          PASSPORT: 'paszport'
        },
        country: {
          PL: 'Polska',
        }
      },
      validations: {
        required: 'Pole wymagane',
        minLength: 'Minimalna długość to',
        maxLength: 'Maksymalna długość to',
        email: 'Pole musi być poprawnym adresem email',
        strongPassword: 'Hasło jest zbyt słabe',
        sameAsPassword: 'Hasło musi być identyczne',
        minValue: 'Minimalna wartość to',
        maxValue: 'Maksymalna wartość to',
        wrongDate: 'Niepoprawna data',
        zipCode: 'Podaj poprawny kod pocztowy',
        bankAccount: 'Niepoprawny nr rachunku bankowego',
        checked: 'Pole musi zostać zaznaczone',
        phone: 'Niepoprawny nr telefonu',
        url: 'Niepoprawny adres url',
        iban: 'Niepoprawny nr rachunku bankowego',
      },
      inviteInvestor: 'Zaproś klienta',
      daysLeft: 'Dni do końca',
      investmentReach: 'Zebrane środki',
      invite: 'Zaproś',
      target: 'Cel inwestycyjny',
      duration: 'Czas trwania',
      interestPA: 'Zwrot z inwestycji P.A.',
      interestPayment: 'Płatności odsetkowe',
      campaignDetails: 'Szczegóły kampanii',
      progress: 'Postęp',
      invest: 'Zainwestuj',
      interestPaymentOptions: {
        Monthly: 'miesięczne',
        Quarterly: 'kwartalne',
        'Semi-annually': 'półroczne',
        Annually: 'roczne',
        'At Maturity': 'na zapadalność',
      },
      dates: {
        one: 'miesiąc',
        multiple: 'miesiące',
        decimal: 'miesiąca',
      },
      durations: {
        year: {
          one: 'rok',
          multiple: 'lata',
          other: 'lat'
        },
        quarter: {
          one: 'kwartał',
          multiple: 'kwartały',
          other: 'kwartałów',
        },
        month: {
          one: 'miesiąc',
          multiple: 'miesiące',
          other: 'miesięcy'
        },
        day: {
          one: 'dzień',
          multiple: 'dni',
          other: 'dni'
        }
      },
      generalData: 'Dane ogólne',
      detailedData: 'Dane szczegółowe',
      addressData: 'Dane adresowe',
      firstName: 'Imię',
      surname: 'Nazwisko',
      crmData: 'Dane CRM',
      investmentPotential: 'Potencjał inwestycyjny',
      investmentKnowledge: 'Wiedza inwestycyjna',
      industry: 'Branża',
      notes: 'Notatki',
      investmentDetails: 'Szczegóły inwestycji',
      yourInvestment: 'Twoja inwestycja',
      nextStep: 'Następny krok',
      cancel: 'Powrót',
      title: 'Tytuł',
      middleName: 'Drugie imię',
      birthDay: 'Data urodzenia',
      fathersName: 'Imię ojca',
      mothersName: 'Imię matki',
      phoneNo: 'Nr telefonu',
      identificationNo: 'PESEL',
      documentType: 'Rodzaj dokumentu',
      documentNo: 'Seria i nr dokumentu',
      documentExpiryDate: 'Data ważności dokumentu',
      documentCountry: 'Kraj wydania dokumentu',
      countryOfTaxResidence: 'Kraj rezydencji podatkowej',
      nationality: 'Obywatelstwo',
      birthPlace: 'Miejsce urodzenia',
      bankAccount: 'Rachunek bankowy',
      bankAccountName: 'Nazwa banku',
      swiftCode: 'Kod SWIFT',
      residentialAddress: 'Adres zamieszkania',
      street: 'Ulica',
      houseNo: 'Nr budynku',
      flatNo: 'Nr mieszkania',
      city: 'Miasto',
      zipCode: 'Kod pocztowy',
      country: 'Kraj',
      mailingAddress: 'Adres pocztowy',
      sameAsResidential: 'Taki sam jak zamieszkania',
      investRequestSucceed: 'Dziękujemy za Twoje zgłoszenie.',
      mailSent: 'Na podany adres e-mail zostało wysłane potwierdzenie zapisu',
      goToPanel: 'Przejdź do panelu',
      selectAtLeastOneIndustry: 'Wybierz co najmniej jedną branżę'
    },
    EN: {
      dictionaries: {
        title: {
          'Mr.': 'Mr.',
          'Mrs.': 'Mrs.'
        },
        documentType: {
          ID_CARD: 'ID card', 
          PASSPORT: 'passport'
        },
        country: {
          PL: 'Poland',
        }
      },
      validations: {
        required: 'This field is required',
        minLength: 'Minimum length is',
        maxLength: 'Maximum length is',
        email: 'This field must be valid email',
        strongPassword: 'Password is too weak',
        sameAsPassword: 'Passwords must be same',
        minValue: 'Minimum value is',
        maxValue: 'Maximum value is',
        wrongDate: 'Invalid date',
        zipCode: 'Provide correct zip code',
        bankAccount: 'Incorrect bank account number',
        checked: 'This field must be checked',
        phone: 'Invalid phone number',
        url: 'Invalid url',
        iban: 'Invalid Bank Account number',
      },
      inviteInvestor: 'Invite investor',
      invite: 'Invite',
      daysLeft: 'Days left',
      investmentReach: 'Raised funds',
      target: 'Target',
      duration: 'Duration',
      interestPA: 'Interest P.A.',
      interestPayment: 'Interest payment',
      campaignDetails: 'Campaign details',
      progress: 'Progress',
      invest: 'Invest',
      interestPaymentOptions: {
        Monthly: 'monthly',
        Quarterly: 'quaterly',
        'Semi-annually': 'semi-annually',
        Annually: 'annually',
        'At Maturity': 'at maturity',
      },
      dates: {
        one: 'month',
        multiple: 'months',
        decimal: 'months',
      },
      durations: {
        year: {
          one: 'year',
          multiple: 'years',
          other: 'years'
        },
        quarter: {
          one: 'quarter',
          multiple: 'quarters',
          other: 'quarters',
        },
        month: {
          one: 'month',
          multiple: 'months',
          other: 'months'
        },
        day: {
          one: 'day',
          multiple: 'days',
          other: 'days'
        }
      },
      generalData: 'General data',
      detailedData: 'Detailed data',
      addressData: 'Address data',
      firstName: 'First name',
      surname: 'Surname',
      crmData: 'CRM Data',
      investorPotential: 'Investor potential',
      investorKnowledge: 'Investor knowledge',
      industry: 'Industry',
      notes: 'Notes',
      investmentDetails: 'Investment details',
      yourInvestment: 'Your investment',
      nextStep: 'Next step',
      cancel: 'Cancel',
      title: 'Title',
      middleName: 'Middle name',
      birthDay: 'Birth day',
      fathersName: 'Fathers name',
      mothersName: 'Mothers name',
      phoneNo: 'Phone no.',
      identificationNo: 'Identification no.',
      documentType: 'Document type',
      documentNo: 'Document no.',
      documentExpiryDate: 'Document expiry date',
      documentCountry: 'Document country',
      countryOfTaxResidence: 'Country of tax residence',
      nationality: 'Nationality',
      birthPlace: 'Birth place',
      bankAccount: 'Bank account',
      bankAccountName: 'Bank account name',
      swiftCode: 'SWIFT code',
      residentialAddress: 'Residential address',
      street: 'Street',
      houseNo: 'House no.',
      flatNo: 'Flat no.',
      city: 'City',
      zipCode: 'Zip code',
      country: 'Country',
      mailingAddress: 'Mailing address',
      sameAsResidential: 'Same as residential',
      investRequestSucceed: 'Invest request succeed',
      mailSent: 'We have sent a link confirming your identity to the email address provided',
      goToPanel: 'Go to panel',
      selectAtLeastOneIndustry: 'Select at least one industry'
    }
  }

  get parentLink() {
    return this.$route.path.includes('company') ? 'company' : 'fund';
  }

  get languageOptions() {
    return this.languages.map((el: SelectOption<string>) => el.value);
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get isEditable() {
    return !(this.campaignData?.status?.toLowerCase() === 'active') || this.isMarketplaceAccountant
  }

  get productCodeFormats(): CodeFormat[] {
    return this.$store.getters['investors/productCodeFormats'] || []
  }

  loadCampaignData() {
    this.campaignData = this.$store.getters['campaigns/getCampaignData'];

    const campaignDetailsData = this.campaignData.data;

    if (campaignDetailsData?.headline) {
      this.headline = campaignDetailsData.headline;
    }

    if (campaignDetailsData?.textSections) {
      this.textSections = campaignDetailsData.textSections;
    }

    if (campaignDetailsData?.galleryPhotos) {
      this.galleryPhotos = campaignDetailsData.galleryPhotos;
    }

    if (campaignDetailsData?.description) {
      this.description = campaignDetailsData.description;
    }

    if(campaignDetailsData?.sectionNames) {
      this.sectionNames = campaignDetailsData.sectionNames;
    }

    if (campaignDetailsData?.consentData) {
      this.consentData = campaignDetailsData?.consentData;
    }
  }

  // eslint-disable-next-line no-unused-vars
  setCampaignData(cb?: any) {
    this.campaignData.data = {
      languages: this.languages,
      headline: this.headline,
      description: this.description,
      textSections: this.textSections,
      galleryPhotos: this.galleryPhotos,
      sectionNames: this.sectionNames,
      translations: this.translations,
      consentData: this.consentData,
    };
    this.$store.commit('campaigns/setCampaignData', this.campaignData);
  }

  beforeMount() {
    this.loadCampaignData();
  }

  @Watch('languages', { deep: true }) onLanguagesChange() {
    const translatableFields = ['headline', 'description', 'textSections', 'sectionNames'];
    const defaultValues: any = {
      headline: '',
      description: '',
      textSections: [],
      sectionNames: {
        gallery: '',
        documents: '',
      },
    };
    translatableFields.forEach((field: string) => {
      this.languages.forEach((lang: SelectOption<string>) => {
        if (!(this as any)[field][lang.value]) {
          (this as any)[field][lang.value] = _.clone(defaultValues[field]);
        }
      })

      const keys = Object.keys((this as any)[field]).filter((el: string) => !this.languageOptions.includes(el));
      keys.forEach((key: string) => {
        delete (this as any)[field][key];
      })
    });
    this.setCampaignData();
  }

  @Watch('selectedLanguage') onSelectedLanguageChange() {
    this.reRender++;
  }
}
