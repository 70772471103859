var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-checkbox', _vm.disabled ? 'disabled' : '', !_vm.legacyStyling ? 'tw-theme' : ''],
    attrs: {
      "id": _vm.testId
    }
  }, [_c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modelValue,
      expression: "modelValue"
    }],
    class: _vm.value ? 'sygni-checkbox--active' : '',
    attrs: {
      "type": "checkbox",
      "disabled": _vm.disabled
    },
    domProps: {
      "checked": Array.isArray(_vm.modelValue) ? _vm._i(_vm.modelValue, null) > -1 : _vm.modelValue
    },
    on: {
      "input": _vm.handleInput,
      "change": function change($event) {
        var $$a = _vm.modelValue,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.modelValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.modelValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.modelValue = $$c;
        }
      }
    }
  }), _vm.label ? _c('div', {
    staticClass: "label",
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    },
    on: {
      "click": function click($event) {
        return _vm.clickCheckbox();
      }
    }
  }) : _vm._e(), !_vm.label ? _c('div', {
    staticClass: "label",
    on: {
      "click": function click($event) {
        return _vm.clickCheckbox();
      }
    }
  }, [_vm._t("default")], 2) : _vm._e()]), _c('sygni-input-error', {
    attrs: {
      "legacyStyling": _vm.legacyStyling,
      "validation": _vm.validation
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }