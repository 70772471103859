var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_vm.freeToolsAvailable ? _c('sygni-loader', {
    attrs: {
      "isLoading": _vm.xmlLoading
    }
  }) : _vm._e(), _c('div', {
    staticClass: "welcome__container"
  }, [_c('div', {
    staticClass: "welcome__main"
  }, [_c('div', {
    staticClass: "welcome__header"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "bold",
    domProps: {
      "innerHTML": _vm._s(_vm.welcomeText.title)
    }
  }), _c('br')]), _c('div', {
    staticClass: "subtitle",
    domProps: {
      "innerHTML": _vm._s(_vm.welcomeText.lead)
    }
  }), _c('div', {
    staticClass: "welcome__description description",
    domProps: {
      "innerHTML": _vm._s(_vm.welcomeText.content)
    }
  })]), _c('div', {
    staticClass: "welcome__content"
  }, [!_vm.authorized && _vm.activeUserContext.context === _vm.Contexts.privateInvestor ? _c('authorize-data', {
    on: {
      "goToAuthorize": function goToAuthorize($event) {
        return _vm.goToAuthorize();
      }
    }
  }) : _vm._e(), _vm.company && _vm.product && _vm.activeUserContext.context === _vm.Contexts.privateInvestor ? _c('product-card', {
    attrs: {
      "product": _vm.product,
      "company": _vm.company,
      "authorized": _vm.authorized
    },
    on: {
      "acceptProduct": _vm.acceptProduct
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "welcome__sidebar"
  }, [_vm.posts.length == 0 ? _c('div', {
    staticClass: "welcome__ad-baner"
  }, [_vm._m(0)]) : _c('div', {
    staticClass: "welcome-panel"
  }, [_c('div', {
    staticClass: "welcome-panel__inner"
  }, [_c('sygni-container-title', [_vm._v("Get started "), _c('span', {
    staticClass: "show-xl"
  }, [_vm._v("with Fundequate")])]), _c('div', {
    staticClass: "welcome-panel__box"
  }, [_vm._l(_vm.posts, function (post) {
    return _c('simple-post-box', {
      key: post.id,
      attrs: {
        "isLoading": _vm.arePostsLoading,
        "size": "sm",
        "thumbnail": post.photoHeader,
        "label": _vm._f("dateWithoutTime")(post.publishedAt),
        "title": post.title
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: 'insight-by-slug',
            params: {
              slug: post.slug
            }
          });
        }
      }
    });
  }), _c('sygni-link-button', {
    staticClass: "gn-primary",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'insights'
        });
      }
    }
  }, [_vm._v("See more posts")])], 2)], 1)])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ad-baner__unauthorized"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/marketplace--grey.svg")
    }
  }), _c('div', {
    staticClass: "ad-baner__text"
  }, [_vm._v(" Authorise your data to have personalised offers ")])]);
}]

export { render, staticRenderFns }