






















import Vue from 'vue'
import Component, {mixins} from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import {SygniValidation} from "@/shared/mixins/ValidationMixin";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
@Component({
  components: {SygniInputError}
})
export default class SygniCheckbox extends mixins(Vue, SygniValidation) {

  @Prop() label!: String;
  @Prop() value!: boolean;
  @Prop({ default: true }) legacyStyling: boolean;
  @Prop({default: false}) removeWatcher: boolean;
  @Prop({default: null}) testId: string;
  @Watch('value', {immediate: true}) onValueChange(){
    this.modelValue = this.value;
  }
  @Prop({default: true}) clickableLabel!: boolean;
  @Prop({default: false}) disabled: boolean;
  modelValue: boolean = false;
  
  @Watch('modelValue') onModelValueChange(): void {
    if(this.removeWatcher) return;
    this.emitModelValue();
  }

  handleInput() {
    if(this.removeWatcher && !this.disabled) {
      this.$emit('changedValue', !this.modelValue)
    }
  }

  @Emit('input')
  emitModelValue(): boolean {
    this.$emit('changedValue');
    return this.modelValue;
  }

  mounted(){
  }

  clickCheckbox(){
    // @ts-ignore: Object is possibly 'null'.
    if(this.clickableLabel === true && !this.isDisabled){
      this.$el.querySelector('input').click();
    }
  }
}
