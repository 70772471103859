var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounting-module"
  }, [_c('accounting-filters', {
    on: {
      "filtersChange": _vm.applyFiltersEvent,
      "exportCsv": _vm.exportCsvByFilters
    }
  }), _c('div', {
    staticClass: "container-fluid"
  }, [_vm.showFileUploader ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('file-uploader', {
    attrs: {
      "supported-file-formats": _vm.supportedFileFormats,
      "dropAreaText": "Drag and drop to add your new document",
      "infoText": "File format: pdf, jpg, png / max 5mb",
      "uploadProgress": _vm.documentsUploadProgress,
      "multiple": true
    },
    on: {
      "input": function input($event) {
        return _vm.addFiles($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "additionalContent",
      fn: function fn() {
        return [_c('sygni-toggle-switch', {
          staticClass: "file-uploader-toggle",
          attrs: {
            "label": "DETAILED OCR"
          },
          model: {
            value: _vm.detailedOcr,
            callback: function callback($$v) {
              _vm.detailedOcr = $$v;
            },
            expression: "detailedOcr"
          }
        })];
      },
      proxy: true
    }], null, false, 725917844)
  })], 1)]) : _vm._e(), _c('div', {
    class: ['row', !_vm.showFileUploader ? 'mt-4' : '']
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('accounting-table', {
    ref: "accountingTable",
    attrs: {
      "show-page-number-options": true,
      "tableData": _vm.documentsTable,
      "tablePerPageMutation": "accounting/setDocumentsTablePerPage",
      "setTableBusyMutation": "accounting/setDocumentsTableBusy",
      "busy": _vm.isBusy,
      "perPage": _vm.perPage,
      "getItemsAction": "accounting/getDocuments"
    },
    on: {
      "deleteRow": _vm.confirmDelete,
      "bulkDelete": _vm.confirmBulkDelete,
      "book": _vm.bookDocuments,
      "openFlagManagementModal": _vm.enableFlagManagementModal
    }
  }), _c('flags-management-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFlagManagementModal && !_vm.showFlagAccessModal,
      expression: "showFlagManagementModal && !showFlagAccessModal"
    }],
    on: {
      "close": _vm.closeFlagManagementModal,
      "showFlagAccessModal": _vm.enableFlagAccessModal
    }
  }), _c('flag-access-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFlagAccessModal,
      expression: "showFlagAccessModal"
    }],
    attrs: {
      "flag": _vm.sharingFlag
    },
    on: {
      "close": _vm.closeFlagAccessModal
    }
  })], 1)])]), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showDeleteModal,
      expression: "showDeleteModal"
    }],
    attrs: {
      "header": "Are you sure?",
      "description": "This action can't be undone",
      "cancelText": "Cancel",
      "confirmText": "Delete",
      "isLoading": _vm.isLoading
    },
    on: {
      "cancel": _vm.closeDeleteModal,
      "close": _vm.closeDeleteModal,
      "confirm": _vm.confirmDeleteAction
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table",
          attrs: {
            "innertable": "true",
            "fields": [{
              key: 'fileName',
              sortable: false,
              class: ['text-left']
            }],
            "items": _vm.deleteFilesModalItems
          }
        })];
      },
      proxy: true
    }])
  }), _c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showUploadFilesModal,
      expression: "showUploadFilesModal"
    }],
    attrs: {
      "header": "Files processed",
      "size": "large",
      "description": _vm.processedFilesDescription,
      "confirmText": _vm.allFilesUploaded ? '' : 'OK',
      "cancelText": ""
    },
    on: {
      "close": _vm.closeUploadFilesModal,
      "confirm": _vm.closeUploadFilesModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table",
          attrs: {
            "innertable": "true",
            "fields": [{
              key: 'fileName',
              sortable: false,
              class: ['fileName']
            }, {
              key: 'status',
              sortable: false,
              class: ['status']
            }],
            "items": _vm.uploadFilesModalItems
          },
          scopedSlots: _vm._u([{
            key: "cell(status)",
            fn: function fn(rowData) {
              var _rowData$item$errors$;

              return [_c('div', {
                staticClass: "table__status d-flex justify-content-center align-items-center"
              }, [_c('sygni-rounded-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  value: (_rowData$item$errors$ = rowData.item.errors[0]) === null || _rowData$item$errors$ === void 0 ? void 0 : _rowData$item$errors$.message,
                  expression: "rowData.item.errors[0]?.message",
                  modifiers: {
                    "hover": true
                  }
                }],
                class: ['outline round', _vm.getFileStatusClass(rowData.item.status)],
                attrs: {
                  "hoverable": false
                }
              }, [_vm._v(_vm._s(_vm.getFileStatusText(rowData.item.status)))])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }