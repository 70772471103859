var render = function () {
  var _vm$$slots;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['gn-modal', "gn-modal--".concat(_vm.size), !_vm.overflow ? 'gn-modal--remove-overflow' : '', _vm.type === 'iframe' ? 'gn-modal--iframe' : '', _vm.smallerActionsPadding ? 'gn-modal--actions' : ''],
    attrs: {
      "id": _vm.testId
    }
  }, [_c('div', {
    staticClass: "gn-modal__inner",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    ref: "gnModalBox",
    staticClass: "gn-modal__box",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    class: ['gn-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "gn-modal__close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20",
      "fill": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"
    }
  })])]), _c('p', {
    staticClass: "gn-modal__header"
  }, [_vm._v(_vm._s(_vm.header))]), _vm.description ? _c('p', {
    staticClass: "gn-modal__desc"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _vm.hasContentSlot ? _c('div', {
    staticClass: "gn-modal__content"
  }, [_vm._t("content")], 2) : _vm._e(), _vm.cancelText || _vm.confirmText || (_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.additionalActions ? _c('div', {
    staticClass: "gn-modal__actions"
  }, [_vm._t("additionalActions"), _vm.cancelText ? _c('sygni-rounded-button', {
    staticClass: "outline disabled",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(_vm._s(_vm.cancelText))]) : _vm._e(), _vm.confirmText ? _c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(_vm._s(_vm.confirmText))]) : _vm._e()], 2) : _vm._e()])]), _c('div', {
    staticClass: "gn-modal__bg"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }