






































import Vue from 'vue'
import {NavigationGuardNext, Route} from 'vue-router';
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {
  emptyInvestmentClient,
  InvestmentClient,
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {alphaNum} from "vuelidate/lib/validators";
import {phone} from '@/shared/validators';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {InvestorSelects, GlobalSelects} from "@/modules/shared/dictionary-store/types";
import {ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {mapGetters} from "vuex";
import {BSpinner} from "bootstrap-vue";
import EditProfile from '../EditProfile.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import { Watch } from 'vue-property-decorator';

Component.registerHooks(['validations', 'beforeRouteLeave'])
@Component({
  components: {
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle, SygniDatePicker, BSpinner, EditProfile, GenproxModal},
  computed: {
    ...mapGetters('dictionary', {
      selects: 'investorsSelects',
      globalSelects: 'globalSelects',
    })
  }
})
export default class EditDetailedData extends Vue {
  isLoading: boolean =  false;
  showModal: boolean = false;
  nextRoute: NavigationGuardNext<Vue> = null;
  hasChanges: boolean = false;
  profileData: ProfileDataState = {} as ProfileDataState;
  investmentData: InvestmentClient = {} as InvestmentClient;
  selects!: InvestorSelects;
  globalSelects!: GlobalSelects;

  get isDocumentExpiryFieldDisabled() {
    return !!(this.profileData?.details?.documentExpiryIndefinitely)
  }

  beforeMount() {
    this.$store.commit('investors/setNewInvestor', emptyInvestmentClient);
    this.profileData = this.$store.getters['profile/getProfileData'];
  }

  showUpdateMessage(type: string, title: string, text: string) {
    this.isLoading = false;
    this.showModal = false;

    this.$notify({
      duration: 2500,
      type,
      title,
      text
    });
  }

  handleFieldChange(): void {
    this.hasChanges = true;
    this.$v.$reset();
  }

  async updateProfile(nextRoute: NavigationGuardNext<Vue>) {
    if(!this.hasChanges) return; 

    this.isLoading = true;
    this.$v.$touch();

    if(!this.$v.$error) {
      try {
        this.profileData.generalData.phone = (!this.profileData.generalData.phone) ? null : this.profileData.generalData.phone;
        await this.$store.dispatch('profile/updateProfileData', this.profileData);
        this.showUpdateMessage('success', 'Changes saved', 'Your profile has been updated.');
        this.hasChanges = false;

        if(nextRoute) nextRoute();

      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.showUpdateMessage('error', 'Error', errorMessage);
      }
    } else {
      this.showUpdateMessage('error', 'Validation error', 'Please check your details.');
    }
  }

  changeRoute() {
    if(this.nextRoute) this.nextRoute();
  }

  async confirmUpdateProfile() {
    await this.updateProfile(this.nextRoute);
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if(this.hasChanges) {
      this.showModal = true;
      this.nextRoute = next;
    } else {
      next();
    }
  }

  validations() {
    return {
      profileData: {
        generalData: {
          phone: { phone },
        },
        details: {
          identificationNumber: { alphaNum },
        },
      }
    }
  }

  @Watch('profileData.details.documentExpiryIndefinitely') onDocumentExpiryIndefinitelyUpdate() {
    if (this.profileData?.details?.documentExpiryIndefinitely) {
      this.profileData.details.documentExpiryDate = null
    }
  }
}
