import {RouteConfig} from "vue-router";
import {AddRegRepType} from "@/modules/genprox/modules/fund/modules/reg-reporting/store/types";
import store from "@/store/rootStore";

const regReportingModule = () => import('./containers/RegReportingModule.vue');
const regDashboardModule = () => import('./containers/RegDashboardModule.vue');
const regReportingBlindModule = () => import('./containers/RegReportingBlindModule.vue');
const addReport = () => import('./../../../AIFM/modules/reg-reporting/containers/AddNewReport.vue');
const knfXmlPreview = () => import('./../../../AIFM/modules/reg-reporting/containers/KnfXmlPreview.vue');
const xmlPreview = () => import('./../../../AIFM/modules/reg-reporting/containers/XmlPreview.vue');

export const regReporting: Array<RouteConfig>  = [
    {
        path: 'regulatory-reporting',  /* /fund */
        name: 'fund-regulatory-reporting',
        component: regReportingModule,
        props: true,
        children: [],
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.regulatoryReporting) {
                next();
            } else {
                next({ name: 'reg-reporting-access-denied' });
            }
        },
    },
    {
        path: 'dashboard',  /* /fund */
        name: 'fund-reg-dashboard',
        component: regDashboardModule,
    },
    {
        path: 'regulatory-reporting/knf-xml-preview',
        name: 'fund-reg-xml-preview',
        props: true,
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: 'Reg reporting', href: '/fund/regulatory-reporting' },
                { text: 'XML Reader for AIF Regulatory Reporting', href: '' },
            ]
        },
        component: knfXmlPreview,
    },
    {
        path: 'regulatory-reporting/xml-preview',
        name: 'fund-xml-preview',
        props: true,
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: 'Reg reporting', href: '/fund/regulatory-reporting' },
                { text: 'XML Reader for Financial Statements', href: '' },
            ]
        },
        component: xmlPreview,
    },
    {
        path: 'regulatory-reporting/access-denied',
        name: 'reg-reporting-access-denied',
        component: regReportingBlindModule,
        meta: {
            accessModule: ['regulatoryReporting'],
        },
    }
];
