var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: [_vm.buttonClass, !_vm.legacyStyling ? 'tw-theme' : ''],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.toggleActive();
      }
    }
  }, [_vm.active || _vm.plusIcon || _vm.arrowIcon ? _c('div', {
    staticClass: "non-existing-left-div"
  }) : _vm._e(), _vm.arrowIconLeft ? _c('div', {
    staticClass: "plus-icon left"
  }) : _vm._e(), _vm.arrowIconTop ? _c('div', {
    staticClass: "plus-icon top"
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        customClass: _vm.tooltipCustomClass
      },
      expression: "{ customClass: tooltipCustomClass }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text",
    attrs: {
      "title": _vm.tooltip
    }
  }, [!_vm.loading ? _vm._t("default") : _vm._e(), _vm.loading ? [_c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "type": "grow"
    }
  }), _c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "type": "grow"
    }
  }), _c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "type": "grow"
    }
  })] : _vm._e()], 2), _vm.arrowIconLeft ? _c('div', {
    staticClass: "non-existing-right-div"
  }) : _vm._e(), _vm.active ? _c('div', {
    staticClass: "remove right"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10.097",
      "height": "10.097",
      "viewBox": "0 0 10.097 10.097"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_1157",
      "data-name": "Path 1157",
      "d": "M-.742-8.624v2.9H-3.57v1.344H-.742v2.9H.742v-2.9H3.57V-5.726H.742v-2.9Z",
      "transform": "translate(1.475 8.622) rotate(45)",
      "fill": "#000000"
    }
  })])]) : _vm._e(), _vm.plusIcon ? _c('div', {
    staticClass: "plus-icon right"
  }, [_vm._v(" + ")]) : _vm._e(), _vm.arrowIcon ? _c('div', {
    staticClass: "plus-icon right"
  }, [_vm._v(" > ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }