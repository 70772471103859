

































































import Vue from 'vue'
import Component from 'vue-class-component'
import PageDetails from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/PageDetails.vue";
import Specs from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/Specs.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { Specification, Table } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";

import { BTable, BSpinner } from "bootstrap-vue";
import { Watch } from 'vue-property-decorator';
import GenproxBreadcrumbs from '@/components/layout/GenproxBreadcrumbs.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';

@Component({
  components: { PageDetails, BTable, BSpinner, Specs, SygniRoundedButton, SygniLoader, GenproxBreadcrumbs },
})
export default class KnfXmlPreview extends Vue {
  isLoading: boolean = true;
  title: string = 'XML Reader for AIF Regulatory Reporting';
  xml: string = '';
  tables: Array<any> = [];
  specs: Array<Specification> = [];
  codes: Array<any> = [];
  marketCodes: Array<any> = [];
  emptyPreview: boolean = false;
  dragActive: boolean = false;

  get xmlDoc() {
    const parser = new DOMParser();

    return parser.parseFromString(this.xml, "text/xml");
  }

  get isDatman() {
    return this.xmlDoc.getElementsByTagName("AIFMRecordInfo")?.length > 0;
  }

  get category() {
    return this.isDatman ? 'DATMAN' : 'DATAIF';
  }

  get currency() {
    return this.isDatman ? 'EUR' : 'zł';
  }

  get hasId() {
    return !!this.$route.query?.id;
  }

  get isLoaded() {
    return this.specs.length > 0 || this.tables.length > 0 || this.emptyPreview;
  }

  get routeId(): any {
    return this.$route.query.id;
  }

  printPage() {
    window.print();
  }

  getMarketCode(el: Element) {
    if (el.getElementsByTagName("MarketCodeType")[0]) {
      if (el.getElementsByTagName("MarketCode")[0]) {
        return el.getElementsByTagName("MarketCodeType")[0].textContent == 'NOT' ? '' : this.mapMarketCode(el.getElementsByTagName("MarketCodeType")[0].textContent, el.getElementsByTagName("MarketCode")[0]?.textContent);
      } else {
        return el.getElementsByTagName("MarketCodeType")[0].textContent == 'NOT' ? '' : this.mapMarketCodeLegacy(el.getElementsByTagName("MarketCodeType")[0].textContent);
      }
    }

    return '';
  }

  mapMarketCode(marketCodeType: string, marketCode: string) {
    const code = this.marketCodes.find((el) => {
      return el.codeLevel1 == marketCodeType && el.codeLevel2 === marketCode;
    });

    return code?.label;
  }

  mapMarketCodeLegacy(text: string) {
    const marketCode = this.marketCodes.find((el) => {
      return el.codeLevel1 == text;
    });

    return marketCode?.label;
  }

  getSubAssetType(el: Element) {
    const assetType = el.getElementsByTagName("SubAssetType")[0];
    if (!assetType || assetType.textContent == 'NTA_NTA_NOTA') {
      return '';
    }

    const assetTypeCode = this.codes.find((el) => {
      return el.category3.code == assetType.textContent;
    });

    return assetTypeCode.category3.description;
  }

  getAssetType(el: Element) {
    const assetType = el.getElementsByTagName("AssetType")[0];
    if (!assetType || assetType.textContent == 'NTA_NTA') {
      return '';
    }

    const assetTypeCode = this.codes.find((el) => {
      return el.category2.code == assetType.textContent;
    });

    return assetTypeCode.category2.description;
  }

  parseNumber(number: string) {
    return parseFloat(number).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  generateDataIfMainInstrumentsTraded(mainInstrumentsTraded: HTMLCollection) {
    const formattedMainInstruments: Table = {
      id: 1,
      label: 'Main Instruments Traded',
      type: 'default',
      fields: [
        {
          key: 'no',
          label: 'No.',
        },
        {
          key: 'asset_type',
          label: 'Asset Type',
          class: 'mid-width'
        },
        {
          key: 'instrument_name',
          label: 'Instrument Name',
        },
        {
          key: 'position_value',
          label: 'Position Value',
        },
      ],
      items: [],
    }

    if(mainInstrumentsTraded[0]?.children.length) {
      Array.from(mainInstrumentsTraded[0].children).forEach((el) => {
        const mainInstrument = {
          no: this.getTextContentByKey(el, 'Ranking'),
          asset_type: this.getSubAssetType(el),
          instrument_name: this.getTextContentByKey(el, 'InstrumentName'),
          position_value: this.getTextPriceContentByKey(el, 'PositionValue'),
        }
        formattedMainInstruments.items.push(mainInstrument);
      });
    }

    return formattedMainInstruments;
  }

  generateDataIfPrincipalExposures(principalExposures: HTMLCollection) {
    const formattedPrincipalExposures: Table = {
      id: 2,
      label: 'Principal Exposures',
      type: 'default',
      fields: [
        {
          key: 'no',
          label: 'No.',
        },
        {
          key: 'asset_type',
          label: 'Asset Type',
          class: 'mid-width'
        },
        {
          key: 'aggregated_value_amount',
          label: 'Aggregated Value Amount',
        },
        {
          key: 'aggregated_value_rate',
          label: 'Aggregated Value Rate',
        },
      ],
      items: [],
    }

    if(principalExposures[0]?.children.length) {
      Array.from(principalExposures[0].children).forEach(el => {
        const principalExposure = {
          no: this.getTextContentByKey(el, 'Ranking'),
          asset_type: this.getSubAssetType(el),
          aggregated_value_amount: this.getTextPriceContentByKey(el, 'AggregatedValueAmount'),
          aggregated_value_rate: this.getTextPercentageContentByKey(el, 'AggregatedValueRate'),
        }
        formattedPrincipalExposures.items.push(principalExposure);
      });
    }


    return formattedPrincipalExposures;
  }

  generateDataIfPortfolioConcentrations(portfolioConcentrations: HTMLCollection) {
    const formattedPortfolioConcentrations: Table = {
      id: 3,
      label: 'Portfolio Concentrations',
      type: 'default',
      fields: [
        {
          key: 'no',
          label: 'No.',
        },
        {
          key: 'asset_type',
          label: 'Asset Type',
          class: 'mid-width'
        },
        {
          key: 'market_code',
          label: 'Market Code',
        },
        {
          key: 'aggregated_value_amount',
          label: 'Aggregated Value Amount',
        },
        {
          key: 'aggregated_value_rate',
          label: 'Aggregated Value Rate',
        },
      ],
      items: [],
    }

    if(portfolioConcentrations[0]?.children.length) {
      Array.from(portfolioConcentrations[0].children).forEach(el => {
        const portfolioConcentration = {
          no: this.getTextContentByKey(el, 'Ranking'),
          asset_type: this.getAssetType(el),
          market_code: this.getMarketCode(el),
          aggregated_value_amount: this.getTextPriceContentByKey(el, 'AggregatedValueAmount'),
          aggregated_value_rate: this.getTextPercentageContentByKey(el, 'AggregatedValueRate'),
        }
        formattedPortfolioConcentrations.items.push(portfolioConcentration);
      });
    }

    return formattedPortfolioConcentrations;
  }

  generateDataIfPrincipalMarkets(principalMarkets: HTMLCollection) {
    const formattedPrincipalMarkets: Table = {
      id: 4,
      label: 'AIF Principal Markets',
      type: 'default',
      fields: [
        {
          key: 'no',
          label: 'No.',
        },
        {
          key: 'market_code',
          label: 'Market Code',
        },
        {
          key: 'aggregated_value_amount',
          label: 'Aggregated Value Amount',
        },
      ],
      items: [],
    }

    if(principalMarkets[0]?.children.length) {
      Array.from(principalMarkets[0].children).forEach(el => {
        const principalMarket = {
          no: this.getTextContentByKey(el, 'Ranking'),
          market_code: this.getMarketCode(el),
          aggregated_value_amount: this.getTextPriceContentByKey(el, 'AggregatedValueAmount'),
        }
        formattedPrincipalMarkets.items.push(principalMarket);
      });
    }

    return formattedPrincipalMarkets;
  }

  generateDatmanPrincipalMarkets(principalMarkets: HTMLCollection) {
    const formattedPrincipalMarkets: Table = {
      id: 1,
      label: 'Principal Markets',
      type: 'default',
      fields: [
        {
          key: 'no',
          label: 'No.'
        },
        {
          key: 'market_code',
          label: 'Market Code'
        },
        {
          key: 'aggregated_value_amount',
          label: 'Aggregated Value Amount'
        }
      ],
      items: [],
    };
    
    if(principalMarkets[0]?.children.length) {
      Array.from(principalMarkets[0].children).forEach(el => {
        const principalMarket = {
          no: this.getTextContentByKey(el, 'Ranking'),
          market_code: this.getMarketCode(el),
          aggregated_value_amount: this.getTextPriceContentByKey(el, 'AggregatedValueAmount'),
        }
        formattedPrincipalMarkets.items.push(principalMarket);
      });
    }

    return formattedPrincipalMarkets;
  }

  generateDatmanPrincipalInstruments(principalInstruments: HTMLCollection) {
    const formattedPrincipalInstruments: Table = {
      id: 2,
      label: 'Principal Instrument',
      type: 'default',
      fields: [
        {
          key: 'no',
          label: 'No.'
        },
        {
          key: 'asset_type',
          label: 'Asset Type',
          class: 'mid-width'
        },
        {
          key: 'aggregated_value_amount',
          label: 'Aggregated Value Amount'
        }
      ],
      items: [],
    };
    
    if(principalInstruments[0]?.children.length) {
      Array.from(principalInstruments[0].children).forEach((el) => {
        const principalInstrument = {
          no: this.getTextContentByKey(el, 'Ranking'),
          asset_type: this.getSubAssetType(el),
          aggregated_value_amount: this.getTextPriceContentByKey(el, 'AggregatedValueAmount'),
        }
        formattedPrincipalInstruments.items.push(principalInstrument);
      });
    }

    return formattedPrincipalInstruments;
  }

  getTextPriceContentByKey(el: Element | Document, key: string, defaultValue: string = '') {
    return el.getElementsByTagName(key)[0] ? `${this.parseNumber(el.getElementsByTagName(key)[0].textContent)} ${this.currency}` : defaultValue;
  }

  getTextPercentageContentByKey(el: Element | Document, key: string, defaultValue: string = '') {
    const value = el.getElementsByTagName(key)[0] ? `${el.getElementsByTagName(key)[0].textContent} %` : defaultValue;

    return value;
  }

  getTextContentByKey(xml: Element | Document, key: string, defaultValue: string = '') {
    const value = xml.getElementsByTagName(key)[0] ? xml.getElementsByTagName(key)[0].textContent : defaultValue;

    return value;
  }

  generateDatmanSpecs(xml: Document) {
    const specs = [
      {
        label: 'Reporting period',
        value: `<span>from</span> ${this.getTextContentByKey(xml, "ReportingPeriodStartDate")} <span>to</span> ${this.getTextContentByKey(xml, "ReportingPeriodEndDate")}`,
      },
      {
        label: 'Amount of assets under management in euro',
        value: this.getTextPriceContentByKey(xml, "AUMAmountInEuro"),
      }
    ]

    return specs;
  }

  generateDataIfSpecs(xml: Document) {
    const specs = [
      {
        label: 'Reporting period',
        value: `<span>from</span> ${this.getTextContentByKey(xml, 'ReportingPeriodStartDate')} <span>to</span> ${this.getTextContentByKey(xml, 'ReportingPeriodEndDate')}`,
      },
      {
        label: 'Amount of assets under management in the base currency:',
        value: this.getTextPriceContentByKey(xml, 'AUMAmountInBaseCurrency'),
      },
      {
        label: 'AIF Net Asset Value',
        value: this.getTextPriceContentByKey(xml, 'AIFNetAssetValue'),
      },
      {
        label: 'Main Beneficial Owners Rate',
        value: this.getTextPercentageContentByKey(xml, 'MainBeneficialOwnersRate'),
      },
      {
        label: 'Professional Investor Concentration Rate',
        value: this.getTextPercentageContentByKey(xml, 'ProfessionalInvestorConcentrationRate'),
      },
      {
        label: 'Retail Investor Concentration Rate',
        value: this.getTextPercentageContentByKey(xml, 'RetailInvestorConcentrationRate'),
      },
    ];

    return specs;
  }

  async getXmlReportCodes() {
    const data = await this.$store.dispatch('regReporting/getXmlKnfReportCodes');

    return data;
  }

  async getXmlReportFile(id: string | string[]) {
    try {
      const data = await this.$store.dispatch('genprox/getXmlFile', id);
      this.isLoading = false;

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  async initXmlPreview() {
    const reportCodes = await this.getXmlReportCodes();
    this.codes = reportCodes.category;

    this.marketCodes = reportCodes.other.filter((el: { type: string; }) => {
      return el.type == 'Market';
    });

    this.xml = await this.getXmlReportFile(this.$route.query.id);

    if (this.xml) {
      if (this.isDatman) {
        const principalMarkets = this.generateDatmanPrincipalMarkets(this.xmlDoc.getElementsByTagName("AIFMPrincipalMarkets"));
        const principalInstruments = this.generateDatmanPrincipalInstruments(this.xmlDoc.getElementsByTagName("AIFMPrincipalInstruments"));

        this.generateDatmanSpecs(this.xmlDoc).forEach(el => {
          this.specs.push(el);
        });

        this.title = this.getTextContentByKey(this.xmlDoc, 'AIFMName');
        this.tables.push(principalMarkets);
        this.tables.push(principalInstruments);
      } else {
        const mainInstrumentsTraded = this.generateDataIfMainInstrumentsTraded(this.xmlDoc.getElementsByTagName("MainInstrumentsTraded"));
        const principalExposures = this.generateDataIfPrincipalExposures(this.xmlDoc.getElementsByTagName("PrincipalExposures"));
        const portfolioConcentrations = this.generateDataIfPortfolioConcentrations(this.xmlDoc.getElementsByTagName("PortfolioConcentrations"));
        const principalMarkets = this.generateDataIfPrincipalMarkets(this.xmlDoc.getElementsByTagName("AIFPrincipalMarkets"));

        this.generateDataIfSpecs(this.xmlDoc).forEach(el => {
          this.specs.push(el);
        });

        this.title = this.getTextContentByKey(this.xmlDoc, 'AIFName');
        this.tables.push(mainInstrumentsTraded);
        this.tables.push(principalExposures);
        this.tables.push(portfolioConcentrations);
        this.tables.push(principalMarkets);
      }
    }
  }

  resetData() {
    if (this.hasId) this.isLoading = true;

    if (!this.hasId) {
      this.emptyPreview = true;
      this.isLoading = false;
    }

    this.title = 'XML Reader for AIF Regulatory Reporting';
    this.xml = '';
    this.tables = [];
    this.specs = [];
    this.codes = [];
    this.marketCodes = [];
  }

  async mounted() {
    this.resetData();

    this.initXmlPreview();
  }

  @Watch('routeId') onRouteIdChange() {
    this.resetData();

    if (this.routeId?.length) {
      this.isLoading = true;
      this.emptyPreview = false;
      this.initXmlPreview();
    } else {
      this.isLoading = false;
    }
  }
}
