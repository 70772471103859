var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    class: ['sygni-link-button', _vm.type == 'simple' ? 'sygni-link-button--simple' : '', !_vm.legacyStyling ? 'tw-theme' : ''],
    on: {
      "click": _vm.click
    }
  }, [_vm.arrowSide === 'left' && _vm.type != 'simple' ? _c('span', {
    staticClass: "arrow"
  }, [_vm._v(" < ")]) : _vm._e(), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        boundary: 'viewport',
        intaractive: false
      },
      expression: "{ boundary: 'viewport', intaractive: false }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text",
    attrs: {
      "title": _vm.tooltip
    }
  }, [_vm._t("default")], 2), _vm.arrowSide === 'right' && _vm.type != 'simple' ? _c('span', {
    staticClass: "arrow"
  }, [_vm._v(" > ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }