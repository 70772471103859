var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('edit-profile', {
    attrs: {
      "isLoading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "modal",
      fn: function fn() {
        return [_c('GenproxModal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showModal,
            expression: "showModal"
          }],
          attrs: {
            "isLoading": _vm.isLoading,
            "header": "Wait...",
            "description": "Do you want to save the changes?"
          },
          on: {
            "cancel": _vm.changeRoute,
            "confirm": _vm.confirmUpdateProfile,
            "close": function close($event) {
              _vm.showModal = false;
            }
          }
        }), _c('GenproxModal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showInfoModal,
            expression: "showInfoModal"
          }],
          attrs: {
            "cancelText": "",
            "size": "large",
            "confirmText": "Got it",
            "header": "How to enable two factor authentication?"
          },
          on: {
            "confirm": _vm.closeInfoModal,
            "close": _vm.closeInfoModal
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function fn() {
              return [_c('div', {
                staticClass: "gn-modal__steps"
              }, [_c('div', {
                staticClass: "gn-modal__step"
              }, [_c('p', {
                staticClass: "gn-modal__step-header"
              }, [_c('strong', [_vm._v("Step 1:")]), _vm._v(" Download & install Google Authenticator on your mobile device")]), _c('div', {
                staticClass: "gn-modal__step-desc"
              }, [_c('div', {
                staticClass: "gn-modal__step-badges"
              }, [_c('div', {
                staticClass: "gn-modal__step-badge"
              }, [_c('a', {
                attrs: {
                  "target": "_blank",
                  "href": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pl&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                }
              }, [_c('img', {
                attrs: {
                  "alt": "Get it on Google Play",
                  "src": "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                }
              })])]), _c('div', {
                staticClass: "gn-modal__step-badge"
              }, [_c('a', {
                attrs: {
                  "href": "https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&itscg=30200",
                  "target": "_blank"
                }
              }, [_c('img', {
                staticStyle: {
                  "height": "47px",
                  "margin-top": "11px"
                },
                attrs: {
                  "src": "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1284940800&h=7fc6b39acc8ae5a42ad4b87ff8c7f88d",
                  "alt": "Download on the App Store"
                }
              })])])])])]), _c('div', {
                staticClass: "gn-modal__step"
              }, [_c('p', {
                staticClass: "gn-modal__step-header"
              }, [_c('strong', [_vm._v("Step 2:")]), _vm._v(" Add your account to Google authenticator")]), _c('p', {
                staticClass: "gn-modal__step-desc"
              }, [_vm._v("After clicking the '+' icon in Google Authenticator, use the camera to scan the QR code on the screen.")])]), _c('div', {
                staticClass: "gn-modal__step"
              }, [_c('p', {
                staticClass: "gn-modal__step-header"
              }, [_c('strong', [_vm._v("Step 3:")]), _vm._v(" Verify your device")]), _c('p', {
                staticClass: "gn-modal__step-desc"
              }, [_vm._v("Enter the code you get from Google Authenticator app.")])])])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('sygni-container-title', {
          staticClass: "mb-5"
        }, [_vm._v("Account data")]), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'EMAIL',
            "disabled": true,
            "validation": _vm.$v.profileData.generalData.email
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.generalData.email,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.generalData, "email", $$v);
            },
            expression: "profileData.generalData.email"
          }
        })], 1), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('sygni-loader', {
          attrs: {
            "is-loading": _vm.isTwoFactorLoading
          }
        }), _c('div', {
          staticClass: "edit-profile__heading"
        }, [_c('sygni-container-title', [_vm._v("Two factor authentication")]), _c('sygni-toggle-switch', {
          staticClass: "secondary edit-profile__heading-switch",
          attrs: {
            "label": _vm.twoFactorString
          },
          on: {
            "input": _vm.toggle2Factor
          },
          model: {
            value: _vm.twoFactor,
            callback: function callback($$v) {
              _vm.twoFactor = $$v;
            },
            expression: "twoFactor"
          }
        })], 1), !_vm.activeUserData.user.twoFactorAuthEnabled && _vm.twoFactor ? _c('div', {
          staticClass: "edit-profile__group"
        }, [_c('div', {
          staticClass: "edit-profile__qr"
        }, [_c('vue-qr', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isTwoFactorLoading,
            expression: "!isTwoFactorLoading"
          }],
          attrs: {
            "text": _vm.twoFactorCode,
            "size": 200
          }
        })], 1), _c('div', {
          staticClass: "text-center edit-profile__help"
        }, [_vm._v(" Don't know what to do with this code? "), _c('sygni-link-button', {
          staticClass: "gn-primary",
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.enableInfoModal
          }
        }, [_vm._v("Click here")])], 1), _c('p', {
          staticClass: "label"
        }, [_vm._v("ENTER CONFIRMATION CODE")]), _c('sygni-input-group', {
          attrs: {
            "btnText": "Confirm",
            "btnClasses": "filled gn-secondary"
          },
          on: {
            "click": _vm.sendConfirmationCode
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })], 1) : _vm._e(), _vm.requireTwoFactor && !_vm.activeUserData.user.twoFactorAuthEnabled ? _c('div', {
          staticClass: "edit-profile__group"
        }, [_c('p', {
          staticClass: "edit-profile__error"
        }, [_vm._v("Your legal entity requires you to enable two factor authentication in order to use our platform.")])]) : _vm._e(), _vm.activeUserData.user.twoFactorAuthEnabled ? _c('div', {
          staticClass: "edit-profile__group"
        }, [_c('p', {
          staticClass: "edit-profile__notice"
        }, [_vm._v("Two factor authentication is currently enabled.")])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }