import { render, staticRenderFns } from "./FundsEmptyTable.vue?vue&type=template&id=57ca7977&scoped=true&"
import script from "./FundsEmptyTable.vue?vue&type=script&lang=ts&"
export * from "./FundsEmptyTable.vue?vue&type=script&lang=ts&"
import style0 from "./FundsEmptyTable.vue?vue&type=style&index=0&id=57ca7977&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ca7977",
  null
  
)

export default component.exports