var render = function () {
  var _vm$$slots;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['auth-module', "auth-module--".concat(_vm.size)]
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "auth-module__wrapper"
  }, [_c('div', {
    staticClass: "auth-module__container"
  }, [_vm._m(2), _c('div', {
    staticClass: "auth-module__inner"
  }, [_vm._t("default"), !!((_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.footNote) ? _c('p', {
    staticClass: "auth-module__footnote"
  }, [_vm._t("footNote")], 2) : _vm._e()], 2)]), _vm._m(3)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-module__top-addon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/funde-login-bg-top-corner.png"),
      "alt": ""
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-module__bottom-addon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/funde-login-bg-bottom-corner.svg"),
      "alt": ""
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-module-header"
  }, [_c('img', {
    staticClass: "auth-module-header__logo",
    attrs: {
      "src": require("@/assets/images/logo.svg"),
      "alt": "Fundequate"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-module__footer"
  }, [_c('p', [_vm._v("Fundequate Sarl-S provides accounting, corporate services and reporting platform for Venture Capital and Private Equity funds based on Alternative Investment Fund structure.")])]);
}]

export { render, staticRenderFns }