































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Prop} from 'vue-property-decorator';
import {BSpinner} from "bootstrap-vue";

Component.registerHooks(['validations'])
@Component({
  components: { SygniRoundedButton, BSpinner }
})

export default class GenproxModal extends Vue {
  @Prop() header: string;
  @Prop() description?: string;
  @Prop({default: 'normal'}) size?: string;
  @Prop({default: 'normal'}) type?: 'normal' | 'iframe';
  @Prop({default: 'Quit'}) cancelText?: string;
  @Prop({default: 'Save'}) confirmText?: string;
  @Prop({default: false}) showCloseBtn: boolean;
  @Prop({default: true}) overflow: boolean;
  @Prop() isLoading!: boolean;
  @Prop({ default: false }) smallerActionsPadding: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: null }) testId: string;

  get hasContentSlot(): boolean {
    return !!this.$slots?.content;
  }

  scrollToTop() {
    (this.$refs.sygniModalBox as HTMLElement)?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleClick(e: any) {
    if(!this.disabled) {
      this.$emit('confirm', e);
    }
  }
}
