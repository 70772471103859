






























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import FormBoilerplate from '../FormBoilerplate.vue';
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';
import Utils from '@/modules/shared/utils/utils';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

Component.registerHooks(['validations'])
@Component({
  components: { SygniInput, SygniRectButton, SygniLinkButton, FormBoilerplate }
})
export default class LoginConfirmPage extends Vue {
  confirmationCode: string = '';
  sessionLength: number = 300;
  loading: boolean = false;
  interval: any = null;

  async sendConfirmationCode() {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.$v.$reset();
      this.loading = true;

      try {
        await this.$store.dispatch('auth/loginWithTwoFactor', {
          token: this.$route.params.token,
          confirmationCode: this.confirmationCode,
        })
        localStorage.removeItem(REDIRECTED_PATH);
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }

      this.loading = false;
    }
  }

  validations () {
    return  {
      confirmationCode: { 
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
    }
  }

  get timerString() {
    const minutes = Math.floor(this.sessionLength / 60);
    const seconds = this.sessionLength % 60;

    return `${Utils.pad(minutes, 2)}:${Utils.pad(seconds, 2)}`;
  }

  beforeMount() {
    if(this.$route?.params?.token === undefined) {
      this.$router.push({ name: 'login' });
    } else {
      this.interval = setInterval(() => {
        this.sessionLength--;

        if(this.sessionLength <= 0) {
          this.$router.push({ name: 'login' });
        }
      }, 1000);
    }
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }
}
