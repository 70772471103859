var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-boilerplate', {
    attrs: {
      "formTitle": "Sign in"
    },
    scopedSlots: _vm._u([{
      key: "fields",
      fn: function fn() {
        return [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "testId": "fe_email",
            "label": 'Email address',
            "legacyStyling": false,
            "name": "natural_person[email]",
            "validation": _vm.$v.loginCredentials.email
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.loginCredentials.email.$touch();
            }
          },
          model: {
            value: _vm.loginCredentials.email,
            callback: function callback($$v) {
              _vm.$set(_vm.loginCredentials, "email", $$v);
            },
            expression: "loginCredentials.email"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "testId": "fe_password",
            "label": 'Password',
            "legacyStyling": false,
            "name": "natural_person[password]",
            "validation": _vm.$v.loginCredentials.password,
            "type": "password"
          },
          on: {
            "blur": function blur($event) {
              return _vm.$v.loginCredentials.password.$touch();
            },
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.login();
            }
          },
          model: {
            value: _vm.loginCredentials.password,
            callback: function callback($$v) {
              _vm.$set(_vm.loginCredentials, "password", $$v);
            },
            expression: "loginCredentials.password"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-form__btn",
          attrs: {
            "legacyStyling": false,
            "loading": _vm.loading
          },
          on: {
            "click": function click($event) {
              return _vm.login();
            }
          }
        }, [_vm._v("Sign in")])];
      },
      proxy: true
    }, {
      key: "actionsFooter",
      fn: function fn() {
        return [_c('sygni-link-button', {
          attrs: {
            "type": "simple",
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.goToRemindPassword();
            }
          }
        }, [_vm._v("Forgot password?")])];
      },
      proxy: true
    }, {
      key: "footNote",
      fn: function fn() {
        return [_vm._v(" Not a member? "), _c('sygni-link-button', {
          staticClass: "gn-primary",
          attrs: {
            "type": "simple",
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('register');
            }
          }
        }, [_vm._v("Create an account")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }