import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const productsModule = () => import('./containers/productsModule.vue');
const capitalRiseBlindModule = () => import('../../../fundraising/containers/CapitalRiseBlindModule.vue');

export const productsRouting: Array<RouteConfig>  = [
    {
        path: 'products',  /* /fund */
        name: 'products',
        component: productsModule,
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.marketplace) {
                next();
            } else {
                next(`/${to.path.includes('company') ? 'company' : 'fund'}/products/access-denied`);
            }
        },
    },
    {
        path: 'products/access-denied',
        name: 'products-access-denied',
        component: capitalRiseBlindModule,
        meta: {
            accessModule: ['marketplace'],
        },
        children: [],
    },
];
