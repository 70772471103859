



























































































































































































































































































import Component from 'vue-class-component'
import PivotTableModal from './PivotTableModal.vue';
import PivotManagement from './PivotManagement.vue';
import FilterPopover from './FilterPopover.vue';
import { PTableColumn, View } from './types';
import draggable from 'vuedraggable';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import PivotTableUtilities from './PivotTableUtilities.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  components: { PivotTableModal, PivotManagement, FilterPopover, draggable, SygniCheckbox, SygniLinkButton },
})

export default class PivotTable extends PivotTableUtilities {
  @Prop({ default: null }) reportName: string | null
  showFieldsModal: boolean = false;
  shareLinkTooltipText: string = 'Copy link to share'

  onFilterUpdate(filter: string, values: any) {
    this.$set(this.filterSelections, filter, values)
    this.pivotData.openedFilter = null
    this.$nextTick(() => {
      this.refreshPivotData()
      this.setAggregations()
      this.updateWidths()
      this.refreshScrollbars()
      this.reRender++
    })
  }

  // function to update
  displayCellValue(colId: string, row: any) {
    let col: any = this.getCol(colId, true)
    let value = row[colId]
    
    if (col?.colType === 'datetime') {
      if (col?.withTime) {
        return this.$options.filters.dateWithTime(value)
      } else {
        return this.$options.filters.dateWithoutTime(value)
      }
    }

    if (col?.colType === 'number') {
      switch (col?.format?.value) {
        case false:
          return this.$options.filters.thousandSeparator(value)
        case 'integer':
          return this.$options.filters.integer(value)
        case 'leadingZero':
          return this.$options.filters.leadingZeroDigitFormat(value, 2, true)
        case 'advancedFloat':
          return this.$options.filters.numberFormat(value, 4, true)
        default:
          return this.$options.filters.numberFormat(value, 2)
      }
    }

    if (col?.colType === 'array') {
      switch (col?.format) {
        case 'datetime':
          return value?.map((el: any) => this.$options.filters.dateWithoutTime(el)?.replaceAll(',', '.')).join(', ')
        case 'number':
          return value?.map((el: any) => this.$options.filters.numberFormat(el, 2)?.replaceAll(',', '.')).join(', ')
        default:
          return value
      }
    }
    
    if (col?.colType === 'boolean') {
      if (value === true) {
        return 'true'
      } else if (value === false) {
        return 'false'
      } else if (value === null) {
        return 'null'
      }
    }

    return value
  }

  getCellClassList(col: PTableColumn) {
    return col?.colType === 'number' ? 'text-right' : 'text-left'
  }

  // ------------------------------------------------------
  // Rows & columns
  // ------------------------------------------------------

  get pivotRows() {
    return this.pivotData?.items?.slice(this.scrollPositionY, this.scrollPositionY + this.displayRows)
  }

  get pivotItemsLength() {
    return this.pivotData?.items?.length
  }

  get allRowsOpened() {
    return this.formattedRows?.results && this.formattedRows?.results?.length === this.numberOfRows
  }

  toggleAllDetails() {
    const levels = this.pivotData.rows.length - 1 || 0
    if (!this.allRowsOpened) {
      for (let index = 1; index <= levels; index++) {
        this.pivotData.items.filter((el: any) => el.level === index)?.forEach((item: any) => {
          if (!item.detailsOpened) {
            this.toggleDetails(item)
          }
        })
      }
    } else {
      this.pivotData.items.filter((el: any) => el.level === 1)?.forEach((item: any) => {
        this.toggleDetails(item)
      })
      this.scrollPositionY = 0
    }
  }

  // function to update
  getColValue(column: any, i: number, colType: string) {
    const key = this.pivotDataColIds[i]

    if (colType === 'datetime') {
      return this.$options.filters.dateWithoutTime(column[key])
    }

    if (colType === 'number') {
      const format: any = this.getCol(key)?.format?.value
      if (format === false) {
        return this.$options.filters.thousandSeparator(column[key])
      } else if (format === 'integer') {
        return this.$options.filters.integer(column[key])
      } else if (format === 'leadingZero') {
        return this.$options.filters.leadingZeroDigitFormat(column[key], 2, true)
      } else if (format === 'advancedFloat') {
        return this.$options.filters.numberFormat(column[key], 4, true)
      } else {
        return this.$options.filters.numberFormat(column[key], 2)
      }
    }

    if (colType === 'boolean') {
      if (column[key] === true) {
        return 'true'
      } else if (column[key] === false) {
        return 'false'
      } else if (column[key] === null) {
        return 'null'
      }
    }

    return column[key]
  }

  toggleDetails(item: any) {
    const index = this.pivotData.items.findIndex((el: any) => el.id === item.id)
    item.detailsOpened = !item.detailsOpened
    this.selections = []

    if (item.detailsOpened) {
      // insert items
      let items = this.formattedRows.results.filter((el: any) => {
        let check: boolean = true
        for (let i = 0; i < item.level; i++) {
          const comparisonKey: string = this.pivotDataRowIds[i]
          const value = item.id.split('-||-')[i]

          if (el[comparisonKey] !== value) {
            check = false
            break;
          }
        }
        return check && el.level === item.level + 1
      }).map((el: any) => {
        el.detailsOpened = false
        return el
      })
      if (items.length) {
        let column: PTableColumn = this.getCol(this.pivotDataRowIds[items[0]?.level - 1], true)

        if (column.colType === 'number') {
          items = items.sort((a: any, b: any) => {
            const x = Number(a[this.pivotDataRowIds[a.level - 1]])
            const y = Number(b[this.pivotDataRowIds[b.level - 1]])
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          })
        } else {
          items = items.sort((a: any, b: any) => {
            if (a.id < b.id) {
              return -1;
            }
            if (a.id > b.id) {
              return 1;
            }
            return 0;
          })
        }
        items = this.setAggregations(items)
        this.pivotData.items.splice(index + 1, 0, ...items)
      }
    } else {
      // remove items
      const items = this.pivotData.items.filter((el: any) => {
        if (el.level <= item.level) {
          return true
        }

        if (el.level > item.level) {
          if (el.id.includes(item.id)) {
            return (el[this.pivotDataRowIds[item.level - 1]] !== item.id && el.level <= item.level)
          } else {
            return true
          }
        }
      })
      this.pivotData.items = items
    }
    
    this.reRender++
  }

  // ------------------------------------------------------
  // Scrolling data
  // ------------------------------------------------------

  get showPrevScrollingArrow() {
    if (this.selectedView === 'grid') {
      return false
    }

    return this.gridTableScrollWidth > this.gridTableWidth && !this.scrollLockX.start && !this.useHorizontalNativeScrolling
  }

  get showNextScrollingArrow() {
    if (this.selectedView === 'grid') {
      return false
    }

    return this.gridTableScrollWidth > this.gridTableWidth && !this.scrollLockX.end && !this.useHorizontalNativeScrolling
  }

  handleScrollClick(type: 'prev' | 'next') {
    const scrollAmount = (this.$refs.pTableInner as any).offsetWidth;
    const scrollPosition = (type === 'prev')  ? (this.$refs.pTableInner as any).scrollLeft - scrollAmount : (this.$refs.pTableInner as any).scrollLeft + scrollAmount;
    (this.$refs.pTableInner as any).scrollBy({
      left: (type === 'next') ? scrollAmount : -scrollAmount,
      top: (this.$refs.pTableInner as any).scrollTop,
      behavior: 'smooth',
    })
    this.scrollbarX = scrollPosition
  }

  get verticalScrollPosition() {
    if (this.missingRows > 0) {
      const percentage = (this.scrollbarY * 100) / ((this.$refs.pTableEl as any)?.offsetHeight - (this.$refs.pTableInner as any)?.offsetHeight) || 0
      const scrollTrail = ((this.$refs.pTableInner as any)?.offsetHeight as number) - (this.verticalScrollbarHeight as any) || 0
      const scrollPositionTop = (percentage * scrollTrail / 100)
      return `${scrollPositionTop}px`;
    } else {
      // use vertical native scrolling
      let percentage = (this.scrollPositionY * 100) / this.maxScrollPositionY;
      const scrollTrail = ((this.$refs.pTableInner as any)?.offsetHeight as number) - (this.verticalScrollbarHeight as any) || 0
      const scrollPositionTop = (percentage * scrollTrail / 100)
      return `${scrollPositionTop}px`
    }
  }

  get horizontalScrollPosition() {
    if (this.useHorizontalNativeScrolling) {
      const percentage = (this.scrollbarX * 100) / ((this.$refs.pTableEl as any)?.offsetWidth - (this.$refs.pTableInner as any)?.offsetWidth) || 0
      const scrollTrail = ((this.$refs.pTableInner as any)?.offsetWidth as number) - (this.horizontalScrollbarWidth as any) || 0
      const scrollPositionLeft = (percentage * scrollTrail / 100)
      return `${scrollPositionLeft}px`;
    } else {
      let percentage = (this.scrollPositionX * 100) / this.maxScrollPositionX;
      const scrollTrail = ((this.$refs.pTableInner as any)?.offsetWidth as number) - (this.horizontalScrollbarWidth as any) || 0
      const scrollPositionLeft = (percentage * scrollTrail / 100)
      return `${scrollPositionLeft}px`;
    }
  }

  // ------------------------------------------------------
  // Fields modal
  // ------------------------------------------------------

  openFieldsModal() {
    this.showFieldsModal = true;
    this.selection = {}
  }

  closeFieldsModal() {
    this.showFieldsModal = false;
  }

  // ------------------------------------------------------
  // General
  // ------------------------------------------------------

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  cloneShareLink() {
    const contextQueryParam: string = window?.location?.href?.includes('?') ? `&contextOwnerId=${this.activeUserData?.context?.id}` : `?contextOwnerId=${this.activeUserData?.context?.id}`
    const shareLink: string = `${window?.location?.href}${contextQueryParam}`
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(shareLink)
    const shareLinkTooltipText = _.clone(this.shareLinkTooltipText)
    this.shareLinkTooltipText = 'Text copied!'

    setTimeout(() => {
      this.shareLinkTooltipText = shareLinkTooltipText
    }, 2000)
  }

  getTooltipInfo(col: any) {
    let stringElements: string[] = []

    for (let i = 0; i < this.pivotSelectedColumns.length; i++) {
      const column: PTableColumn = this.pivotSelectedColumns[i];
      if (col[column.id] !== undefined) {
        stringElements.push(`${column.title}: ${this.getColValue(col, i, column.colType)}`)
      }
    }

    return stringElements.length ? stringElements.join(',<br>') : ''
  }

  exportToCSV(view: View) {
    const csvColumns: string[] = this.filteredColumns.map((col: PTableColumn) => col.title)

    const dataToExport = view === 'grid' ? this.filteredTableDataItems : this.pivotData?.items

    let csvContent = [
      csvColumns,
      ...dataToExport.map((item: any) => {
        const row: string[] = []

        csvColumns.forEach((col: string) => {
          const column = this.filteredColumns.find((el: PTableColumn) => el.title === col)
          
          if (column) {
            if (column?.colType === 'boolean') {
              if (item[column.id] === true) {
                row.push('true')
              } else if (item[column.id] === false) {
                row.push('false')
              } else if (item[column.id] === null) {
                row.push('')
              }
            } else {
              row.push(item[column.id])
            }
          }
        })


        // eslint-disable-next-line no-useless-escape
        return row.map(str => `"${str ? str.toString().replaceAll('\"', '').replaceAll('"', '') : ''}"`)
      })
    ].map(e => e.join(",")).join("\n");
    
    csvContent = csvContent.replace(/[\r]+/g, '').replace(';', ' ').replace(/&nbsp;/g, '').replace(/"[^"]*(?:""[^"]*)*"/g, function (m: any) { return m.replace(/\n/g, ''); })
    const universalBOM: string = '\uFEFF';
    let data = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
    const link = document.createElement('a');
    link.setAttribute('href', data)
    link.setAttribute('download', `reporting.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  handleKeydown(e: any) {
    if (this.selections?.length) {
      if (this.operatingSystem === 'MacOS') {
        if (e.metaKey && e.keyCode === 67) {
          this.copyHandler()
        }
      } else {
        if (e.ctrlKey && e.keyCode === 67) {
          this.copyHandler()
        }
      }
    }
  }

  // ------------------------------------------------------
  // Hooks
  // ------------------------------------------------------

  mounted() {
    this.setDisplayRowsAmount()
    this.setDisplayColsAmount()
    this.tableDataItems = this.tableData
    this.pivotDataSchema = this.schema
    
    document.addEventListener('keydown', this.handleKeydown);
    document.addEventListener('mouseup', this.handleMouseUp);

    this.$nextTick(() => {
      this.refreshScrollbars()
    })
  }
  
  @Watch('tableDataItems', { deep: true, immediate: true }) onTableDataChange() {
    this.$nextTick(() => {
      this.refreshPivotData(true)
    })
  }
}
