






























































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import GenproxModal from '@/components/layout/GenproxModal.vue';
import FileUploader from '@/components/FileUploader.vue';
import { Prop } from 'vue-property-decorator';
import { BACKEND_BASE_URL } from '@/shared/consts';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { UploadedFileInfo } from '@/modules/genprox/models/Shared';
import { BTable, BSpinner } from 'bootstrap-vue'
import { AxiosRequestConfig } from 'axios';

@Component({
  components: { SygniContainerTitle, GenproxModal, SygniRoundedButton, FileUploader, BTable, BSpinner },
})
export default class PaymentConfirmations extends Vue {
  @Prop({ default: '' }) productId: string;
  @Prop({ default: [] }) items!: any[];

  deletingPaymentConfirmationId: string = null;
  uploadProgress: UploadProgress = { progress: 0 };
  isLoading: boolean = false

  readonly supportedFileFormats: any = ['pdf', 'doc'];

  async addFiles(files: any[]) {
    this.isLoading = true
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    const file: any = files[0]

    try {
      const uploadedFile: UploadedFileInfo = await this.$store.dispatch('genprox/uploadFile', { file, config });
      await this.$store.dispatch('investors/uploadPaymentConfirmationFile', { productId: this.productId, fileId: uploadedFile?.id })
      
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Payment confirmation uploaded.'
      })

      this.$emit('change')

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.uploadProgress.progress = -1;
  }

  downloadGeneratedFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName)
  }

  closeDeleteConfirmationModal() {
    this.deletingPaymentConfirmationId = null
  }

  openPaymentConfirmationModal(item: any) {
    this.deletingPaymentConfirmationId = item?.id
  }

  async deletePaymentConfirmation() {
    this.isLoading = true

    try {
      await this.$store.dispatch('investors/deletePaymentConfirmation', { productId: this.productId, paymentConfirmationId: this.deletingPaymentConfirmationId })
      
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Payment confirmation deleted.'
      })

      this.$emit('change')
      this.closeDeleteConfirmationModal()

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  hasPreview(item: any) {
    const isPdf = item?.filePath?.toLowerCase().indexOf('.pdf') != -1 ? true : false;

    return isPdf
  }

  previewPaymentConfirmation(item: any) {
    if (this.hasPreview(item)) {
      this.$emit('preview', item)
    }
  }
}
