var render = function () {
  var _vm$$slots;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-page-heading"
  }, [_c('h1', {
    staticClass: "gn-page-heading__header"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.description ? _c('p', {
    staticClass: "gn-page-heading__desc"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), !!((_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.actions) ? _c('div', {
    staticClass: "gn-page-heading__actions"
  }, [_vm._t("actions")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }