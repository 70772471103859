



























import Vue from 'vue'
import Component from 'vue-class-component'
import { XMarkIcon, ExclamationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@/assets/icons/heroicons/heroicons'

@Component({
  components: { XMarkIcon, ExclamationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon }
})
export default class Notification extends Vue {
  get position() {
    return 'top right';
  }

  hasIcon(type: string) {
    return type === 'success' || type === 'error' || type === 'warn'
  }
}
