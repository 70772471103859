var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-boilerplate', {
    attrs: {
      "formTitle": "Reset password"
    },
    scopedSlots: _vm._u([{
      key: "fields",
      fn: function fn() {
        return [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'Password',
            "legacyStyling": false,
            "type": "password",
            "validation": _vm.$v.password
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'Repeat password',
            "legacyStyling": false,
            "type": "password",
            "validation": _vm.$v.repeatPassword
          },
          model: {
            value: _vm.repeatPassword,
            callback: function callback($$v) {
              _vm.repeatPassword = $$v;
            },
            expression: "repeatPassword"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-form__btn",
          attrs: {
            "loading": _vm.loading,
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.resetPassword();
            }
          }
        }, [_vm._v("Reset password")])];
      },
      proxy: true
    }, {
      key: "actionsFooter",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "footNote",
      fn: function fn() {
        return [_c('sygni-link-button', {
          staticClass: "gn-primary",
          attrs: {
            "type": "simple",
            "legacyStyling": false
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/auth/login');
            }
          }
        }, [_vm._v("Go back to login")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }