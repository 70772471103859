var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-boilerplate', {
    attrs: {
      "formTitle": "Confirm your login"
    },
    scopedSlots: _vm._u([{
      key: "fields",
      fn: function fn() {
        return [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'Enter Google Authenticator code',
            "legacyStyling": false,
            "validation": _vm.$v.confirmationCode,
            "type": "text"
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.sendConfirmationCode.apply(null, arguments);
            }
          },
          model: {
            value: _vm.confirmationCode,
            callback: function callback($$v) {
              _vm.confirmationCode = $$v;
            },
            expression: "confirmationCode"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('sygni-rect-button', {
          staticClass: "filled gn-primary auth-form__btn",
          attrs: {
            "loading": _vm.loading,
            "legacyStyling": false
          },
          on: {
            "click": _vm.sendConfirmationCode
          }
        }, [_vm._v("Sign in")]), _c('p', {
          staticClass: "timer text-center mt-3"
        }, [_vm._v("Your session ends in: "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.timerString))])])];
      },
      proxy: true
    }, {
      key: "actionsFooter",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "footNote",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }